<template>
  <div class="admin-user-profile">
    <div class="admin-user-profile__wrap">
      <div class="admin-user-profile__container">
        <h2>
          Личная информация
        </h2>
        <h3>
          ФИО:
        </h3>
        <p>
          {{ user.fullName }}
        </p>
        <h3>
          Адрес:
        </h3>
        <p class="admin-user-profile__street">
          {{ user.address }}
        </p>
      </div>
      <div class="admin-user-profile__container">
        <h2>
          Информация о лицевом счете
        </h2>
        <h3>
          Лицевой счёт
        </h3>
        <p>
          № {{ user.personalNumber }}
        </p>
        <h3>
          Комплексная стоимость тарифа:
        </h3>
        <p>
          {{ user.trafficCost }} грн
        </p>
        <h3>
          Количество проживающих:
        </h3>
        <p v-if="user.tenants == 1">
          {{ user.tenants }} человек
        </p>
        <p v-if="user.tenants > 1">
          {{ user.tenants }} человекa
        </p>
        <h3>
          Количество льготчиков
        </h3>
        <p v-if="user.privileges == 1">
          {{ user.privileges }} человек
        </p>
        <p  v-if="user.privileges > 1">
          {{ user.privileges }} человека
        </p>
      </div>
    </div>
    <!-- <DecoreLine /> -->
  </div>
</template>

<script>
import DecoreLine from '../atoms/DecoreLine.vue';

export default {
  name: 'AdminUserProfile',
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    DecoreLine,
  },
};
</script>

<style lang="scss" scoped>
  .admin-user-profile {

      &__wrap {
        display: flex;
        padding: 40px;
      }

      &__street {
        padding-right: 50px;
      }

      &__container {

          max-width: 500px;
          text-align: left;

          h2 {
            @include h1;
            padding-bottom: 30px;
            color: $hintTextColor;
          }

          h3 {
            @include p;
            padding-bottom: 10px;
          }

          p {
            @include h2-regular;
            padding-bottom: 25px;
          }
      }
  }
</style>
