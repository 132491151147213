<template>
  <div class="inputs-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InputsInOneRowContainer',
};
</script>

<style lang="scss" scoped>
  .inputs-container {
    display: flex;
  }
</style>
