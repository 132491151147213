import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {

};

const mutations = {

};

const getters = {

};

const actions = {

};

const errorHandlerStore = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default errorHandlerStore;
