<template>
  <div class="decore-line"></div>
</template>

<script>
export default {
  name: 'DecoreLine',
};
</script>

<style lang="scss" scoped>
  .decore-line {
    border-bottom: 1px solid rgba(42, 49, 51, 0.1);
    margin: 25px 0;
  }
</style>
