<template>
  <div class="restore-password-form">
    <form @submit.prevent="handleSubmit">
      <InputPhoneWithLabel
        :id="'phone'"
        :set-value="userData"
        :error="error['phone']"
        :placeholder="'Введите номер телефона'"
      />
      <MessageError v-for="message in error['nonFieldError']" :key="message">
        {{message}}
      </MessageError>
      <ButtonMain :type="'submit'" class="restore-password-form__button-entrance">
        Получить код
      </ButtonMain>
    </form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import InputPhoneWithLabel from '../molecules/InputPhoneWithLabel.vue';
import ButtonMain from '../atoms/ButtonMain.vue';
import MessageError from '../atoms/MessageError.vue';

export default {
  name: 'RestorePasswordForm',
  components: {
    InputPhoneWithLabel,
    ButtonMain,
    MessageError,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userData: {},
    };
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSubmit() {
      this.onSubmit(this.userData);
    },
  },
};
</script>
<style lang="scss" scoped>
.restore-password-form {
  &__button-entrance {
    width: 175px;
    height: 43px;
    margin-right: 15px;
    margin-top: 15px;
  }
}
</style>
