<template>
  <div class="choose-town-n-street">
    <SearchTownForm
      v-if="!isStreetSearch"
      :handle-set-town="handleSetTown"
    />
    <SearchStreetForm
      v-if="isStreetSearch"
      :town="town"
      :handle-back-to-prev="handleBackToPrev"
      :handle-set-registration-data="handleSetRegistrationData"
    />
  </div>
</template>

<script>
import SearchTownForm from '../../forms/SearchTownForm.vue';
import SearchStreetForm from '../../forms/SearchStreetForm.vue';

export default {
  name: 'ChooseTownNStreet',
  components: {
    SearchTownForm,
    SearchStreetForm,
  },
  props: {
    handleSetInfo: {
      type: Function,
      default() {
        return {
        };
      },
    },
  },
  data() {
    return {
      isStreetSearch: false,
      town: null,
      street: null,
    };
  },
  methods: {
    handleSetTown(town) {
      this.town = town;
      this.isStreetSearch = true;
    },
    handleBackToPrev() {
      this.isStreetSearch = false;
    },
    handleSetRegistrationData(street) {
      this.street = street;
      this.handleSetInfo({ town: this.town, street: this.street });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
