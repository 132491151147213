<template>
  <div class="requests">
    <div class="requests__top-section">
      <h1>
        Запросы на изменение данных
      </h1>
      <div class="admin-search">
        <input type="text" v-model="inputStr" class="admin-search__search" placeholder="Поиск запроса">
        <div>
          <Search class="admin-search__svg"/>
        </div>
      </div>
    </div>
    <AdminRequestsList :information="filteredMovies" class="requests__user-list"/>
  </div>
</template>

<script>
import AdminRequestsList from '../../molecules/AdminRequestsList.vue';
import { getQueryList } from '../../api/supportApi';
import Search from '../../assets/images/search/button-search.svg';

export default {
  name: 'Requests',
  components: {
    AdminRequestsList,
    Search
  },
  data() {
    return {
      info: [],
      inputStr: ''
    }
  },
  computed: {
    filteredMovies() {
      if (this.inputStr) {
        return this.info.filter(item => {
            if(item.name == null || item.personalNumber == null || item.address == null) {
              item++
            } else return item.name.toLowerCase().indexOf(this.inputStr.toLowerCase()) !== -1 || item.address.toLowerCase().indexOf(this.inputStr.toLowerCase()) !== -1 || item.personalNumber.toLowerCase().indexOf(this.inputStr.toLowerCase()) !== -1;
        });
      }
      return this.info
    }
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
          const params = {  };
          getQueryList(params).then((res) => {
              this.info = res.data;
          }).catch((error) => {
            const { data } = error.response;
            this.error = data;
          });
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .requests {
    
    &__top-section {
      display: flex;
      justify-content: space-between;
      padding: 40px 40px 40px 0;

      h1 {
        @include h1;
        padding-left: 40px;
      }
    }

    &__search {
      margin-right: 40px;
      width: 480px;
    }

    &__user-list {
      padding: 0 40px;
      margin-right: 40px;
    }
  }
  .admin-search {
    position: relative;

    &__svg {
      position: absolute;
      top: 15px;
      right: 10px;

      &:hover {
        cursor: pointer;

        /deep/ path {
          stroke: $elementsColor;
        }
      }
    }
    &__search {
      width: 480px;
      height: 45px;
      background: #EDF4F7;
      border: 1px solid rgba(42, 49, 51, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      padding-left: 10px;
      color: $hintTextColor;
      font-size: $baseFontsSize;
    }
  }
  input:focus {
    outline: none!important;
  }
</style>