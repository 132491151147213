<template>
  <div class="dashboard-navigation-panel">
    <nav>
      <div>
      <router-link v-for="link in dashboardNavigationLinks" :to="link.to" :key="link.id">
        {{link.title}}
        <div v-if="link.title == 'Профиль'" class="dashboard-navigation-panel__prompt" title="подсказка">
          <Prompt />
        </div>
      </router-link>
      </div>
    </nav>
    <PersonalAccount />
  </div>
</template>

<script>
import { dashboardNavigationLinks } from '../router/constants';
import PersonalAccount from '../molecules/PersonalAccount.vue';
import Prompt from '../assets/images/prompt/prompt.svg';

export default {
  name: 'DashboardNavigationPanel',
  data() {
    return {
      dashboardNavigationLinks,
    };
  },
  components: {
    Prompt,
    PersonalAccount,
  },
};
</script>

<style lang="scss" scoped>
.dashboard-navigation-panel {

  display: flex;
  justify-content: space-between;
  position: relative;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(42, 49, 51, 0.1);
  }

   &__prompt {
    display: none!important;
  }

  nav {

    div {
      display: flex;
      padding: 20px 20px 15px 40px;
    }

    a {
      @include p;
      display: block;
      padding: 9px 0;
      margin-right: 44px;
      position: relative;
      text-transform: uppercase;
      text-decoration: none;
      color: $hintTextColor;

      &:hover {
        color: $mainColor;
      }
    }

    .router-link-active {

      color: $elementsColor;

      &:before {
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $elementsColor;
      }
    }
  }

}

</style>
