<template>
  <div class="user-registration-form">
    <p class="user-registration-form__title">Введите номер мобильного телефона и адрес квартиры</p>
    <form @submit.prevent="handleSubmit">
      <InputPhoneWithLabel
        :id="'phone'"
        :set-value="userInfo"
        :type="'tel'"
        :error="error['phone']"
        :placeholder="'Номер мобильного телефона'"
      />
      <InputTextWithLabel
        :id="'email'"
        :type="'mail'"
        :set-value="userInfo"
        :error="error['email']"
        :placeholder="'Email'"
      />
      <InputsInOneRowContainer>
        <div class="user-registration-form__adress">
          <ButtonUnderlined
            @click.native="chooseAdressModalIsOpen = true"
            type="button"
          >   
            <div v-if="townData" class="user-registration-form__adress-selected">
              {{townData.town.label}}
              {{townData.street.label}}
            </div>
            <div v-else>
              Выбрать адрес
            </div>
           </ButtonUnderlined>
        </div>
        <InputTextWithLabel
          :id="'building'"
          :type="'text'"
          :set-value="userInfo"
          :disabled="!townData"
          :error="error['building']"
          :placeholder="'Дом'"
        />
        <InputTextWithLabel
          :id="'flat'"
          :type="'text'"
          :set-value="userInfo"
          :disabled="!townData"
          :error="error['flat']"
          :placeholder="'Кв'"
        />
      </InputsInOneRowContainer>
      <MessageError v-for="message in error['nonFieldError']" :key="message">
        {{message}}
      </MessageError>
      <ButtonMain type="submit" class="user-registration-form__button">
        Далее
        <ArrowNext />
      </ButtonMain>
    </form>

    <!-- modals -->
      <ModalContainer
        v-if="chooseAdressModalIsOpen"
        :on-close="() => chooseAdressModalIsOpen = false"
      >
        <ChooseTownNStreet :handle-set-info="handleSetInfo"/>
      </ModalContainer>
    <!--  -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ChooseTownNStreet from '../organisms/Auth/ChooseTownNStreet.vue';
import InputTextWithLabel from '../molecules/InputTextWithLabel.vue';
import ModalContainer from '../molecules/ModalContainer.vue';
import ButtonMain from '../atoms/ButtonMain.vue';
import ButtonUnderlined from '../atoms/ButtonUnderlined.vue';
import InputsInOneRowContainer from '../atoms/InputsInOneRowContainer.vue';
import InputPhoneWithLabel from '../molecules/InputPhoneWithLabel.vue';
import MessageError from '../atoms/MessageError.vue';
import ArrowNext from '../assets/images/arrow/next.svg';

export default {
  name: 'UserRegistrationForm',
  components: {
    InputTextWithLabel,
    InputPhoneWithLabel,
    ButtonMain,
    MessageError,
    ButtonUnderlined,
    ModalContainer,
    InputsInOneRowContainer,
    ArrowNext,
    ChooseTownNStreet,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      townData: null,
      userInfo: {},
      options: {
        townId: [],
        streetName: [],
      },
      selectSearch: {},
      chooseAdressModalIsOpen: false,
    };
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSetInfo(data) {
      console.log(data);
      this.townData = data;
      this.chooseAdressModalIsOpen = false;
    },
    handleSubmit() {
      const { userInfo, townData } = this;
      this.onSubmit({
        townId: townData?.town.id,
        streetId: townData?.street.id,
        ...userInfo,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-registration-form {
  text-align: left;
  &__button {
    width: 130px;
    height: 43px;
    margin-right: 15px;
    margin-top: 15px;

    svg {
      position: relative;
      top: 1px;
    }
  }
  &__title {
    padding-bottom: 25px;
  }

  &__adress {

    display: flex;
    height: 45px;
    margin: 8px 0;
    background: #EDF4F7;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    button {
      width: 250px;
      text-align: left;
      padding-left: 10px;
    }
  }

  &__adress-selected {
    color: $mainColor;
    width: 250px;
  }

  form {
    position: relative;
  }
}
button {
  text-decoration: none;
}

/deep/ #vs2__combobox {
  padding-left: 5px;
}
/deep/.card-container {
    max-width: 560px!important;
    min-width: 400px;
  }
</style>
