<template>
  <div class="dashboard-user-bill-short-history">
      <p class="dashboard-user-bill-short-history__title">
          История транзакций
        </p>
        <div class="dashboard-user-bill-short-history__date-sum" v-for="history in history.transactions" :key="history.id">
          <span class="dashboard-user-bill-short-history__date">
            {{ history.time }} года
          </span>
          <span class="dashboard-user-bill-short-history__sum">
            {{ history.amount }} грн
          </span>
        </div>
        <router-link to="/dashboard/history" class="dashboard-user-bill-short-history__show-all-history">
          Просмотреть историю
        </router-link>
  </div>
</template>

<script>
export default {
  name: 'DashboardUserBillShortHistory',
  props: {
    history: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .dashboard-user-bill-short-history {

    width: 303px;
    border: 1px solid rgba(42, 49, 51, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    height: 100%;
    margin-left: 20px;

    &__title {
      @include p;
      padding: 30px 0 25px 30px;
    }

    &__date-sum {
      padding: 0 0 25px 30px;
      text-align: left;
    }

    &__date {
      @include p;
      display: inline-block;
      font-weight: normal;
      padding-right: 30px;
    }

    &__sum {
      @include p;
      display: inline-block;
    }

    &__show-all-history {
      @include p;
      font-weight: normal;
      color: $elementsColor;
      padding-left: 30px;
      padding-bottom: 30px;
    }
  }
</style>
