import axios from 'axios';
import { api } from './index';
// eslint-disable-next-line import/no-cycle
import { onResponseInterceptor, onResponseErrorInterceptor } from './interceptors';
// eslint-disable-next-line import/no-cycle
import { getAccessToken } from '../helpers/auth';

export const getUserHistory = (params) => api.request({
    method: 'POST',
    url: '/getUserHistory',
    params,
});

export const getUrlForPs = (params) => api.request({
    method: 'POST',
    url: '/getUrlForPs',
    params,
});

export const getStateAccount = (params) => api.request({
    method: 'POST',
    url: '/getStateAccount',
    params,
});

export const getUserList = (params) => api.request({
    method: 'POST',
    url: '/getUserList',
    params,
});

export const approveQueryStatus = (params) => api.request({
    method: 'POST',
    url: '/approveQueryStatus',
    params,
});

export const getQueryList = (params) => api.request({
    method: 'POST',
    url: '/getQueryList',
    params,
});

export const getDialogueTopic = (params) => api.request({
    method: 'POST',
    url: '/getDialogueTopic',
    params,
});

export const createDialogue = (data, params) => {
    axios.interceptors.response.use(
        onResponseInterceptor,
        onResponseErrorInterceptor,
    );
    return axios.request({
        method: 'POST',
        url: 'https://api.uberu.test.ut.in.ua/api/createDialogue/',
        data,
        params,
        headers: {
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
};

export const createMessage = (data, params) => {
    axios.interceptors.response.use(
        onResponseInterceptor,
        onResponseErrorInterceptor,
    );
    return axios.request({
        method: 'POST',
        url: 'https://api.uberu.test.ut.in.ua/api/createMessage/',
        data,
        params,
        headers: {
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
};

export const operatorCreateMessage = (data, params) => {
    axios.interceptors.response.use(
        onResponseInterceptor,
        onResponseErrorInterceptor,
    );
    return axios.request({
        method: 'POST',
        url: 'https://api.uberu.test.ut.in.ua/api/operatorCreateMessage/',
        data,
        params,
        headers: {
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
};

export const getUserDialogueList = (params) => api.request({
    method: 'POST',
    url: '/getUserDialogueList',
    params,
});

export const getOperatorDialogueList = (params) => api.request({
    method: 'POST',
    url: '/getOperatorDialogueList',
    params,
});

export const getMessagesByDialogueId = (data, params) => api.request({
    method: 'POST',
    url: '/getMessagesByDialogueId',
    data,
    params,
});

export const getOperatorMessagesByDialogueId = (data, params) => api.request({
    method: 'POST',
    url: '/getOperatorMessagesByDialogueId',
    data,
    params,
});

export const getUserInfonById = (data, params) => api.request({
    method: 'POST',
    url: '/getUserInfonById',
    data,
    params,
});

export const getQueryById = (data, params) => api.request({
    method: 'POST',
    url: '/getQueryById',
    data,
    params,
});

export const getUserInfoByPersonalNumber = (data, params) => api.request({
    method: 'POST',
    url: '/getUserInfoByPersonalNumber',
    data,
    params,
});