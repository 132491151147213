<template>
  <div class="support-static-information">
    <h2 class="support-static-information__title">
      Ответы на частые вопросы
    </h2>
    <p class="support-static-information__hint">
      Если у вам необходимо получить
      быстрый ответ на ваш вопрос, можете воспользоваться списком, который мы подготовили для вас
    </p>
    <tabs>
    <tab title-slot="vue">
      <h2 class="support-static-information__hint-title">
        Личный кабинет позволяет вам:
      </h2>
      <li>получать счета в онлайн режиме;</li>
      <li>оплачивать счета с телефона или компьютера;</li>
      <li>просматривать историю платежей;</li>
      <li>задавать вопросы и консультироваться у менеджеров</li>
      <p class="support-static-information__title-vedeo-hint">
        Посмотрите видеоинструкцию ниже, чтобы узнать о всех возможностях личного кабинета “Уберу”.
      </p>
      <div class="support-static-information__video">
        <Video />
      </div>
    </tab>
    <tab title-slot="vue-2">
      <p>
        Чтобы оплатить задолженность, перейдите на вкладку "Состояние счета"
        и нажмите на кнопку "Оплатить задолженность".
      </p>
      <p>
        После этого вам будет доступен выбор способа оплаты.
        Нажмите на подходящий платежный сервис и совершите платеж.
      </p>
    </tab>
    <tab title-slot="vue-3">
      <p>
        После оплаты на ваш номер в Viber придет сообщение с
        информацией о том, что платежная операция прошла успешно.
      </p>
      <p>
        Также вы можете убедиться в том, что счет оплачен,
        посмотрев на строку "Задолженность" в личном кабинете.
        Если в этой строке указана цифра 0,00 грн., то это означает,
        что ваши счета успешно оплачены и задолженности нет.
      </p>
    </tab>
    <tab title-slot="vue-4">
      <p>
        Чтобы изменить данные, перейдите во вкладку "Профиль" и нажмите на кнопку "Изменить данные".
        Выберите пункт, который вы хотите изменить,
        а затем прикрепите необходимые файлы.
      </p>
      <p>
        Посмотрите, как изменить данные в
        короткой видеоинструкции, которая находится ниже.
        <Video class="support-static-information__video-2"/>
      </p>
    </tab>
    <tab title-slot="vue-5">
      <p>
        Откройте главную страницу личного кабинета. В правом углу,
        где отображен лицевой счет, нажмите на стрелочку (инфографика).
        Из выпадающего меню выберите опцию "Добавить лицевой счет".
      </p>
    </tab>
    <tab title-slot="vue-6">
      <p>
        Если вы находитесь в аккаунте, зайдите в
        настройки аккаунта и выберите опцию "изменить пароль".
      </p>
      <p>
        Если вы вышли из аккаунта и не можете в него войти,
        вы можете восстановить доступ. Для этого на странице
        входа в личный кабинет нажмите "Восстановить пароль" и следуйте подсказкам.
      </p>
    </tab>
    <tab title-slot="vue-7">
      <p>
        Для этого зайдите в настройки аккаунта (инфографика)
        и выберите опцию "изменить номер телефона".
      </p>
    </tab>
    <tab title-slot="vue-8">
      <p>
        Зайдите в настройки аккаунта (инфографика) и выберите опцию "Удалить аккаунт".
      </p>
    </tab>
    <tab title-slot="vue-9">
      <p>
        Перейдите на главную страницу личного кабинета и выберите вкладку "Поддержка".
      </p>
      <p>
        Найдите интересующую вас тему и задайте вопрос менеджеру.
        После этих действий на странице появится онлайн-чат,
        где будет происходить диалог с менеджером.
      </p>
      <p>
        Также вы можете связаться с оператором по телефону: 0661233445
      </p>
    </tab>
    <tab title-slot="vue-10">
      <p>
        Шаг 1. В своем личном кабинете добавьте новый (дополнительный)
        лицевой счет с того адреса, на который вы переехали.
      </p>
      <p>
        Шаг 2. Сообщите оператору о том, что хотите поменять основной
        лицевой счет на новый. Не забудьте указать номер нового лицевого счета.
      </p>
    </tab>

    <template slot="vue">
      <div class="support-static-information__tabs-title">
        01
        <h2>
          Чем ЛК удобнее бумажной квитанции? 
        </h2>
      </div>
    </template>
    <template slot="vue-2">
      <div class="support-static-information__tabs-title">
        02
        <h2>
          Как оплатить счет онлайн?
        </h2>
      </div>
    </template>
    <template slot="vue-3">
      <div class="support-static-information__tabs-title">
        03
        <h2>
          Как проверить, что счет оплачен?
        </h2>
      </div>
    </template>
    <template slot="vue-4">
      <div class="support-static-information__tabs-title">
        04
        <h2>
          Неправильно указана информация, привязанная к лицевому счету. Как изменить?
        </h2>
      </div>
    </template>
    <template slot="vue-5">
      <div class="support-static-information__tabs-title">
        05
        <h2>
          Как добавить лицевой счет?
        </h2>
      </div>
    </template>
    <template slot="vue-6">
      <div class="support-static-information__tabs-title">
        06
        <h2>
          Не помню пароль от личного кабинета. Как я могу его изменить?
        </h2>
      </div>
    </template>
    <template slot="vue-7">
      <div class="support-static-information__tabs-title">
        07
        <h2>
           Как я могу изменить логин (номер телефона)?
        </h2>
      </div>
    </template>
    <template slot="vue-8">
      <div class="support-static-information__tabs-title">
        08
        <h2>
          Как удалить свой аккаунт?
        </h2>
      </div>
    </template>
    <template slot="vue-9">
      <div class="support-static-information__tabs-title">
        09
        <h2>
          Как связаться со службой поддержки?
        </h2>
      </div>
    </template>
    <template slot="vue-10">
      <div class="support-static-information__tabs-title">
        10
        <h2>
          Что, если я переезжаю? Как изменить данные личного кабинета?
        </h2>
      </div>
    </template>
  </tabs>
  </div>
</template>

<script>
import Video from '../organisms/Video/video.vue';

export default {
  name: 'SupportStaticInformation',
  components: {
    Video,
  }
}
</script>

<style lang="scss" scoped>
  .support-static-information {

    &__title {
      @include h1;
      justify-content: center;
    } 

    &__hint {
      @include p;
      font-weight: normal;
      padding: 15px 24% 0 24%;
      text-align: left;
    }

    &__tabs-title {
      @include h2-bold;
      border-bottom: 1px solid rgba(42, 49, 51, 0.1);
      border-right: 1px solid rgba(42, 49, 51, 0.1);
      color: $elementsColor;
      padding-left: 15px;

      h2 {
        @include h2-bold;
        padding: 15px;

        &:hover {
          color: $elementsColor;
        }
      }
    }

    &__hint-title {
        @include p;
    }

    &__video-2 {

        /deep/ .video__background {
          width: 440px;
          height: 260px;
          border-radius: 10px;
        }
      }

    &__title-vedeo-hint {
        width: 400px;
        @include p;
        font-weight: normal;
    }

    &__video {
      width: 462px;
      margin-top: 25px;

      /deep/ .video__background {
        border-radius: 6px;
        width: 95%;
        height: 247px;
        margin-top: 0;
      }

      /deep/ .video__video-container {
        width: 95%;
        border-radius: 6px;
        margin-top: 0;
        filter: blur(1px);
      }
    }
  }
  
  /deep/ .video-container__title-block {
      display: none!important;
  }
  /deep/ li[aria-selected="true"] {
      background: rgba(255, 98, 0, 0.1);
      border-left: 2px solid $elementsColor;
      border-radius: 2px;
  }
  /deep/ .vue-tablist {
    width: 50%;
    padding: 0;
    margin: 0;
  }
  /deep/ .vue-tab {
      list-style: none;
      text-align: start;

      &:hover {
        cursor: pointer;
        border-left: 2px solid $mainColor;
      }
  }
  /deep/ .vue-tab div {
    display: flex;
    align-items: center;
  }
  /deep/ .vue-tabs {
      display: flex;
      border: 1px solid rgba(42, 49, 51, 0.1);
      margin-top: 30px;
      border-radius: 8px;
  }
  /deep/ .vue-tabpanel {
    padding: 40px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    left: 40px;
    text-align: start;
    width: 450px;

    p {
      padding-bottom: 15px;
    }

    li {
      padding: 10px 0;
      
      &::marker  {
          color: $elementsColor;
      }
    }
  }
  /deep/ iframe {
    height: 287px;
    border-radius: 0!important;
  }
</style>