<template>
  <v-select
    :type="type"
    :id="id"
    :placeholder="placeholder"
    :options="options"
    @search="onSearch"
    v-model="setValue[id]"
    :no-drop="noDrop"
    :disabled="disabled"
    class="input-select"
  />
</template>
<script>
export default {
  name: 'InputSelect',
  props: {
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    onSelectSearch: {
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      noDrop: true,
    };
  },
  watch: {
    options: {
      deep: true,
      handler() {
        if (this.options && this.options.length) {
          this.noDrop = false;
          return;
        }
        this.noDrop = true;
      },
    },
  },
  methods: {
    onSearch(search) {
      const { onSelectSearch, id } = this;
      if (!onSelectSearch[id]) {
        this.$set(onSelectSearch, id, search);
      }
      if (search) {
        onSelectSearch[id] = search;
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
  .input-select {
    background: $placeholderColor;
    border: 1px solid $mainBorderColor;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 45px;
    padding: 10px 0 0 0;
    font-size: 16px;
    ::placeholder {
      color: $hintTextColor;
    }
    /deep/ .vs__search {
      padding: 0;
      margin: 0;
      border-radius: 0;
      border-right: 1px solid;
      border-right: 1px solid rgba(42, 49, 51, 0.1);
    }
    /deep/ .vs__selected + .vs__search {
      position: absolute;
      right: 0;
    }
    /deep/ .vs__selected {
      max-width: 130px;
      overflow: hidden;
      padding: 0;
      text-transform: lowercase;
    }
    /deep/ #vs1__combobox {
      padding-left: 10px;
    }
    /deep/ #townId .vs__dropdown-toggle {
      padding-left: 10px;
    }
  }
</style>
