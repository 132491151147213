<template>
  <DashboardLayout>
    <div class="dashboard-layout__component-container">
      <component
        :is="currentComponent"
      />
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from '../../layouts/DashboardLayout.vue';
import { DASHBOARD_ROUTES } from '../../router/constants';
import Account from '../../organisms/dashboard/Account.vue';
import History from '../../organisms/dashboard/History.vue';
import Profile from '../../organisms/dashboard/Profile.vue';
import Support from '../../organisms/dashboard/Support.vue';
import Dialogue from '../../organisms/dashboard/Dialogue.vue';

export default {
  name: 'DashboardPage',
  components: {
    DashboardLayout,
  },
  data() {
    return {
      currentComponent: null,
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.checkActualDashComponent();
      },
    },
  },
  beforeMount() {
    this.$store.dispatch('authStore/getUserPersonalNumbers');
  },
  methods: {
    checkActualDashComponent() {
      const { path } = this.$route;
      if (path === DASHBOARD_ROUTES.PERSONAL_ACCOUNT) { 
        this.currentComponent = Account;
      }
      if (path === DASHBOARD_ROUTES.HISTORY) {
        this.currentComponent = History;
      }
      if (path === DASHBOARD_ROUTES.PROFILE) {
        this.currentComponent = Profile;
      }
      if (path === DASHBOARD_ROUTES.SUPPORT) {
        this.currentComponent = Support;
      }
      if (path === DASHBOARD_ROUTES.DIALOGUE) {
        this.currentComponent = Dialogue;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .dashboard-layout {

    &__personal-bill-button {
      position: absolute;
      right: 30px;
      top: 30px;
    }

    &__component-container {
      width: 1030px;
      padding: 30px 40px 35px 40px;
    }
  }

  /deep/.profile-tab + .dashboard-bottons-group {
    width: 60%;
  }
  // /deep/.input-select {
  //     height: 45px!important;
  //   }
    /deep/.vs--searchable .vs__dropdown-toggle {
      padding: 9px;
    }

    /deep/#vs1__listbox {
    background: rgba(237, 244, 247, 0.86);
  }
  /deep/#vs1__listbox li {
    color: $mainColor;
    background: rgba(237, 244, 247, 0.86);
    border-top: 1px solid rgba(42, 49, 51, 0.1);
    padding: 10px;

    &:hover {
      color: $elementsColor;
    }
  }

    /deep/#vs2__listbox {
    background: rgba(237, 244, 247, 0.86);
  }

  /deep/#vs2__listbox li {
    color: $mainColor;
    background: rgba(237, 244, 247, 0.86);
    border-top: 1px solid rgba(42, 49, 51, 0.1);
    padding: 10px;

    &:hover {
      color: $elementsColor;
    }
  }
  /deep/#vs3__listbox {
    background: rgba(237, 244, 247, 0.86);
  }

  /deep/#vs3__listbox li {
    color: $mainColor;
    background: rgba(237, 244, 247, 0.86);
    border-top: 1px solid rgba(42, 49, 51, 0.1);
    padding: 10px;

    &:hover {
      color: $elementsColor;
    }
  }
  /deep/#vs4__listbox {
    background: rgba(237, 244, 247, 0.86);
  }

  /deep/#vs4__listbox li {
    color: $mainColor;
    background: rgba(237, 244, 247, 0.86);
    border-top: 1px solid rgba(42, 49, 51, 0.1);
    padding: 10px;

    &:hover {
      color: $elementsColor;
    }
  }
    /deep/ .input-select .vs__selected {
      max-width: 100%;
    }
    /deep/.vs__clear{
      display: none;
    }
    /deep/ .vs__search {
      display: none;
    }
    /deep/ .vs__selected {
      margin: 0;
    }
    /deep/ .input-select .vs__selected {
      text-transform: none;
    }
    /deep/#vs1__combobox {
      padding: 9px;
      height: 45px;

      .vs__selected-options {

          /deep/.vs__selected {
            max-width: 100%;
          }
        }
    }
</style>
