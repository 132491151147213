<template>
  <div class="user-registration">
    <CardContainer class="user-registration__container">
      <h1 class="user-registration__title">Регистрация в личный кабинет</h1>
      <ActualStepLine :actual-step="actualRegistrationStep" />
      <component
        :is="currentComponent"
        :on-submit="onSubmit"
      />
      <DecoreLine />
      <p><span class="user-registration__hint">Уже зарегистрировались? </span><LinkUnderlined :to="'/login'">Выполнить вход</LinkUnderlined></p>
    </CardContainer>
  </div>
</template>

<script>
import CardContainer from '../../atoms/CardContainer.vue';
import ActualStepLine from '../../molecules/ActualStepLine.vue';
import DecoreLine from '../../atoms/DecoreLine.vue';
import LinkUnderlined from '../../atoms/LinkUnderlined.vue';
import UserRegistrationForm from '../../forms/UserRegistrationForm.vue';
import UserRegistrationPasswordForm from '../../forms/UserRegistrationPasswordForm.vue';
import UserRegistrationCodeConfirm from '../../forms/UserRegistrationCodeConfirm.vue';
import userRegistrationAcceptAgreement from '../../forms/userRegistrationAcceptAgreement.vue';
import { userSendSmsKey, userCheckSmsKeyRegistration } from '../../api/authApi';

export default {
  name: 'UserRegistration',
  components: {
    CardContainer,
    ActualStepLine,
    DecoreLine,
    LinkUnderlined,
  },
  data() {
    return {
      actualRegistrationStep: null,
      currentComponent: null,
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.checkActualRegistrationComponent();
      },
    },
  },
  beforeDestroy() {
    this.$store.commit('authStore/setError', {});
  },
  methods: {
    onSubmit(data) {
      const { id } = this.$route.params;
      if (id === 'user') {
        this.$store.dispatch('authStore/userCreate', data).then(() => {
          this.$router.push('/registration/create-password');
        });
      }
      if (id === 'create-password') {
        this.$store.dispatch('authStore/userPasswordCreate', data).then(() => userSendSmsKey().then(() => this.$router.push('/registration/code-confirm')));
      }
      if (id === 'code-confirm') {
        this.$store.dispatch('authStore/userCheckSmsKeyRegistration', data);
      }
      if (id === 'accept-agreement') {
        this.$store.dispatch('authStore/userAcceptAgreement', data);
      }
    },
    checkActualRegistrationComponent() {
      const { id } = this.$route.params;
      if (id === 'user') {
        this.actualRegistrationStep = 1;
        this.currentComponent = UserRegistrationForm;
      }
      if (id === 'create-password') {
        this.actualRegistrationStep = 2;
        this.currentComponent = UserRegistrationPasswordForm;
      }
      if (id === 'code-confirm') {
        this.actualRegistrationStep = 3;
        this.currentComponent = UserRegistrationCodeConfirm;
      }
      if (id === 'accept-agreement') {
        this.actualRegistrationStep = 4;
        this.currentComponent = userRegistrationAcceptAgreement;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-registration {
  &__container {
    width: 445px;
    padding: 40px;
  }
  &__title {
    @include h1;
    padding-bottom: 20px;
  }

  p {
    text-align: start;
  }

  &__hint {
    font-weight: 600;
    font-size: 12px;
    padding-right: 16px;
  }
}
</style>
