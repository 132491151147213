<template>
  <div class="dashboard-user-bill">
      <div class="dashboard-user-bill__user-data" v-if="userInfo.balance < 0">
        <p class="dashboard-user-bill__name-placeholder">
          ФИО:
        </p>
        <p class="dashboard-user-bill__name">
          {{ userInfo.fullName }}
        </p>
        <p class="dashboard-user-bill__address-placeholder">
          Адрес:
        </p>
        <p class="dashboard-user-bill__address">
          {{ userInfo.address }}
        </p>
        <div class="dashboard-user-bill__amount-of-debt">
          {{ userInfo.balance }} <span>грн</span>
        </div>
        <div @click="handleBillIsOpen"> 
          <ButtonMain class="dashboard-user-bill__button">
            Оплатить задолженость
          </ButtonMain>
          </div>
      </div>
      <div class="dashboard-user-bill__user-data-positive" v-if="userInfo.balance > 0">
        <p class="dashboard-user-bill__name-placeholder">
          ФИО:
        </p>
        <p class="dashboard-user-bill__name">
          {{ userInfo.fullName }}
        </p>
        <p class="dashboard-user-bill__address-placeholder">
          Адрес:
        </p>
        <p class="dashboard-user-bill__address">
          {{ userInfo.address }}
        </p>
        <div class="dashboard-user-bill__amount-of-debt dashboard-user-bill__amount-of-debt-positive">
          + {{ userInfo.balance }} <span>грн</span>
        </div>
        <div class="dashboard-user-bill__positive">
          <PositiveLogo />
          Задолженность отсутствует
        </div>
      </div>
      <template v-if="billIsOpen">
      <ModalContainer :on-close="() => (billIsOpen = false)">
        <div>
          <h3 class="bill-model__title">
            Выберите способ оплаты
          </h3>
          <div class="bill-model__main-container">
            <div class="bill-model__container bill-model__container-top">
              <div class="bill-model__container-top-group">
              <h4 class="bill-model__title-group">
                Скачать квитанцию и оплатить офлайн
              </h4>
              <p class="bill-model__text">
                Вы можете скачать квитанцию и оплатить в отделении любого банка
              </p>
              </div>
              <div>
                <Pdf />
              </div>
            </div>
            <DecoreLine />
            <div class="bill-model__container">
              <div>
              <h4 class="bill-model__title-group">
                Оплатить онлайн
              </h4>
              <p class="bill-model__text">
                Вы можете оплатить счета онлайн, удобным вам способом
              </p>
              </div>
              <a :href="dataPs.url" target="_blank">
                <Online />
              </a>
            </div>
          </div>
        </div>
      </ModalContainer>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ButtonMain from '../atoms/ButtonMain';
import ModalContainer from '../molecules/ModalContainer';
import Pdf from '../assets/images/payments/pdf.svg';
import Online from '../assets/images/payments/online.svg';
import PositiveLogo from '../assets/images/logo/positive-logo.svg';
import DecoreLine from '../atoms/DecoreLine.vue';


export default {
  name: 'DashboardUserBill',
  data() {
    return {
      billIsOpen: false,
    }
  },
  components: {
    ButtonMain,
    ModalContainer,
    Pdf,
    Online,
    DecoreLine,
    PositiveLogo
  },
  props: {
    dataPs: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapState('authStore', ['userInfo']),
  },
  methods: {
    handleBillIsOpen() {
      this.billIsOpen = true;
    },
  }
};
</script>

<style lang="scss" scoped>
.dashboard-user-bill {

  &__user-data {
    width: 360px;
    background: rgba(255, 98, 0, 0.1);
    border-radius: 8px;
    text-align:start;
    padding: 40px 0 0 30px;
  }

  &__user-data-positive {
    background: rgba(0, 139, 179, 0.1);
    width: 360px;
    border-radius: 8px;
    text-align:start;
    padding: 40px 30px;
  }

  /deep/ .modal .modal-container {
    max-width: 530px
  }

  &__name-placeholder, &__address-placeholder {
    @include p;
  }

  &__name, &__address {
    @include h2-regular;
    padding: 10px 10px 25px 0;
  }

  &__amount-of-debt {
    font-family: 'IBMPlexSans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    line-height: 70px;
    color: $elementsColor;
    padding-bottom: 15px;

    span {
      font-size: 36px;
      font-weight: normal;
    }
  }

  &__amount-of-debt-positive {
    color: #008BB3;
  }

  &__positive {
    display: flex;
    align-items: center;
    color: #008BB3;
    font-weight: bold;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 20px;

    svg {
      margin-right: 10px;
    }
  }

  &__button {
    width: 297px;
    height: 43px;
    margin-bottom: 40px;
  }

  .bill-model {

    &__title {
      @include h1;
      padding-bottom: 30px;
    }

    &__main-container {
      border: 1px solid rgba(42, 49, 51, 0.1);
      border-radius: 6px;
      padding: 15px;
    }
    
    &__container {
      display: flex;
      align-items: center;
    }

    &__title-group {
      @include p;
      text-align: left;
      padding-bottom: 10px;
    }

    &__text {
      @include p;
      font-weight: normal;
      text-align: left;
    }
  }
}
</style>
