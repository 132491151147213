<template>
  <form @submit.prevent="handleSubmit" class="ask-a-question-form">
    <InputSelect
      :id="'dialogueTopicId'"
      :options="options"
      :placeholder="'Выберите тему вопроса'"
      :set-value="userQuestion"
    />
    <div class="textarea-file">
      <InputTextarea
        :placeholder="'Напишите Ваш вопрос'"
        :id="'message'"
        :set-value="userQuestion"
      />
      <FileUploaderWithModal
        class="ask-a-question-form__file-input"
        :set-value="userQuestion"
        :id="'images'"
      />
      <ButtonMain class="ask-a-question-form__submit" type="submit">
        Отправить
        <Send class="ask-a-question-form__submit-svg"/>
      </ButtonMain>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import InputSelect from '../atoms/InputSelect';
import ButtonMain from '../atoms/ButtonMain';
import InputTextarea from '../atoms/InputTextarea.vue';
import FileUploaderWithModal from '../molecules/FileUploaderWithModal.vue';
import Send from '../assets/images/arrow/send.svg';

export default {
  name: 'AskAQuestionForm',
  components: {
    InputSelect,
    ButtonMain,
    FileUploaderWithModal,
    InputTextarea,
    Send,
  },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userQuestion: {
        images: [],
      },
    };
  },
  computed: {
    ...mapState('authStore', ['userInfo']),
  },
  methods: {
    handleSubmit() {
      const { userQuestion } = this;
      const { fullName } = this.userInfo;
      const data = {
        ...userQuestion,
        newFullName: fullName,
        dialogueTopicId: userQuestion.dialogueTopicId?.id,
      };
      this.onSubmit(data);
      this.userQuestion = {
        images: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .ask-a-question-form {

    .textarea-file {
      position: relative;
    }

    &__file-input {
      position: absolute;
      bottom: 25px;
      left: 5px;

      input {
        display: none;
      }
    }

    &__files-container {
      display: flex;
      flex-wrap: wrap;
    }

    &__submit {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 173px;
      height: 43px;
    }

    &__submit-svg {
      position: relative;
      top: 2px;
    }

  }
  #dialogueTopicId {

    height: 100%;
    padding: 0;
    margin-bottom: 14px;
  }

  .file-add-text:hover {
    cursor: pointer;
    color: $elementsColor;
  }

  #dialogueTopicId {

    /deep/ .input-select .vs__search {
      border-right: none;
    }
  }

</style>
