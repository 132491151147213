<template>
  <textarea
    :id="id"
    :placeholder="placeholder"
    v-model="setValue[id]"
    :disabled="disabled"
    class="textarea"
  />
</template>
<script>
export default {
  name: 'InputTextarea',
  props: {
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
  .textarea {
    background: $placeholderColor;
    border: 1px solid $mainBorderColor;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 200px;
    padding: 10px;
    font-size: 16px;
    &::placeholder {
      color: $hintTextColor;
    }
  }
</style>
