<template>
  <div class="dialogue-wrapper-admin">
    <DialogWrap>
      <div v-for="messege in dialogue.messages.slice().reverse()" :key="messege.id" class="dialogue-wrapper-admin__container">
        <div v-if="messege.author === 'Пользователь'" class="user dialogue-wrapper-admin__item">
          <div class="dialogue-wrapper-admin__title-group">
            <h3>
              {{ messege.author }}
            </h3>
            <span>
              {{ messege.createdAt.slice(0,10) }}
            </span>
          </div>
          <p class="dialogue-wrapper-admin__message">
            {{ messege.message }}
          </p>
          <div class="dialogue-wrapper-admin__file" v-for="image in messege.images" :key="image.id">
            <FileContainer />
            {{ image.slice(45) }}
          </div>
        </div>
        <div v-if="messege.author !== 'Пользователь'" class="operator dialogue-wrapper-admin__item">
          <div class="dialogue-wrapper-admin__title-group">
            <h3>
              {{ messege.author }}
            </h3>
            <span>
              {{ messege.createdAt.slice(0,10) }}
            </span>
          </div>
          <p class="dialogue-wrapper-admin__message">
            {{ messege.message }}
          </p>
          <div class="dialogue-wrapper-admin__file" v-for="image in messege.images" :key="image.id">
            <FileContainer />
            {{ image.slice(45) }}
          </div>
        </div>
      </div>
    </DialogWrap>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getOperatorMessagesByDialogueId, createMessage } from '../api/supportApi';
import convertToFormData from '../helpers/convertToFormData';
import DialogWrap from '../atoms/DialogWrap.vue';
import FileContainer from '../atoms/FileContainer.vue';

export default {
  name: 'DialogueWrapperAdmin',
  components: {
    DialogWrap,
    FileContainer,
  },
  data() {
    return {
      error: {},
      options: [],
      message: [],
    };
  },
  props: {
    dialogue: {
      type: Array,
      default() {
        return {};
      },
    },
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .dialogue-wrapper-admin {

    padding: 15px 40px 40px 40px;
    position: relative;
    /deep/ .dialog-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: 50px;
    }

    &__container {
      width: 100%;
    }

    &__title-group {
        display: flex;
        align-items: center;
        font-size: $littleFontsSize;
        color: $mainColor;

        h3 {
          padding-right: 10px;
        }
    }

    &__file {
      display: flex;
      align-items: center;
      font-size: $littleFontsSize;
      color: $hintTextColor;
    }

    &__message {
      @include p;
      font-weight: normal;
      padding: 10px 0;
    }

    &__item {

      + .ask-a-question-form {
          padding: 0 40px;
          position: absolute;
          bottom: 25px;
          width: 92.7%;
          height: 70px;
          right: 0;

        /deep/ #dialogueTopicId {
            display: none;
          }

        /deep/ .textarea {
            height: auto;
            padding: 15px 10px 0px 45px;
        }
      }
    }
  }
    .user {
      background: $mainSecondColor;
      border-radius: 6px;
      margin: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding: 15px;
      width: 70%;
      margin-left: 15px;

      &::before {
        content: '';
        position: absolute;
        border: 20px solid transparent;
        left: -30px;
        bottom: 0;
        border: 20px solid transparent;
        border-bottom: 0px solid $mainSecondColor;
        border-right: 20px solid $mainSecondColor;
      }
    }

    .operator {
      background: #d8e0e2;
      border-radius: 6px;
      margin: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding: 15px;
      margin-left: 30%;
      margin-right: 30px;

      &::before {
        content: '';
        position: absolute;
        border: 20px solid transparent;
        right: -30px;
        bottom: 0;
        border: 20px solid transparent;
        border-bottom: 0px solid #d8e0e2;
        border-left: 20px solid #d8e0e2;
      }
    }

    /deep/ .file-add-text {
      display: none;
    }
    /deep/ .ask-a-question-form__file-input {
        bottom: 17px;
        left: 10px;
    }
    /deep/ .ask-a-question-form__submit {
        bottom: 10px;
    }

    /deep/.file-container__upload-status {
      display: none;
    }
    /deep/ .dialog-wrap__scroll {
      width: 100%;
    }
</style>
