export const DASHBOARD_ROUTES = {
  PERSONAL_ACCOUNT: '/dashboard/account',
  HISTORY: '/dashboard/history',
  PROFILE: '/dashboard/profile',
  SUPPORT: '/dashboard/support',
  DIALOGUE: '/dashboard/dialogue/',
};

export const dashboardNavigationLinks = [
  {
    title: 'Состояние счета',
    to: DASHBOARD_ROUTES.PERSONAL_ACCOUNT,
  },
  {
    title: 'История',
    to: DASHBOARD_ROUTES.HISTORY,
  },
  {
    title: 'Профиль',
    to: DASHBOARD_ROUTES.PROFILE,
  },
  {
    title: 'Поддержка',
    to: DASHBOARD_ROUTES.SUPPORT,
  },
];

export const dashboardMenuLinks = [
  {
    title: 'Состояние счета',
    to: DASHBOARD_ROUTES.PERSONAL_ACCOUNT,
  },
  {
    title: 'История платежей',
    to: DASHBOARD_ROUTES.HISTORY,
  },
  {
    title: 'Профиль',
    to: DASHBOARD_ROUTES.PROFILE,
  },
  {
    title: 'Служба поддержки',
    to: DASHBOARD_ROUTES.SUPPORT,
  },
];

export const ADMIN_ROUTES = {
  SEARCH: '/admin/search',
  REQUESTS: '/admin/requests',
  DIALOGUES: '/admin/dialogues',
  USERINFO: '/admin-new/user-info',
  REQUESTCHANGEDATA: '/admin-new/request',
  CHAT: '/admin/chat',

};

export const adminNavigationLinks = [
  {
    title: 'Поиск',
    to: ADMIN_ROUTES.SEARCH,
  },
  {
    title: 'Запросы',
    to: ADMIN_ROUTES.REQUESTS,
  },
  {
    title: 'Диалоги',
    to: ADMIN_ROUTES.DIALOGUES,
  },
]

export const adminMenuLinks = [
  {
    title: 'Поиск',
    to: ADMIN_ROUTES.SEARCH,
  },
  {
    title: 'Запросы',
    to: ADMIN_ROUTES.REQUESTS,
  },
  {
    title: 'Диалоги',
    to: ADMIN_ROUTES.DIALOGUES,
  },
]