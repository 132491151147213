<template>
  <div class="admin-bottons-group">
    <ButtonMain v-if="isEditForm" @click.native="handleClick">
      Все данные верны
    </ButtonMain>
    <ButtonMain v-else  @click.native="handleClick">
      Изменить данные
    </ButtonMain>
    <ButtonSecondary>
      Сообщить об ошибке
    </ButtonSecondary>
    <ButtonSecondary class="admin-bottons-group-group__cancel" v-if="cancelIsShowed" @click.native="handleCancel">
      Отмена
    </ButtonSecondary>
  </div>
</template>

<script>
import ButtonSecondary from '../atoms/ButtonSecondary.vue';
import ButtonMain from '../atoms/ButtonMain.vue';

export default {
  name: 'AdminButtonsGroup',
  props: {
    isEditForm: {
      type: Boolean,
      default: false,
    },
    handleClick: {
      type: Function,
      default() {
        return {};
      },
    },
    handleCancel: {
      type: Function,
      default() {
        return {};
      },
    },
    cancelIsShowed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ButtonSecondary,
    ButtonMain,
  },
  methods: {
    goToSupport() {
      this.$router.push('/dashboard/support');
    },
  },
};
</script>

<style lang="scss" scoped>
  .admin-bottons-group-group {
    display: flex;
    height: 43px;

    button {
      margin-right: 25px;
    }
    
    &__cancel {
      background: rgba(237, 244, 247, 0.6);
      border: 1px solid rgba(42, 49, 51, 0.1);
      color: $mainColor;
      margin-right: 0!important;
      margin-left: 32%;
      width: 50%;

      &:hover {
        @include cancelButtonHover;
      }

      &:active {
        @include cancelButtonActive ;
      }
    }
  }
</style>
