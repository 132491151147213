<template>
  <div class="profile">
    <UserProfileEditTab v-if="isEditForm" :user="userInfo" :user-data-to-edit="userData"/>
    <UserProfileTab v-else :user="userInfo" />
    <DashboardButtonsGroup
      v-if="checkUserProfileAccount"
      :is-edit-form="isEditForm"
      :handle-click="isEditForm ? handleSubmit : isEditFormActive"
      :handle-cancel="handleCancel"
      :cancel-is-showed="isEditForm"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DashboardButtonsGroup from '../../molecules/DashboardButtonsGroup.vue';
import UserProfileEditTab from '../UserProfileEditTab.vue';
import UserProfileTab from '../../molecules/UserProfileTab.vue';

export default {
  name: 'ProfileTab',
  components: {
    UserProfileTab,
    DashboardButtonsGroup,
    UserProfileEditTab,
  },
  data() {
    return {
      isEditForm: false,
      userData: {
        privilegeImages: [],
        fullNameImages: [],
      },
    };
  },
  computed: {
    ...mapState('authStore', ['userInfo', 'actualAccount']),
    ...mapGetters('authStore', ['checkUserProfileAccount']),
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
        const { actualAccount } = this;
      },
    },
  },
  methods: {
    isEditFormActive() {
      this.isEditForm = true;
    },
    handleSubmit() {
      const personal_number = this.actualAccount.personalNumber;
      this.$store.dispatch('authStore/userCreateQuery', ({...this.userData, personal_number})).then(() => {
        this.isEditForm = false;
      });
    },
    handleCancel() {
      this.isEditForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .video-container__title-block {
    display: none;
  }
  /deep/ .video__background {
      width: 177px;
      margin-top: 0;
    }

    /deep/ #background {
      height: 120px!important;
      border-bottom-left-radius: 0!important;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
    /deep/.video__play-title {
      display: none;
    }
    /deep/ .video__play {
      top: 20%;
      left: 50%;
    }
    /deep/ .video__video-container {
      margin-top: 0;
      width: 215px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
    /deep/ video {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
</style>
