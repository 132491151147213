<template>
  <div class="chat">
    <div v-for="titleInfo in info.userList" :key="titleInfo.id" v-if="titleInfo.id == userId" class="chat__title-block">
      <h2 class="dialogue__back" @click="$router.go(-1)">
        <ArrowBack class="arrow-back-black"/>
      </h2>
      <div class="chat__title-name">
        Диалог с {{ titleInfo.name }}
      </div>
      <div class="chat__label">
        Тема: <span>{{ dialogue.dialogueLabel }}</span>
      </div>
      <div class="chat__personal-number">
        № {{ titleInfo.personalNumber }}
      </div>
      <div class="chat__address">
        {{ titleInfo.address }}
      </div>
    </div>
      <DialogueWrapperAdmin :dialogue="dialogue" />
      <AskAQuestionFormAdmin :on-submit="onSubmit" :error="error" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import convertToFormData from '../../helpers/convertToFormData';
import { getOperatorMessagesByDialogueId, getUserList, operatorCreateMessage } from '../../api/supportApi';
import ArrowBack from '../../assets/images/arrow/back.svg';
import DialogueWrapperAdmin from '../../molecules/DialogueWrapperAdmin.vue';
import AskAQuestionFormAdmin from '../../forms/AskAQuestionFormAdmin.vue';

export default {
  name: 'Chat',
  components: {
    ArrowBack,
    DialogueWrapperAdmin,
    AskAQuestionFormAdmin
  },
  data() {
    return {
      info: [],
      dialogue: [],
      userId: this.$route.query.userId,
    }
  },
  beforeMount() {
    this.getOperatorMessagesByDialogueId();
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
        this.getOperatorMessagesByDialogueId();
        const params = {  };
          getUserList(params).then((res) => {
              this.info = res.data;
          }).catch((error) => {
            const { data } = error.response;
            this.error = data;
          });
      },
    },
  },
  methods: {
    getOperatorMessagesByDialogueId() {
        const { id } = this.$route.query;
        const user_id = this.$route.query.userId;
        const params = { };
        getOperatorMessagesByDialogueId({ dialogueId: id, user_id: user_id }, params).then((res) => {
          this.dialogue = res.data;
        });
    },
    onSubmit(data) {
      const { id } = this.$route.query;
      operatorCreateMessage(convertToFormData({...data,dialogueId: id})).then(() => {
        this.error = {};
        this.getOperatorMessagesByDialogueId();
      }).catch((error) => {
        const { data } = error.response;
        this.error = data;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
  .chat {

    &__title-block {
      display: flex;
      padding: 40px 40px 0 40px;
      align-items: center;
      justify-content: space-between;
    }

    &__title-name {
      @include h1;
    }

    &__label {
      @include p;

      span {
        font-weight: normal;
        padding-left: 3px;
      }
    }

    &__personal-number, &__address {
      font-weight: 600;
      font-size: $littleFontsSize;
      color: $hintTextColor;
    }
  }
  .dialogue__back {

    /deep/ svg path {
      stroke: $mainColor;
    }

    &:hover {
      cursor: pointer;

      /deep/ svg path {
        stroke: $elementsColor;
      }
    }
  }
</style>