<template>
  <button><slot /></button>
</template>

<script>
export default {
  name: 'ButtonUnderlined',
};
</script>

<style lang="scss" scoped>
  button {
    border: none;
    background: transparent;
    @include link;

    &:hover {
      text-decoration: none;
    }
  }
</style>
