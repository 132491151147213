<template>
  <AuthLayout>
    <div class="login-page">
      <div class="login-page__form-container">
        <LogIn />
      </div>
      <Video class="login-page__video-container"/>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout.vue';
import LogIn from '../../organisms/Auth/LogIn.vue';
import Video from '../../organisms/Video/video.vue';

export default {
  name: 'LoginPage',
  components: {
    AuthLayout,
    LogIn,
    Video,
  },
};
</script>

<style lang="scss" scoped>
  .login-page {
    display: flex;
    justify-content: center;

    &__video-container {
      width: 635px;
      margin-left: 30px;
    }
  }
  /deep/ iframe {
    height: 388px;
  }
</style>
