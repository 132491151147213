<template>
  <div class="dashboard-header">
    <div class="dashboard-header__left">
      <Logo />
      <div class="dashboard-header__contacts">
        <ContactInfo />
      </div>
    </div>
    <div class="dashboard-header__container">
      <div class="dashboard-header__user-controls">
        <UserHeaderControls />
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '../assets/images/logo/logo.svg';
import ContactInfo from '../molecules/ContactInfo.vue';
import UserHeaderControls from './UserHeaderControls.vue';

export default {
  name: 'DashboardHeader',
  components: {
    Logo,
    ContactInfo,
    UserHeaderControls,
  },
};
</script>

<style lang="scss" scoped>
.dashboard-header {
  padding-bottom: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__container {
    display: flex;
  }

  &__left {
    display: flex;
    align-items: center;
  }

}
</style>
