<template>
  <div class="history">
    <div class="history__container">
      <div class="history__group">
        <h1>История транзакций</h1>
        <div class="history__group-year">
          {{ this.yearNow }} год
          <MenuControlButton
            @click="handleClick"
            class="history__svg-year"
            :class="[menuIsOpen ? 'user-header-menu__buttton--open' : null]"
          />

          <template v-if="menuIsOpen">
            <div class="history__select">
              <div
                v-for="year in this.years.allYears"
                :key="year.all"
                @click="changeYear(year)"
                :class="[menuIsOpen ? 'user-header-menu--open' : null]"
              >
                {{ year.all }} год
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="history__group history__group-sm">
        <div class="history__group-debit" v-if="userInfo.balance > 0">
          <div class="history__debt-title">Переплата по лицевому счету:</div>
          <div class="history__debt-sum">{{ userInfo.balance }} грн</div>
        </div>
        <div class="history__group-debit" v-if="userInfo.balance < 0">
          <div class="history__debt-title">Долг по лицевому счету:</div>
          <div class="history__debt-sum">{{ userInfo.balance }} грн</div>
        </div>
        <div @click="handleBillIsOpen">
          <ButtonMain class="history__button-paymant">
            Перейти к оплате
          </ButtonMain>
        </div>
        <template v-if="billIsOpen">
          <ModalContainer :on-close="() => (billIsOpen = false)">
            <div>
              <h3 class="bill-model__title">Выберите способ оплаты</h3>
              <div class="bill-model__main-container">
                <div class="bill-model__container bill-model__container-top">
                  <div class="bill-model__container-top-group">
                    <h4 class="bill-model__title-group">
                      Скачать квитанцию и оплатить офлайн
                    </h4>
                    <p class="bill-model__text">
                      Вы можете скачать квитанцию и оплатить в отделении любого
                      банка
                    </p>
                  </div>
                  <div>
                    <Pdf />
                  </div>
                </div>
                <DecoreLine />
                <div class="bill-model__container">
                  <div>
                    <h4 class="bill-model__title-group">Оплатить онлайн</h4>
                    <p class="bill-model__text">
                      Вы можете оплатить счета онлайн, удобным вам способом
                    </p>
                  </div>
                  <a :href="dataPs.url" target="_blank">
                    <Online />
                  </a>
                  <!-- <MessageError v-for="message in error" :key="message.id">
                    {{ message[0] }}
                  </MessageError> -->
                </div>
              </div>
            </div>
          </ModalContainer>
        </template>
      </div>
    </div>
    <HistoryTable :history="history" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ButtonMain from "../../atoms/ButtonMain";
import HistoryTable from "../../molecules/HistoryTable";
import { getUserHistory, getUrlForPs } from "../../api/supportApi";
import MenuControlButton from "../../assets/images/icons/menu_control_button.svg";
import ModalContainer from "../../molecules/ModalContainer";
import Pdf from "../../assets/images/payments/pdf.svg";
import Online from "../../assets/images/payments/online.svg";
import DecoreLine from "../../atoms/DecoreLine.vue";
// import MessageError from '../../atoms/MessageError.vue';

export default {
  name: "History",
  data() {
    return {
      history: [],
      // error: {},
      dataPs: {},
      menuIsOpen: false,
      billIsOpen: false,
      yearNow: "2021",
      years: {
        allYears: [
          {
            all: "2021",
          },
          {
            all: "2020",
          },
          {
            all: "2019",
          },
        ],
      },
    };
  },
  components: {
    ButtonMain,
    HistoryTable,
    MenuControlButton,
    ModalContainer,
    Pdf,
    Online,
    DecoreLine,
    // MessageError
  },
  computed: {
    ...mapState("authStore", ["actualAccount", "userInfo"]),
    filteredPersonalAccounts() {
      return this.personalAccounts.filter(
        (el) => el.personalNumber !== this.actualAccount?.personalNumber
      );
    },
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
        const { actualAccount } = this;
        if (actualAccount && Object.keys(actualAccount).length) {
          const params = { personal_number: this.actualAccount.personalNumber };
          getUserHistory(params)
            .then((res) => {
              this.history = res.data;
            })
            .catch((error) => {
              const { data } = error.response;
              this.error = data;
            });
            getUrlForPs(params)
              .then((res) => {
              this.dataPs = res.data;
            })
            .catch((error ) => {
              const { data } = error .response;
              this.error  = data;
            });
          }
        this.menuIsOpen = false;
      },
    },
  },
  methods: {
    handleClick() {
      this.menuIsOpen = !this.menuIsOpen;
    },
    openAccountEdit() {
      this.isAccountEditActive = true;
      this.menuIsOpen = false;
    },
    handleBillIsOpen() {
      this.billIsOpen = true;
    },
    changeYear(year) {
      const { actualAccount } = this;
      const params = {
        personal_number: this.actualAccount.personalNumber,
        year: year.all,
      };
      getUserHistory(params)
        .then((res) => {
          this.history = res.data;
        })
        .catch((error) => {
          const { data } = error.response;
          this.error = data;
        });
        this.menuIsOpen = !this.menuIsOpen;
        this.yearNow = year.all
    },
  },
};
</script>

<style lang="scss" scoped>
.user-header-menu {
  &__buttton {
    &--open {
      transform: rotate(180deg);
    }
  }
}
.history {
  &__container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-right: 10px;
  }

  &__button-paymant {
    width: 220px;
    height: 43px;
    margin-left: 25px;
  }

  &__group-debit {
    display: flex;
  }

  &__group {
    display: flex;
    align-items: center;
  }

  &__debt-title {
    @include p;
    font-weight: normal;
  }

  &__debt-sum {
    @include p;
    padding-left: 5px;
  }

  &__group-year {
    @include p;
    padding: 10px;
    align-items: center;
    margin-left: 10px;
    background: #edf4f7;
    border-radius: 6px;
    position: relative;
  }

  &__svg-year {
    margin-left: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  &__svg-year-select {
    position: absolute;
    top: 35px;
    background: #edf4f7;
    padding: 10px;
    width: 93px;
    right: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &__select {
    position: absolute;
    top: 35px;
    width: 100%;
    left: 0;
    background: #edf4f7;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    div {
      padding: 10px 0;
      text-align: left;
      margin-left: 10px;

      &:hover {
        cursor: pointer;
        color: $elementsColor;
      }
    }
  }
  .bill-model {
    &__title {
      @include h1;
      padding-bottom: 30px;
    }

    &__main-container {
      border: 1px solid rgba(42, 49, 51, 0.1);
      border-radius: 6px;
      padding: 15px;
    }

    &__container {
      display: flex;
      align-items: center;
    }

    &__title-group {
      @include p;
      text-align: left;
      padding-bottom: 10px;
    }

    &__text {
      @include p;
      font-weight: normal;
      text-align: left;
    }
  }
}
</style>
