import Vue from 'vue';
import VueRouter from 'vue-router';

import DashboardPage from '../pages/dashboardPages/DashboardPage.vue';
import Admin from '../pages/admin/Admin.vue';
import AdminWithoutNav from '../pages/adminNew/AdminWithoutNav.vue';
import LoginAdmin from '../pages/admin/LoginAdmin.vue';
import LoginPage from '../pages/authPages/LoginPage.vue';
import ForgotPasswordPage from '../pages/authPages/ForgotPasswordPage.vue';
import PasswordCodeConfirmPage from '../pages/authPages/PasswordCodeConfirmPage.vue';
import CreatePasswordPage from '../pages/authPages/CreatePasswordPage.vue';
import UserRegistrationPage from '../pages/authPages/UserRegistrationPage.vue';
import { isTokensInStore } from '../helpers/auth';

Vue.use(VueRouter);

const checkPrivateRoute = ((to, from, next) => {
  if (to.fullPath === '/registration/user') return next();
  if (!isTokensInStore()) return next('/login');
  return next();
});

const routes = [
  { path: '/', redirect: '/dashboard' },
  { path: '/login', name: 'LoginPage', component: LoginPage },
  {
    path: '/forgot-password', name: 'ForgotPassword', component: ForgotPasswordPage,
  },
  {
    path: '/password-code-confirm',
    name: 'PasswordCodeConfirm',
    component: PasswordCodeConfirmPage,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: '/create-password',
    name: 'CreatePasswordPage',
    component: CreatePasswordPage,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: '/registration',
    name: 'RegistrationPageBase',
    beforeEnter: (to, from, next) => next('/registration/user'),
  },
  {
    path: '/registration/:id',
    name: 'Registration',
    component: UserRegistrationPage,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter: (to, from, next) => next('/dashboard/profile'),
  },
  {
    path: '/dashboard/:id',
    name: 'DashboardPage',
    component: DashboardPage,
    beforeEnter: (to, from, next) => checkPrivateRoute(to, from, next),
  },
  {
    path: '/admin',
    name: 'LoginAdmin',
    component: LoginAdmin,
  },
  {
    path: '/admin/:id',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/admin-new/:id',
    name: 'AdminWithoutNav',
    component: AdminWithoutNav,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
