<template>
  <div class="dialogues-wrapper">
    <DialogWrap>

      <template v-if="dialogues.length">
        <div class="dialogues-wrapper__container">
        <div class="dialogues-wrapper__wrapp" v-for="dialogue in dialogues" :key="dialogue.id" @click="handleClick(dialogue)">
        <div class="dialogues-wrapper__title-section">
          <div>
          <h3>
              Тема:
            <span>
              {{dialogue.dialogueLabel}}
            </span>
          </h3>
          </div>
          <span class="dialogues-wrapper__data">
            {{ dialogue.dialogueDate.slice(0, 10) }}
          </span>
        </div>
        <div class="dialogues-wrapper__body-section">
          <DefaultAvatar />
          <div>
            <h3>
              {{dialogue.author}}
            </h3>
            <p>
              {{dialogue.message}}
            </p>
          </div>
        </div>
        </div>
        </div>
      </template>

      <template v-else>
        <LogoDefault class="dialogues-wrapper__logo"/>
        <p class="dialogues-wrapper__info">
          Пока что не было ни одного диалога с оператором
        </p>
        <p class="dialogues-wrapper__prompt">
          Чтобы задать вопрос оператору воспользуйтесь формой справа
        </p>
      </template>

    </DialogWrap>
  </div>
</template>

<script>
import DialogWrap from '../atoms/DialogWrap.vue';
import LogoDefault from '../assets/images/logo/logo-default.svg';
import DefaultAvatar from '../assets/images/icons/default_user_avatar.svg';
import { DASHBOARD_ROUTES } from '../router/constants';

export default {
  name: 'DialoguesWrapper',
  components: {
    DialogWrap,
    LogoDefault,
    DefaultAvatar,
  },
  props: {
    dialogues: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  methods: {
    handleClick(dialogue) {
      this.$router.push({ path: DASHBOARD_ROUTES.DIALOGUE, query: { id: dialogue.dialogueId } });
    },
  },
};
</script>

<style lang="scss">
  .dialogues-wrapper {

    &__logo {
      padding: 85px 0 15px 0;
    }

    &__container {
      height: 335px;
      margin-right: 10px;
    }

    &__info {
      @include h2-regular;
      color: $hintTextColor;
      width: 262px;
      text-align: left;
      font-weight: bold;
      margin: auto;
    }

    &__prompt {
      @include p;
      color: $hintTextColor;
      width: 262px;
      text-align: left;
      font-weight: normal;
      margin: auto;
      padding-top: 15px;
    }

    &__title-section {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-bottom: 15px;

      div {

        h3 {
          @include p;

          span {
            padding-left: 5px;
            font-weight: normal;
          }
        }
      }
    }

    &__wrapp {
      padding: 15px 0;
      border-bottom: 1px solid rgba(42, 49, 51, 0.1);

      &:hover {
        cursor: pointer;
        opacity: 0.8;
        color: $elementsColor;
      }
    }

    &__body-section {
      display: flex;

      div {
        text-align: start;
        padding-left: 15px;
      }
    }

    &__data {
      font-size: $littleFontsSize;
      color: $hintTextColor;
    }
  }
</style>
