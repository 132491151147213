<template>
  <div class="dialogues">
    <div class="dialogues__title-container">
      <h1>
        Активные диалоги с клиентами
      </h1>
      <div class="admin-search">
        <input type="text" v-model="inputStr" class="admin-search__search" placeholder="Поиск запроса">
        <div>
          <Search class="admin-search__svg"/>
        </div>
      </div>
    </div>
    <DialogueAdmin :dialogues="filteredMovies" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getOperatorDialogueList } from '../../api/supportApi';
import DialogueAdmin from '../../molecules/DialogueAdmin.vue';
import Search from '../../assets/images/search/button-search.svg';

export default {
  name: 'Dialogues',
  data() {
    return {
      dialogues: [],
      inputStr: ''
    }
  },
  components: {
    DialogueAdmin,
    Search
  },
  computed: {
    filteredMovies() {
      if (this.inputStr) {
        return this.dialogues.filter(item => {
            if(item.name == null || item.personalNumber == null) {
              item++
            } else return item.name.toLowerCase().indexOf(this.inputStr.toLowerCase()) !== -1 || item.dialogueLabel.toLowerCase().indexOf(this.inputStr.toLowerCase()) !== -1 || item.personalNumber.toLowerCase().indexOf(this.inputStr.toLowerCase()) !== -1;
        });
      }
      return this.dialogues
    }
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
          const params = { };
          getOperatorDialogueList(params).then((res) => {
              this.dialogues = res.data;
          }).catch((error) => {
            const { data } = error.response;
            this.error = data;
          });
        }
      }
    }
}
</script>

<style lang="scss" scoped>
  .dialogues {

    padding: 30px 40px 0 40px;

    h1 {
      @include h1;
    }

    &__title-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
  }
  .admin-search {
    position: relative;

    &__svg {
      position: absolute;
      top: 15px;
      right: 10px;

      &:hover {
        cursor: pointer;

        /deep/ path {
          stroke: $elementsColor;
        }
      }
    }
    &__search {
      width: 480px;
      height: 45px;
      background: #EDF4F7;
      border: 1px solid rgba(42, 49, 51, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      padding-left: 10px;
      color: $hintTextColor;
      font-size: $baseFontsSize;
    }
  }
  input:focus {
    outline: none!important;
  }
</style>