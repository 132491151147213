<template>
  <div class="user-header-menu" v-click-outside="handleOutsideClick">
    <div class="user-header-menu__container" @click="handleClick">
      <DefaultAvatar />
      <h2 class="user-header-menu__user-name">{{userName}}</h2>
      <MenuControlButton :class="[menuIsOpen ? 'user-header-menu__buttton--open' : null]" />
    </div>

    <template v-if="menuIsOpen">
      <CardContainer class="user-header-menu__menu-container">
        <nav>
          <p :class="isAccountEditActive ? 'active' : ''" @click="openAccountEdit">Настройки аккаунта</p>
          <router-link v-for="link in dashboardMenuLinks" :to="link.to" :key="link.id">
            {{link.title}}
          </router-link>
        </nav>
        <p @click="handleLogOut" class="user-header-menu__exit">Выйти</p>
      </CardContainer>
    </template>

    <!-- modals -->
      <ModalContainer v-if="isAccountEditActive" :on-close="() => isAccountEditActive = false">
        <ManageAccount :user-name="userName" :handle-save="() => isAccountEditActive = false" />
      </ModalContainer>
    <!--  -->
  </div>
</template>

<script>
import DefaultAvatar from '../assets/images/icons/default_user_avatar.svg';
import MenuControlButton from '../assets/images/icons/menu_control_button.svg';
import CardContainer from '../atoms/CardContainer';
import ModalContainer from '../molecules/ModalContainer';
import ManageAccount from './Auth/ManageAccount';
import { logOut } from '../helpers/auth';
import { dashboardMenuLinks } from '../router/constants';

export default {
  name: 'UserHeaderMenu',
  props: {
    userName: {
      type: String,
      default: '',
    },
  },
  components: {
    DefaultAvatar,
    MenuControlButton,
    CardContainer,
    ModalContainer,
    ManageAccount,
  },
  data() {
    return {
      menuIsOpen: false,
      dashboardMenuLinks,
      isAccountEditActive: false,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.menuIsOpen = false;
      },
    },
  },
  methods: {
    handleClick() {
      this.menuIsOpen = !this.menuIsOpen;
    },
    handleOutsideClick() {
      if (this.menuIsOpen) {
        this.menuIsOpen = !this.menuIsOpen;
      }
    },
    handleLogOut() {
      logOut();
    },
    openAccountEdit() {
      this.isAccountEditActive = true;
      this.menuIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .user-header-menu {
    display: flex;
    align-items: center;
    position: relative;

    &__container {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      min-width: 221px;
      z-index: 15;
      padding-left: 30px;

    }

    &__user-name {
      @include h1;
      padding: 0 15px;
    }

    &__buttton {

      &--open {
        transform: rotate(180deg);
      }
    }

    &__menu-container {
      padding: 77px 30px 30px 30px;
      position: absolute;
      width: 100%;
      min-width: 255px;
      top: -30px;
      z-index: 10;
      left: 20px;
      backdrop-filter: blur(20px);

      nav {

        a, p {
          @include p;
          text-decoration: none;
          text-transform: uppercase;
          padding: 15px 0;

          &:hover {
            color: $elementsColor;
            cursor: pointer;
          }
        }
      }

      p.active {
        color: $elementsColor;
      }
    }

    nav {
      display: flex;
      flex-direction: column;
    }

    &__exit {
      @include p;
      text-transform: uppercase;
      border-top: 1px solid rgba(42, 49, 51, 0.1);
      padding-top: 30px;

      &:hover {
        cursor: pointer;
        color: $elementsColor;
      }
    }
  }
</style>
