<template>
  <div class="admin-user-list">
    <div class="admin-user-list__block" v-for="info in information" :key="info.id">
      <div class="admin-user-list__section" @click="handleClick(info)">
      <h1>
        <DefaultAvatar />
        <span class="admin-user-list__name">
            {{ info.name }}
        </span>
      </h1>
      <div class="admin-user-list__personal-namber">
        Л/c № {{ info.personalNumber }}
      </div>
      <div class="admin-user-list__address">
        {{ info.address }}
      </div>
      </div>
      <div>
        <Chat  @click="goToDialogues()" class="admin-user-list__chat-svg"/>
        <Edit @click="goToRequests()" class="admin-user-list__edit-svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { ADMIN_ROUTES } from '../router/constants';
import DefaultAvatar from '../assets/images/icons/default_user_avatar.svg';
import Edit from '../assets/images/admin/edit.svg';
import Chat from '../assets/images/admin/chat.svg';

export default {
  name: 'AdminUserList',
  components: {
    DefaultAvatar,
    Edit,
    Chat
  },
  props: {
    information: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    handleClick(info) {
      this.$router.push({ path: '/admin-new/user-info', query: { id: info.id } }); 
    },
    goToDialogues() {
      this.$router.push({ path: ADMIN_ROUTES.DIALOGUES }); 
    },
    goToRequests() {
      this.$router.push({ path: ADMIN_ROUTES.REQUESTS }); 
    },
  },
}
</script>

<style lang="scss">
  .admin-user-list {
    display: flex;
    flex-direction: column;

    &__name {
      @include p;
      display: inline-block;
      padding: 0 35px 0 17px;
      position: relative;
      bottom: 8px;
    }

    &__block {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(42, 49, 51, 0.1);
      justify-content: space-between;

      h1 {
        width: 225px;
        text-align: start;
        padding: 30px 0;
      }
    }

    &__section {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
        color: $elementsColor;

        h1 span {
          color: $elementsColor;
        }
      }
    }

    &__personal-namber {
      width: 170px;
      text-align: start;
    }

    &__address {
      width: 540px;
      text-align: start;
    }

    &__edit-svg, &__chat-svg {
      padding-left: 20px;

      &:hover {
        
        /deep/ path {
          cursor: pointer;
          fill: $elementsColor;
        }
      }
    }
  }
</style>