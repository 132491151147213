<template>
  <div class="modal" @click.self="handleCloseModal">
    <CardContainer class="modal-container">
      <CloseModal @click="handleCloseModal" />
      <slot />
    </CardContainer>
  </div>
</template>

<script>
import CardContainer from '../atoms/CardContainer.vue';
import CloseModal from '../assets/images/icons/modal_close.svg';

export default {
  name: 'ModalContainer',
  props: {
    onClose: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  components: {
    CardContainer,
    CloseModal,
  },
  methods: {
    handleCloseModal() {
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1500;

    .modal-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 460px;
      padding: 40px;
    }

    svg {
      position: absolute;
      right: -30px;
      top: -30px;
      cursor: pointer;
    }
  }
</style>
