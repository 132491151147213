<template>
  <div>
    <ConfirmToDelete v-if="!numberConfirmForm && !deleteSuccess" :handle-close="toManageAccountStep" :handle-delete-accept="() => numberConfirmForm = true" />
    <PhoneConfirmToDelete v-if="numberConfirmForm && !deleteSuccess" :closeModal="closeModal" :on-submit="deleteUserOnSubmit" />
    <div v-if="!numberConfirmForm && deleteSuccess">
      <h1>Ваш аккаунт удален</h1>
      <ButtonMain class="delete-account-button" @click.native="handleClick">
        Хорошо
      </ButtonMain>
    </div>
  </div>
</template>

<script>
import ConfirmToDelete from './ConfirmToDelete.vue';
import PhoneConfirmToDelete from './PhoneConfirmToDelete.vue';
import { logOutWithHistory } from '../../helpers/auth';
import ButtonMain from '../../atoms/ButtonMain';

export default {
  name: 'DeleteAccount',
  components: {
    ConfirmToDelete,
    PhoneConfirmToDelete,
    ButtonMain
  },
  props: {
    toManageAccountStep: {
      type: Function,
      default() {
        return {};
      },
    },
    closeModal: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      numberConfirmForm: false,
      deleteSuccess: false,
    };
  },
  methods: {
    deleteUserOnSubmit(data) {
      this.$store.dispatch('authStore/userDelete', data).then(() => {
        this.numberConfirmForm = false;
        this.deleteSuccess = true;
      });
    },
    handleClick() {
      logOutWithHistory();
      // this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
  .delete-account-button {
    width: 120px;
    height: 43px;
    margin-top: 25px;
  }
</style>
