<template>
  <div class="change-password-account">
    <div v-if="changePassForm" class="password-form">
      <h1>Изменить пароль</h1>
      <form @submit.prevent="handleSubmit">
        <InputTextWithLabel
          :id="'password'"
          :set-value="userData"
          :error="error['password']"
          :placeholder="'Введите пароль'"
        />
        <InputTextWithLabel
          :id="'passwordConfirmation'"
          :set-value="userData"
          :error="error['passwordConfirmation']"
          :placeholder="'Введите пароль еще раз'"
        />
        <MessageError v-for="message in error['nonFieldError']" :key="message">
          {{message}}
        </MessageError>
        <ButtonMain class="change-password-account__button" :type="'submit'">
          Изменить пароль
        </ButtonMain>
      </form>
    </div>
    <div v-if="!changePassForm">
      <h1 class="change-password-account__popup-title">Пароль изменен</h1>
      <p class="change-password-account__popup-text">Ваш пароль успешно изменен!</p>
      <ButtonMain @click.native="toManageAccountStep" class="change-password-account__popup-button">
        Хорошо
      </ButtonMain>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InputTextWithLabel from '../../molecules/InputTextWithLabel.vue';
import ButtonMain from '../../atoms/ButtonMain.vue';
import MessageError from '../../atoms/MessageError.vue';

export default {
  name: 'ChangePasswordAccount',
  components: {
    InputTextWithLabel,
    ButtonMain,
    MessageError,
  },
  props: {
    toManageAccountStep: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userData: {},
      changePassForm: true,
    };
  },
  beforeDestroy() {
    this.$store.commit('authStore/setError', {});
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch('authStore/userPasswordCreate', this.userData).then(() => {
        this.changePassForm = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .change-password-account {
    
    &__button {
      margin-top: 25px;
      width: 220px;
      height: 43px;
    }

    &__popup-title {
      @include h1;
    }

    &__popup-text {
      @include p;
      padding: 20px 0;
    }

    &__popup-button {
      height: 43px;
      width: 120px;
    }
  }
</style>
