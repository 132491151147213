<template>
  <div class="new-phone-create-form">
    <h1 class="new-phone-create-form__title">Изменить номер телефона</h1>
    <p class="new-phone-create-form__info-text">
      Обратите внимание, что новый номер будет использоваться в качестве логина для входа в личный кабинет «УБЕРУ»
    </p>
    <form @submit.prevent="handleSubmit">
      <InputPhoneWithLabel
        :id="'phone'"
        :set-value="userData"
        :error="error['phone']"
        :type="'tel'"
        :placeholder="'Введите новый номер телефона'"
      />
      <MessageError v-for="message in error['nonFieldError']" :key="message">
        {{message}}
      </MessageError>
      <ButtonMain :type="'submit'" class="restore-password-form__button-entrance new-phone-create-form__button-entrance">
        Получить код
      </ButtonMain>
    </form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import InputPhoneWithLabel from '../../molecules/InputPhoneWithLabel.vue';
import ButtonMain from '../../atoms/ButtonMain.vue';
import MessageError from '../../atoms/MessageError.vue';

export default {
  name: 'NewPhoneCreateForm',
  components: {
    InputPhoneWithLabel,
    ButtonMain,
    MessageError,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userData: {},
    };
  },
  beforeDestroy() {
    this.$store.commit('authStore/setError', {});
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSubmit() {
      this.onSubmit(this.userData);
    },
  },
};
</script>
<style lang="scss" scoped>
  .new-phone-create-form {
  
    &__title {
      @include h1;
      padding-bottom: 25px;
    }

    &__info-text {
      @include p;
      font-weight: 400;
      padding-bottom: 25px;
    }

    &__button-entrance {
      margin-top: 25px;
      width: 209px;
      height: 43px;
    }
  }
</style>
