<template>
  <AuthLayout
    :footer-is-hidden="true"
    :contacts-is-hidden="true"
  >
    <div class="restore-password-page">
      <ForgotPassword />
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout.vue';
import ForgotPassword from '../../organisms/Auth/ForgotPassword.vue';

export default {
  name: 'ForgotPasswordPage',
  components: {
    AuthLayout,
    ForgotPassword,
  },
};
</script>

<style lang="scss" scoped>
  .restore-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
