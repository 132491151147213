<template>
  <div class="file-container">
    <div class="file-container__icon" >
      <FileDeleteIcon @click="deleteCallBack"/>
    </div>
    <div class="file-container__file-name">
      <p>{{ fileName }}</p>
      <div class="file-container__upload-status">
	      <div class="progress">
		      <div class="progress-bar">
		    </div>
	    </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileDeleteIcon from '../assets/images/icons/file_delete.svg';

export default {
  name: 'FileContainer',
  props: {
    fileName: {
      type: String,
      default: '',
    },
    deleteCallBack: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  components: {
    FileDeleteIcon,
  },
};
</script>

<style lang="scss" scoped>
  .file-container {

    width: 50%;
    display: flex;
    align-items: center;

    &__icon {
      width: 20%;
    }

    // &__file-name {
    //   width: 100%;
    // }

    &__upload-status {
			margin-top: 3px;
			display: none;
    }
  }
</style>

<style scoped>
.progress {
	background-color: rgb(229,233,235);
	height: 2px;
	position: relative;
	width: 175px;
}
.progress-bar {
	height: 100%;
	position: relative;
	background-image: linear-gradient(to right, #FF6200,#FF6200, #FF6200, #FF6200,#FF6200, #FF6200);
		background-image: -o-linear-gradient(to right, #FF6200,#FF6200, #FF6200, #FF6200,#FF6200, #FF6200);
		background-image: -ms-linear-gradient(to right, #FF6200,#FF6200, #FF6200, #FF6200,#FF6200, #FF6200);
		background-image: -webkit-linear-gradient(to right, #FF6200,#FF6200, #FF6200, #FF6200,#FF6200, #FF6200);
		background-image: -moz-linear-gradient(to right, #FF6200,#FF6200, #FF6200, #FF6200,#FF6200, #FF6200);
	animation: cssload-width 3.45s cubic-bezier(0.45, 0, 1, 1);
		-o-animation: cssload-width 3.45s cubic-bezier(0.45, 0, 1, 1);
		-ms-animation: cssload-width 3.45s cubic-bezier(0.45, 0, 1, 1);
		-webkit-animation: cssload-width 3.45s cubic-bezier(0.45, 0, 1, 1);
		-moz-animation: cssload-width 3.45s cubic-bezier(0.45, 0, 1, 1);
}

@keyframes cssload-width {
	0% {
		transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
	}
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@-o-keyframes cssload-width {
	0% {
		-o-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
	}
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@-ms-keyframes cssload-width {
	0% {
		-ms-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
	}
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@-webkit-keyframes cssload-width {
	0% {
		-webkit-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
	}
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@-moz-keyframes cssload-width {
	0% {
		-moz-transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
	}
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
</style>
