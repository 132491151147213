<template>
  <AdminLayoutWithoutNav>
    <div class="admin-without-nav">
      <!-- <UserInfo /> -->
      <!-- <RequestChangeData /> -->
      <component
      :is="currentComponent"
    />
      </div>
  </AdminLayoutWithoutNav>
</template>

<script>
import AdminLayoutWithoutNav from '../../layouts/AdminLayoutWithoutNav.vue';
import UserInfo from '../../organisms/admin/UserInfo.vue';
import RequestChangeData from '../../organisms/admin/RequestChangeData.vue';
import { ADMIN_ROUTES } from '../../router/constants';

export default {
  name: 'AdminWithoutNav',
  data() {
    return {
      currentComponent: null,
    };
  },
  components: {
    AdminLayoutWithoutNav,
    UserInfo,
    RequestChangeData
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.checkActualAdminComponent();
      },
    },
  },
  methods: {
    checkActualAdminComponent() {
      const { path } = this.$route;
      if (path === ADMIN_ROUTES.REQUESTCHANGEDATA) {
        this.currentComponent = RequestChangeData;
      }
      if (path === ADMIN_ROUTES.USERINFO) {
        this.currentComponent = UserInfo;
      }
    },
  },
}
</script>

<style>

</style>