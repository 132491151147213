<template>
  <div class="tooltip">
    <CardContainer class="tooltip-container" >
      <slot/>
    </CardContainer>
  </div>
</template>

<script>
import CardContainer from '../atoms/CardContainer.vue';

export default {
  name: 'Tooltip',
  props: {
    onClose: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  components: {
    CardContainer,
  },
};
</script>

<style lang="scss" scoped>
  .tooltip {
    position: absolute;
    top: -200%;
    left: 100%;
    z-index: 1500;

  }
</style>
