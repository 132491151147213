<template>
  <div class="header-auth">
    <router-link to="/">
      <Logo />
    </router-link>
    <template v-if="!contactsIsHidden">
      <ContactInfo />
    </template>
  </div>
</template>

<script>
import Logo from '../../assets/images/logo/logo.svg';
import Phone from '../../assets/images/phone-header/phone.svg';
import ContactInfo from '../../molecules/ContactInfo';

export default {
  name: 'HeaderAuth',
  props: {
    contactsIsHidden: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Logo,
    Phone,
    ContactInfo,
  },
};
</script>

<style lang="scss" scoped>
.header-auth {
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

}
</style>
