<template>
  <div class="footer-auth">
    <div class="footer-auth__description">
      <Paymants />
      <p>
        Оплачивайте счета онлайн или получайте счет на оплату
      </p>
    </div>
    <div class="footer-auth__description-delimiter"></div>
    <div class="footer-auth__description">
      <Eye />
      <p>
        Отслежиивайте платежи <br /> и состояние вашего счета
      </p>
    </div>
    <div class="footer-auth__description-delimiter"></div>
    <div class="footer-auth__description">
      <Tick />
      <p>
        Получайте ответы на ваши вопросы онлайн
      </p>
    </div>
  </div>
</template>
<script>
import Paymants from '../../assets/images/footer-auth/payment.svg';
import Eye from '../../assets/images/footer-auth/eye.svg';
import Tick from '../../assets/images/footer-auth/tick.svg';

export default {
  name: 'FooterAuth',
  components: {
    Paymants,
    Eye,
    Tick,
  },
};
</script>
<style lang="scss" scoped>
  .footer-auth {

    padding-top: 60px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 50px;

    &__description {
      display: flex;
      align-items: center;

      p {
        width: 230px;
        text-align: left;
        padding-left: 15px;
      }
    }

    &__description-delimiter {
      width: 1px;
      height: 35px;
      background: rgba(42, 49, 51, 0.1);
    }
  }
</style>
