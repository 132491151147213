<template>
  <div class="contact-info">
    <PhoneNew />
    <div class="contact-info__container">
      <a href="tel:+380 66 123 34 45" class="contact-info__link">
        +380 66 123 34 45
      </a>
      <p>
        время работы: с 8:00 до 20:00
      </p>
    </div>
  </div>
</template>

<script>
import PhoneNew from '../assets/images/phone-header/phone-new.svg';

export default {
  name: 'ContactInfo',
  components: {
    PhoneNew,
  },
};
</script>

<style lang="scss" scoped>
  .contact-info {
    display: flex;
    padding-left: 40px;

    &__container{
      text-align: left;
      padding-left: $leftIndent;
    }

    &__link {
      color: $mainColor;
      font-weight: bold;
      text-decoration: none;
    }

    p {
      color: $hintTextColor;
      font-size: $littleFontsSize;
      line-height: 22px;
    }
  }
</style>
