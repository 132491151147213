<template>
  <div class="confirm-to-delete">
    <h1>
      Вы уверены что хотите удалить аккаунт?
    </h1>
    <p>
      Всее ваши данные профиля и история транзакций будет удалена и вы не сможете ее восстановить
    </p>
    <div>
      <ButtonMain @click.native="handleDeleteAccept">
        Да, удалить
      </ButtonMain>
      <ButtonSecondary @click.native="handleClose">
        не удалять
      </ButtonSecondary>
    </div>
  </div>
</template>

<script>
import ButtonMain from '../../atoms/ButtonMain.vue';
import ButtonSecondary from '../../atoms/ButtonSecondary';

export default {
  name: 'ConfirmToDelete',
  components: {
    ButtonMain,
    ButtonSecondary,
  },
  props: {
    handleClose: {
      type: Function,
      default() {
        return {};
      },
    },
    handleDeleteAccept: {
      type: Function,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .confirm-to-delete {

    h1 {
      @include h1;
    }

    p {
      @include p;
      padding: 25px 0;
      font-weight: normal;
    }

    div {
      display: flex;
      height: 43px;
      justify-content: space-between;

      button {
        width: 45%;
      }
    }
  }
</style>
