<template>
  <div class="admin-header" v-click-outside="handleOutsideClick">
    <Logo />
    <div class="admin-header__user">
      <DefaultAvatar />
      <span class="admin-header__user-name" v-for="operator in info" :key="operator.id">
        {{operator.name}}
      </span>
      <div class="admin-header__container" @click="handleClick">
      <MenuControlButton :class="[menuIsOpen ? 'user-header-menu__buttton--open' : null]" />
    </div>
      <template v-if="menuIsOpen">
      <CardContainer class="admin-header__menu-container">
        <nav @click="openAccountEdit">
          <router-link v-for="link in adminMenuLinks" :to="link.to" :key="link.id">
            {{link.title}}
          </router-link>
        </nav>
        <p @click="handleLogOut" class="admin-header__exit">Выйти</p>
      </CardContainer>
    </template>
    </div>
  </div>
</template>

<script>
import Logo from '../../assets/images/logo/logo.svg';
import DefaultAvatar from '../../assets/images/icons/default_user_avatar.svg';
import CardContainer from '../../atoms/CardContainer';
import MenuControlButton from '../../assets/images/icons/menu_control_button.svg';
import { adminMenuLinks } from '../../router/constants';
import { logOut } from '../../helpers/auth';
import { getUserList } from '../../api/supportApi'

export default {
  name: 'AdminHeader',
  components: {
    Logo,
    DefaultAvatar,
    CardContainer,
    MenuControlButton
  },
  data() {
    return {
      menuIsOpen: false,
      adminMenuLinks,
      info: [],
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.menuIsOpen = false;
      },
    },
    actualAccount: {
      immediate: true,
      handler() {
          const params = {  };
          getUserList(params).then((res) => {
              this.info = res.data;
          }).catch((error) => {
            const { data } = error.response;
            this.error = data;
          });
      },
    },
  },
  methods: {
    handleClick() {
      this.menuIsOpen = !this.menuIsOpen;
    },
    handleLogOut() {
      logOut();
    },
    handleOutsideClick() {
      if (this.menuIsOpen) {
        this.menuIsOpen = !this.menuIsOpen;
      }
    },
    openAccountEdit() {
      this.menuIsOpen = false;
    },
  },
}
</script>

<style lang="scss" scoped>
  .admin-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    &__user {
      display: flex;
      align-items: center;
      position: relative;

      svg {
        position: relative;
        z-index: 15;
      }
    }

    &__user-name {
      padding-left: 8px;
      position: relative;
      z-index: 15
    }

    &__exit {
      padding-top: 18px;
      border-top: 1px solid rgba(42, 49, 51, 0.1);
      @include p;
      font-weight: bold;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
        color: $elementsColor;
      }
    }

    &__container {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      z-index: 15;
      padding-left: 30px;
    }

    &__menu-container {
      background: rgba(255, 255, 255, 1);
      padding: 55px 130px 30px 30px;
      position: absolute;
      width: auto;
      z-index: 10;
      top: 0;
      right: -15px;

      nav {
        display: flex;
        flex-direction: column;

        a {
          @include p;
          font-weight: bold;
          text-transform: uppercase;
          text-decoration: none;
          padding: 14px 0;

          &:hover {
            opacity: 1;
            color: $elementsColor;
          }
        }
      }
    }
  }
</style>