<template>
  <div class="tab-dashboard-user-bill">
    <div class="tab-dashboard-user-bill__title">
        <h1>
          Сосояние счета
        </h1>
        <span class="tab-dashboard-user-bill__date">
          На {{ localeDate }}
        </span>
      </div>
      <div class="tab-dashboard-user-bill__container">
        <DashboardUserBill :dataPs="dataPs"/>
        <DashboardUserBillShortHistory :history="history"/>
        <MonthlyPayments />
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DashboardUserBill from '../../molecules/DashboardUserBill';
import DashboardUserBillShortHistory from '../../molecules/DashboardUserBillShortHistory';
import MonthlyPayments from '../../molecules/MonthlyPayments';
import { getStateAccount, getUrlForPs } from '../../api/supportApi'; 

export default {
  name: 'TabDashboardUserBill',
  data() {
    return {
      history: [],
      error: {},
      dataPs: {},
      date: Number,
    }
  },
  components: {
    DashboardUserBill,
    DashboardUserBillShortHistory,
    MonthlyPayments,
  },
  computed: {
    ...mapState('authStore', ['actualAccount']),
    localeDate() {
      return (new Date()).toLocaleDateString() 
    },
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
        const { actualAccount } = this;
        if (actualAccount && Object.keys(actualAccount).length) {
          const params = { personal_number: this.actualAccount.personalNumber };
         getStateAccount(params).then((res) => {
              this.history = res.data;
          }).catch((error) => {
            const { data } = error.response;
            this.error = data;
          });
          getUrlForPs(params).then((res) => {
            this.dataPs = res.data;
          })
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-dashboard-user-bill {

  &__title {
    display: flex;
    align-items: baseline;
  }

  &__date {
    @include h2-regular;
    color: $hintTextColor;
    padding-left: 20px;
  }

  &__container {
    display: flex;
    margin-top: 30px;
  }
}
</style>
