<template>
  <div class="login">
    <CardContainer class="login__form-wrap">
      <div class="login__wrap">
        <h1 class="login__title">Вход в личный кабинет</h1>
        <p class="login__placeholder-text">Введите свои данные для входа в поля ниже</p>
        <LoginForm :on-submit="onSubmit" />
        <DecoreLine />
        <p class="login__question-text">Еще не зарегистрировались в личном кабинете «УБЕРУ»?</p>
        <router-link to="/registration/user">
          <ButtonSecondary class="login__button-registration">
            Зарегистрироваться
          </ButtonSecondary>
        </router-link>
      </div>
    </CardContainer>
  </div>
</template>
<script>
import LoginForm from '../../forms/LoginForm.vue';
import CardContainer from '../../atoms/CardContainer.vue';
import ButtonSecondary from '../../atoms/ButtonSecondary.vue';
import DecoreLine from '../../atoms/DecoreLine.vue';

export default {
  name: 'LogIn',
  components: {
    LoginForm,
    CardContainer,
    ButtonSecondary,
    DecoreLine,
  },
  methods: {
    onSubmit(data) {
      this.$store.dispatch('authStore/logInUser', data);
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  &__form-wrap {
    width: 445px;
    height: 456px;
  }
  &__wrap {
    padding: 40px;
    text-align: left;
  }
  &__title {
    @include h1;
  }
  &__question-text {
    font-weight: 600;
    font-size: $littleFontsSize;
    color: $mainColor;
    padding-bottom: 15px;
  }
  &__placeholder-text {
    color: $mainColor;
    text-align: left;
    padding: 16px 0;
  }
  &__button-registration {
    height: 43px;
    width: 253px;
  }
}
</style>
