<template>
  <div class="layout">
    <div class="container">
      <HeaderAuth :contacts-is-hidden="contactsIsHidden"/>
      <slot />
      <template v-if="!footerIsHidden">
        <FooterAuth />
      </template>
    </div>
  </div>
</template>

<script>
import HeaderAuth from '../organisms/AuthLayout/HeaderAuth.vue';
import FooterAuth from '../organisms/AuthLayout/FooterAuth.vue';

export default {
  name: 'AuthLayout',
  props: {
    footerIsHidden: {
      type: Boolean,
      default: false,
    },
    contactsIsHidden: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderAuth,
    FooterAuth,
  },
};
</script>

<style lang="scss" scoped>
  .layout {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
