<template>
  <div class="profile-tab">
    <div class="profile-tab__wrap">
      <div class="profile-tab__container">
        <h2>
          Личная информация
        </h2>
        <h3>
          ФИО:
        </h3>
        <p>
          {{ user.fullName }}
        </p>
        <h3>
          Адрес:
        </h3>
        <p class="profile-tab__street">
          {{ user.address }}
        </p>
      </div>
      <div class="profile-tab__container">
        <h2>
          Информация о лицевом счете
        </h2>
        <h3>
          Лицевой счёт
        </h3>
        <p>
          № {{ user.personalNumber }}
        </p>
        <h3>
          Комплексная стоимость тарифа:
        </h3>
        <p>
          {{ user.trafficCost }} грн
        </p>
        <h3>
          Количество проживающих:
        </h3>
        <p v-if="user.tenants == 1">
          {{ user.tenants }} человек
        </p>
        <p v-if="user.tenants > 1">
          {{ user.tenants }} человека
        </p>
        <h3>
          Количество льготчиков
        </h3>
        <p v-if="user.privileges == 1">
          {{ user.privileges }} человек
        </p>
        <p v-if="user.privileges > 1">
          {{ user.privileges }} человека
        </p>
      </div>
    </div>
    <DecoreLine />
  </div>
</template>

<script>
import DecoreLine from '../atoms/DecoreLine.vue';

export default {
  name: 'UserProfileTab',
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    DecoreLine,
  },
};
</script>

<style lang="scss" scoped>
  .profile-tab {

      &__wrap {
        display: flex;
      }

      &__street {
        padding-right: 50px;
      }

      &__container {

          max-width: 500px;
          text-align: left;

          h2 {
            @include h1;
            padding-bottom: 30px;
          }

          h3 {
            @include p;
            padding-bottom: 10px;
          }

          p {
            @include h2-regular;
            padding-bottom: 25px;
          }
      }
  }
</style>
