<template>
  <div class="user-registration-code-confirm">
    <p class="user-registration-code-confirm__hint">Для завершения регистрации вам необходимо ввести код,
       который мы отправили на Ваш номер телефона</p>
    <p class="user-registration-code-confirm__timer" v-if="!showTimerBtn">
      Повторно код можно отправить через
      <Timer :callback-fn="showResendCodeBtn"/> 
    </p>
    <ButtonUnderlined v-if="showTimerBtn" @click.native="sendACode">
      Отправить код еще раз
    </ButtonUnderlined>
    <form @submit.prevent="handleSubmit">
      <InputTextWithLabel
        :id="'smsKey'"
        :set-value="userData"
        :error="error['smsKey']"
        :placeholder="'Введите код подтверждения'"
      />
      <MessageError v-for="message in error['nonFieldError']" :key="message">
        {{message}}
      </MessageError>
      <div class="user-registration-code-confirm__button-container">
        <ButtonSecondary
          @click.native="handleClick"
          class="user-registration-form__button"
        >
          <ArrowBack />
          Назад
        </ButtonSecondary>
        <ButtonMain type="submit" class="user-registration-form__button">
          Далее
          <ArrowNext />
        </ButtonMain>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { userSendSmsKey } from '../api/authApi';
import InputTextWithLabel from '../molecules/InputTextWithLabel.vue';
import ButtonMain from '../atoms/ButtonMain.vue';
import ButtonSecondary from '../atoms/ButtonSecondary.vue';
import MessageError from '../atoms/MessageError.vue';
import Timer from '../molecules/Timer.vue';
import ButtonUnderlined from '../atoms/ButtonUnderlined.vue';
import ArrowNext from '../assets/images/arrow/next.svg';
import ArrowBack from '../assets/images/arrow/back.svg';

export default {
  name: 'UserRegistrationCodeConfirm',
  components: {
    InputTextWithLabel,
    ButtonMain,
    Timer,
    MessageError,
    ButtonSecondary,
    ButtonUnderlined,
    ArrowNext,
    ArrowBack,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userData: {},
      showTimerBtn: false,
    };
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSubmit() {
      const { userData } = this;
      const sms_key = userData.smsKey
      this.onSubmit({ sms_key: sms_key, type: 'user_verification' });
    },
    handleClick() {
      this.$router.push('/registration/create-password');
    },
    showResendCodeBtn() {
      this.showTimerBtn = true;
    },
    sendACode() {
      userSendSmsKey().then(() => {
        this.showTimerBtn = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-registration-code-confirm {
  
  button {
    margin-right: 16px;

    svg {
      position: relative;
      top: 2px;
    }
  }

  &__button-container {
    display: flex;
    margin-top: 16px;
    height: 43px;
    width: 85%;
  }

  &__hint {
    @include p;
    font-weight: normal;
    text-align: start;
    padding-bottom: 25px;
  }

  &__timer {
    @include p;
    color: $hintTextColor;

    /deep/ p {
      padding-left: 5px;
    }
  }
}
</style>
