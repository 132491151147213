<template>
  <div class="search">
      <div class="admin-search">
        <input type="text" v-model="inputStr" class="admin-search__search" placeholder="Введите лицевой счет или адрес для поиска пользователей">
        <div>
        <AdminSearch class="admin-search__svg"/>
        </div>
      </div>
      <AdminUserList :information="filteredMovies" /> 
  </div>
</template>

<script>
import AdminUserList from '../../molecules/AdminUserList.vue'
import { getUserList } from '../../api/supportApi'
import AdminSearch from '../../assets/images/search/button-search.svg';

export default {
  name: 'Search',
  data() {
    return {
      info: [],
      inputStr: ''
    }
  },
  components: {
    AdminUserList,
    AdminSearch
  },
  computed: {
    filteredMovies() {
      if (this.inputStr) {
        return this.info.userList.filter(item => {
            if(item.name == null || item.personalNumber == null || item.address == null) {
              item++
            } else return item.name.toLowerCase().indexOf(this.inputStr.toLowerCase()) !== -1 || item.personalNumber.toLowerCase().indexOf(this.inputStr.toLowerCase()) !== -1 || item.address.toLowerCase().indexOf(this.inputStr.toLowerCase()) !== -1;
        });
      }
      return this.info.userList
    }
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
          const params = {  };
          getUserList(params).then((res) => {
              this.info = res.data;
          }).catch((error) => {
            const { data } = error.response;
            this.error = data;
          });
      },
    },
  },
}
</script>

<style lang="scss">
  .search {
    padding: 40px;

    &__form-serch {
      padding-bottom: 20px;
    }
  }
  .admin-search {
    position: relative;

    &__svg {
      position: absolute;
      top: 15px;
      right: 10px;

      &:hover {
        cursor: pointer;

        /deep/ path {
          stroke: $elementsColor;
        }
      }
    }
    &__search {
      width: 100%;
      height: 45px;
      background: #EDF4F7;
      border: 1px solid rgba(42, 49, 51, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      padding-left: 10px;
      color: $hintTextColor;
      font-size: $baseFontsSize;
      margin-bottom: 30px;
    }
  }
  input:focus {
    outline: none!important;
  }
</style>