import axios from 'axios';
import { apiPublic, api } from './index';
// eslint-disable-next-line import/no-cycle
import { onResponseInterceptor, onResponseErrorInterceptor } from './interceptors';
// eslint-disable-next-line import/no-cycle
import { getAccessToken } from '../helpers/auth';

export const logInUser = (data) => apiPublic.request({
  method: 'POST',
  url: '/userLogin',
  data,
});

export const logInAdmin = (data) => apiPublic.request({
  method: 'POST',
  url: '/operatorLogin',
  data,
});

export const userLogOut = () => api.request({
  method: 'POST',
  url: '/userLogout',
});

export const sendSmsKey = (data) => apiPublic.request({
  method: 'POST',
  url: '/sendSmsKey',
  data,
});

export const userCheckSmsKey = (data) => api.request({
  method: 'POST',
  url: '/userCheckSmsKey',
  data,
});

export const userSendSmsKey = () => api.request({
  method: 'POST',
  url: '/userSendSmsKey',
});

export const userPasswordCreate = (data) => api.request({
  method: 'POST',
  url: '/userPasswordCreate',
  data,
});

export const getTowns = (data) => apiPublic.request({
  method: 'POST',
  url: '/getTowns',
  data,
});

export const getStreets = (data) => apiPublic.request({
  method: 'POST',
  url: '/getStreets',
  data,
});

export const userCreate = (data) => apiPublic.request({
  method: 'POST',
  url: '/userCreate',
  data,
});

export const userAcceptAgreement = (data) => api.request({
  method: 'POST',
  url: '/userAcceptAgreement',
  data,
});

export const userDelete = (data) => api.request({
  method: 'POST',
  url: '/userDelete',
  data,
});

export const userCheckPhone = (data) => api.request({
  method: 'POST',
  url: '/userCheckPhone',
  data,
});

export const userPhoneUpdate = (data) => api.request({
  method: 'POST',
  url: '/userPhoneUpdate',
  data,
});

export const getUserPersonalNumbers = () => api.request({
  method: 'POST',
  url: '/getUserPersonalNumbers',
});

export const addUserPersonalNumber = (data) => api.request({
  method: 'POST',
  url: '/addUserPersonalNumber',
  data,
});

export const getTownsByCharacterId = (data) => api.request({
  method: 'POST',
  url: '/getTownsByCharacterId',
  data,
});

export const getStreetsByCharacterId = (data) => api.request({
  method: 'POST',
  url: '/getStreetsByCharacterId',
  data,
});

export const userCreateQuery = (data) => {
  axios.interceptors.response.use(
    onResponseInterceptor,
    onResponseErrorInterceptor,
  );
  return axios.request({
    method: 'POST',
    url: 'https://api.uberu.test.ut.in.ua/api/userCreateQuery/',
    data,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};
