<template>
  <div class="personal-account" v-click-outside="handleOutsideClick">
    <div class="personal-account__container" @click="handleClick">
      Лицевой счет №
      {{ actualAccount && actualAccount.personalNumber }}
      <div class="personal-account__button-svg">
        <MenuControlButton
          class="personal-account__svg"
          :class="[menuIsOpen ? 'personal-account__buttton--open' : null]"
        />
        <template v-if="menuIsOpen">
          <transition name="fade">
          <CardContainer class="personal-account__menu-container">
            <div class="personal-account__block-account">
              <div class="personal-account__section-account">
                <p class="personal-account__account personal-account__account-main">
                  Лицевой счет № {{ actualAccount.personalNumber }}
                  <span>
                    *
                  </span>
                </p>
                <p class="personal-account__address">
                  {{ actualAccount.address }}
                </p>
              </div>
              <div
                class="personal-account__section-account"
                v-for="account in filteredPersonalAccounts"
                :key="account.id"
                @click="handleAccountChange(account)"
              >
                <p class="personal-account__account">
                  Лицевой счет № {{ account.personalNumber }}
                </p>
                <p class="personal-account__address">
                  {{ account.address }}
                </p>
              </div>
              <div @click="handleAddAccountModalIsOpen">
                <p class="personal-account__account-add">
                  <Plus />
                  Добавить лицевой счет
                </p>
              </div>
            </div>
          </CardContainer>
          </transition>
        </template>
      </div>
    </div>

    <template v-if="addAccountModal">
      <ModalContainer :on-close="() => (addAccountModal = false)">
        <AddNewPersonalAccount :on-success="() => (addAccountModal = false)" />
      </ModalContainer>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MenuControlButton from "../assets/images/icons/menu_control_button.svg";
import Plus from "../assets/images/plus/plus.svg";
import CardContainer from "../atoms/CardContainer";
import ModalContainer from "./ModalContainer.vue";
import AddNewPersonalAccount from "../organisms/Account/AddNewPersonalAccount.vue";

export default {
  name: "PersonalAccount",
  props: {
    userName: {
      type: String,
      default: "",
    },
  },
  components: {
    MenuControlButton,
    CardContainer,
    ModalContainer,
    AddNewPersonalAccount,
    Plus,
  },
  data() {
    return {
      menuIsOpen: false,
      addAccountModal: false,
    };
  },
  computed: {
    ...mapState("authStore", ["actualAccount", "personalAccounts"]),
    filteredPersonalAccounts() {
      return this.personalAccounts.filter(
        (el) => el.personalNumber !== this.actualAccount?.personalNumber
      );
    },
  },
  methods: {
    handleClick() {
      this.menuIsOpen = !this.menuIsOpen;
    },
    handleOutsideClick() {
      if (this.menuIsOpen) {
        this.menuIsOpen = !this.menuIsOpen;
      }
    },
    handleAddAccountModalIsOpen() {
      this.addAccountModal = true;
    },
    handleAccountChange(account) {
      this.$store.commit("authStore/changePersonalAccount", account);
      this.menuIsOpen = false;
      this.handleClick()
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
/deep/.fade-enter, .fade-leave-to  {
  opacity: 0;
}
.personal-account {
  display: flex;
  align-items: center;
  position: relative;

  &__container {
    @include p;
    color: $mainSecondColor;
    cursor: pointer;
    justify-content: space-between;
    padding: 6px 13px;
    z-index: 5;
    background: $mainColor;
    border: 1px solid $hintTextColor;
    box-sizing: border-box;
    border-radius: 6px;
    margin-right: 10px;

    h2 {
      padding: 0 15px;
    }
  }

  h2 {
    text-transform: uppercase;
  }

  &__buttton {
    &--open {
      transform: rotate(180deg);
    }
  }

  &__section-account {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 9px 49px 15px 13px;
  }

  &__account-main {
    
    span {
      position: relative;
      left: 5px;
      font-size: 23px;
      top: 0px;
      line-height: 0;
    }
  }

  &__account {
    width: 235px;
    @include p;
    color: $mainSecondColor;
    padding-bottom: 3px;

    &:hover {
      color: $elementsColor;
    }
  }

  &__address {
    font-size: $littleFontsSize;
    color: rgba(255, 255, 255, 0.4);
  }

  &__block-account {
    text-align: left;
  }

  &__menu-container {
    position: absolute;
    top: 16px;
    right: 10px;
    z-index: 10;
    backdrop-filter: blur(20px);
    background: rgba(42, 49, 51, 0.86);

  }

  &__account-add {
    padding: 15px 15px;
    color: $mainSecondColor;
    display: flex;
    align-items: center;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: $elementsColor;
      text-decoration: none;
    }

    svg {
      padding-right: 10px;
    }
  }

  &__button-svg {
    padding-left: 30px;
  }

  &__svg {
    fill: $mainSecondColor;
    position: relative;
    z-index: 11;
    transition: 1s;
  }
}
</style>
