<template>
  <div class="monthly-payments">
    <p class="monthly-payments__title">
      Ежемесячные начисления
    </p>
    <div class="monthly-payments__sum">
      {{ userInfo.trafficCost }} <span class="monthly-payments__currency">грн</span>
    </div>
    <p class="monthly-payments__text">
      Комплексная стоимость пересчитанная на количество проживающих
    </p>
    <p class="monthly-payments__count-of-residents-title">
      Количество проживающих:
    </p>
    <p class="monthly-payments__count-of-residents" v-if="userInfo.tenants == 1">
      {{ userInfo.tenants }} человек
    </p>
    <p class="monthly-payments__count-of-residents" v-if="userInfo.tenants > 1">
      {{ userInfo.tenants }} человека
    </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MonthlyPayments',
  computed: {
    ...mapState('authStore', ['userInfo']),
  },
};
</script>

<style lang="scss" scoped>
  .monthly-payments {

    width: 307px;
    border: 1px solid rgba(42, 49, 51, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    height: 100%;
    margin-left: 20px;
    padding: 30px 0 15px 30px;
    text-align: left;

    &__title {
      @include p;
      padding-bottom: 15px;
    }

    &__sum {
      font-family: 'IBMPlexSans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 47px;
    }

    &__text {
      @include p;
      font-weight: normal;
      line-height: 21px;
      padding: 25px 0;
    }

    &__count-of-residents-title {
      @include p;
      padding-bottom: 6px;
    }

    &__count-of-residents {
        padding-bottom: 15px;
    }

    &__currency {
      font-size: 20px;
      font-weight: normal;
    }
  }
</style>
