import Vue from 'vue';
import Vuex from 'vuex';
import snake from 'to-snake-case';

import {
  logInUser, logInAdmin, sendSmsKey, userCheckSmsKey, userCheckSmsKeyRegistration, userPasswordCreate, userCreate, userAcceptAgreement,
  userCheckPhone, userPhoneUpdate, userCreateQuery, userDelete, getUserPersonalNumbers,
  addUserPersonalNumber,
} from '../api/authApi';
import router from '../router';
import { setAuthData, setTokenData } from '../helpers/auth';
import { DASHBOARD_ROUTES } from '../router/constants';

Vue.use(Vuex);

const state = {
  userInfo: null,
  personalAccounts: [],
  error: {},
  actualAccount: null,
};

const mutations = {
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  setAdminInfo: (state, payload) => {
    state.adminInfo = payload;
  },
  setError: (state, payload) => {
    state.error = payload;
  },
  setPersonalAccounts: (state, payload) => {
    state.personalAccounts = [...payload];
    const actualPersonalNumber = state.userInfo?.personalNumber;
    state.actualAccount = payload.find((el) => el.personalNumber === actualPersonalNumber);
  },
  changePersonalAccount: (state, payload) => {
    const { personalAccounts } = state;
    state.actualAccount = personalAccounts.find((el) => payload.personalNumber === el.personalNumber);
  },
};

const getters = {
  checkUserProfileAccount: (state) => {
    const { actualAccount, userInfo } = state;
    return actualAccount?.personalNumber === userInfo?.personalNumber;
  },
};

const actions = {

  logInUser({ commit }, payload) {
    logInUser(payload).then((res) => {
      const { token, tokenTimeExpired, user } = res.data;
      commit('setError', {});
      commit('setUserInfo', user);
      setAuthData(token, tokenTimeExpired, user);
      router.push(DASHBOARD_ROUTES.PERSONAL_ACCOUNT);
    }).catch((error) => {
      const { data } = error.response;
      commit('setError', data);
    });
  },

  logInAdmin({ commit }, payload) {
    logInAdmin(payload).then((res) => {
      const { token, tokenTimeExpired, admin } = res.data;
      commit('setError', {});
      commit('setAdminInfo', admin);
      setAuthData(token, tokenTimeExpired, admin);
      router.push('admin/search');
    }).catch((error) => {
      const { data } = error.response;
      commit('setError', data);
    });
  },

  restorePassword({ commit }, payload) {
    sendSmsKey(payload).then((res) => {
      const { token, tokenTimeExpired } = res.data;
      commit('setError', {});
      setTokenData(token, tokenTimeExpired);
      router.push('/password-code-confirm');
    }).catch((error) => {
      const { data } = error.response;
      commit('setError', data);
    });
  },

  userCheckSmsKey({ commit }, payload) {
    userCheckSmsKey(payload).then(() => {
      commit('setError', {});
      router.push('/create-password');
    }).catch((error) => {
      const { data } = error.response;
      commit('setError', data);
    });
  },

  userCheckSmsKeyRegistration({ commit }, payload) {
    userCheckSmsKey(payload).then(() => {
      commit('setError', {});
      router.push('/registration/accept-agreement');
    }).catch((error) => {
      const { data } = error.response;
      commit('setError', data);
    });
  },

  userPasswordCreate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userPasswordCreate(payload).then(() => {
        commit('setError', {});
        resolve();
      }).catch((error) => {
        const { data } = error.response;
        commit('setError', data);
        reject();
      });
    });
  },

  userAcceptAgreement({ commit }, payload) {
    userAcceptAgreement(payload).then(() => {
      commit('setError', {});
      router.push(DASHBOARD_ROUTES.PERSONAL_ACCOUNT);
    }).catch((error) => {
      const { data } = error.response;
      commit('setError', data);
    });
  },

  userCreate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userCreate(payload).then((res) => {
        const { token, tokenTimeExpired, user } = res.data;
        commit('setError', {});
        commit('setUserInfo', user);
        setAuthData(token, tokenTimeExpired, user);
        resolve();
      }).catch((error) => {
        const { data } = error.response;
        commit('setError', data);
        reject();
      });
    });
  },

  userCreateQuery({ commit }, payload) {
    const fd = new FormData();
    // eslint-disable-next-line
    Object.entries(payload).map(([key, value]) => {
      if (Array.isArray(value) && !value.length) {
        return;
      }
      if (Array.isArray(value) && value.length) {
        value.forEach((el) => {
          fd.append(`${snake(key)}[]`, el, 'blob');
        });
        return;
      }
      fd.set(snake(key), value);
    });
    return new Promise((resolve, reject) => {
      userCreateQuery(fd).then(() => {
        commit('setError', {});
        resolve();
      }).catch((error) => {
        const { data } = error.response;
        commit('setError', data);
        reject();
      });
    });
  },

  userDelete({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userDelete(payload).then(() => {
        commit('setError', {});
        resolve();
      }).catch((error) => {
        const { data } = error.response;
        commit('setError', data);
        reject();
      });
    });
  },

  userCheckPhone({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userCheckPhone(payload).then(() => {
        commit('setError', {});
        resolve();
      }).catch((error) => {
        const { data } = error.response;
        commit('setError', data);
        reject();
      });
    });
  },

  userPhoneUpdate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      userPhoneUpdate(payload).then(() => {
        commit('setError', {});
        resolve();
      }).catch((error) => {
        const { data } = error.response;
        commit('setError', data);
        reject();
      });
    });
  },

  addUserPersonalNumber({ commit }, payload) {
    return new Promise((resolve, reject) => {
      addUserPersonalNumber(payload).then(() => {
        commit('setError', {});
        resolve();
      }).catch((error) => {
        const { data } = error.response;
        commit('setError', data);
        reject();
      });
    });
  },

  getUserPersonalNumbers({ commit }) {
    getUserPersonalNumbers().then((res) => {
      commit('setPersonalAccounts', res.data);
    }).catch((error) => {
      const { data } = error.response;
      commit('setError', data);
    });
  },
};

const authStore = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default authStore;
