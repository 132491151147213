<template>
  <div class="user-profile-edit-tab">
    <div class="user-profile-edit-tab__container">
      <div class="user-profile-edit-tab__section-top">
        <h2 class="user-profile-edit-tab__title">
          Редактирование данных
        </h2>
        <p class="user-profile-edit-tab__info-text">Заполните только те поля, в которых хотите поменять данные</p>
        <p class="user-profile-edit-tab__info-attention">
          <Prompt class="user-profile-edit-tab__prompt"/>
          Обратите внимание, что при изменении данных вы должны прикрепить фото или сканы соответствующих документов для подтврждения информции
        </p>
      </div>
      <div class="user-profile-edit-tab__section-video">
        <div class="user-profile-edit-tab__hint-video">
          <p>Если у вас возникли сложности с изменением данных – посмотрите обучающее видео или
            <ButtonUnderlined class="user-profile-edit-tab__button-support" @click.native="$router.push('/dashboard/support')">напишите в поддержку</ButtonUnderlined></p>
        </div>
        <div class="user-profile-edit-tab__video">
          <Video />
        </div>
      </div>
    </div>
    <div class="user-profile-edit-tab__container">
      <div class="user-profile-edit-tab__section-bottom-left">
        <h3>Текущие ФИО плательщика: <span class="user-profile-edit-tab__section-name"> {{ user.fullName }}</span> </h3>
        <InputFileWithTextMulti
          :text-input-id="'newFullName'"
          :input-set-value="userDataToEdit"
          :text-input-placeholder="'Введите актуальные ФИО'"
          :file-input-id="'fullNameImages'"
        />
      </div>
      <div class="user-profile-edit-tab__section-bottom-right">
        <h3>Текущие количество проживющих: 
          <span class="user-profile-edit-tab__tenants" v-if="user.tenants == 1">{{ user.tenants }} человек</span>
          <span class="user-profile-edit-tab__tenants" v-if="user.tenants > 1">{{ user.tenants }} человека</span>
        </h3>
        <InputFileWithTextMulti
          :text-input-id="'newNumberResidents'"
          :input-set-value="userDataToEdit"
          :text-input-placeholder="'Введите актуальное количество проживвающих'"
          :file-input-id="'privilegeImages'"
        />
      </div>
    </div>
    <div v-if="Object.keys(error).length" class="user-profile-edit-tab__error">
      <MessageError v-for="message in error" :key="message.id">
        {{message.length && message.join(' ')}}
      </MessageError>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ButtonUnderlined from '../atoms/ButtonUnderlined.vue';
import InputFileWithTextMulti from '../molecules/InputFileWithTextMulti.vue';
import MessageError from '../atoms/MessageError.vue';
import Video from './Video/video.vue';
import Prompt from '../assets/images/prompt/prompt.svg';

export default {
  name: 'UserProfileEditTab',
  components: {
    ButtonUnderlined,
    InputFileWithTextMulti,
    MessageError,
    Prompt,
    Video,
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    userDataToEdit: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState('authStore', ['error']), 
  },
};
</script>

<style lang="scss">
  .user-profile-edit-tab {

    &__container {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &__section-bottom-left, &__section-bottom-right {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 18px;
      text-align: start;
      border: 1px solid rgba(42, 49, 51, 0.1);
      box-sizing: border-box;
      border-radius: 8px;
    }

    &__section-bottom-left {
      margin: 15px 15px 15px 0;
    }

    &__section-bottom-right {
      margin: 15px 0 15px 15px;
    }

    &__section-top {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 15px 15px 15px 0;
      text-align: start;
    }

    &__section-video {
      display: flex;
      flex-direction: row;
      width: 50%;
      height: 121px;
      padding: 15px 0 15px 15px;
      text-align: start;
    }

    &__button-support {
      padding: 0;

    }

    &__video {
      width: 100%;

      /deep/ .video__play-title {
        display: none;
      }

      /deep/ .video__background {
        width: 178px;
        height: 121px;
        margin-top: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 10px;
      }

      /deep/ .video__play {
        top: 25%;
      }

      /deep/ .video__video-container {
        margin-top: 10px;
        filter: blur(0);
        border-radius: inherit;
      }
    }

    &__error {
      height: 20px;
    }

    &__title {
      @include h1;
      padding-bottom: 10px;
    }

    &__info-text {
      @include p;
      font-weight: 400;
      padding-bottom: 10px;
    }

    &__info-attention {
      @include p;
      font-size: 12px;
      background: #EDF4F7;
      border-radius: 8px;
      padding: 7px;
      align-items: end;
      text-align: left
    }

    &__prompt {
      width: 40px;
    }

    &__hint-video {
      @include p;
      font-weight: normal;
      background: rgba(255, 98, 0, 0.1);
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      padding: 21px 21px 21px 26px;
    }

    h3 {
      @include p;
      display: inline-block;
    }

    &__section-name, &__tenants {
      padding-left: 5px;
      font-weight: normal;
    }


    /deep/ .video-container {
      
      &__title-block {
        display: none;
      }
    }

    /deep/ iframe {
      height: 120px!important;
      border-bottom-left-radius: 0!important;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  /deep/ .video-container__title-block {
    display: none;
  } 
</style>
