<template>
  <div class="form-input">
    <label :for="id">{{ label }}</label>
    <InputSelect
      :id="id"
      :set-value="setValue"
      :type="type"
      :placeholder="placeholder"
      :options="options"
      :disabled="disabled"
      :on-select-search="onSelectSearch"
    />
    <MessageError>{{error.join(' ')}}</MessageError>
  </div>
</template>
<script>
import InputSelect from '../atoms/InputSelect.vue';
import MessageError from '../atoms/MessageError.vue';

export default {
  name: 'InputSelectWithLabel',
  components: {
    InputSelect,
    MessageError,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    onSelectSearch: {
      type: Object,
      default() {
        return {};
      },
    },
    error: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
<style lang="scss" scoped>
  .form-input {
    width: 100%;
    padding: 8px 0;
  }
  /deep/ .vs__dropdown-toggle {
    border: none;
    width: 130px;
  }
  /deep/ .vs--disabled .vs__dropdown-toggle, /deep/.vs--disabled .vs__search {
    background: transparent;
  }
  /deep/ .vs__open-indicator, /deep/ .vs__clear {
    display: none;
  }
  /deep/ .vs__selected {
    margin: 0;
    width: 100%;
  }
  /deep/ #vs1__listbox {
    background: rgba(237, 244, 247, 0.86);
    width: 305px;
  }
  /deep/ #vs2__listbox {
    background: rgba(237, 244, 247, 0.86);
    width: 235px;
  }
  /deep/ .v-select {
    border: none;
    border-radius: 0;
  }
  /deep/ #townId {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .input-text {
    border: none!important;
    border-radius: 0!important;
  }
  /deep/ #vs1__listbox li, /deep/ #vs2__listbox li {
    color: $mainColor;
    &:hover {
      background: none;
      color: $elementsColor;
    }
    &:focus {
      background: transparent;
    }
  }
</style>
