import Vue from 'vue';
import Embed from 'v-video-embed';
import vSelect from 'vue-select';
import vClickOutside from 'v-click-outside';
import * as Tabs from 'vue-slim-tabs';
import VueMask from 'v-mask';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vue-select/dist/vue-select.css';
import './styles/main.scss';
import './api/interceptors';

Vue.use(vClickOutside);
Vue.component('v-select', vSelect);
Vue.use(Embed);
Vue.use(Tabs);
Vue.use(VueMask);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
