<template>
  <div class="admin-layout">
      <div class="container">
        <AdminHeader />
        <CardContainer>
          <template v-if="!navIsHidden">
            <AdminNavigationPanel />
          </template>
            <slot />
        </CardContainer>
      </div>
  </div>
</template>

<script>
import CardContainer from '../atoms/CardContainer.vue'
import AdminNavigationPanel from '../organisms/AdminNavigationPanel.vue'
import AdminHeader from '../organisms/AdminAuth/AdminHeader.vue';

export default {
  name: 'AdminLayout',
  components: {
    AdminNavigationPanel,
    CardContainer,
    AdminHeader
  },
  props: {
    navIsHidden: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
    .admin-layout {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        min-height: calc(100vh - 30px);
        width: 100%;
        padding-top: 30px;

        .container {
          width: 1110px;
        }
    }
</style>
