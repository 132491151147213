<template>
  <div class="password-code-confirm">
    <CardContainer>
      <div class="password-code-confirm__wrap">
        <h1 class="password-code-confirm__title">Введите код</h1>
        <p class="password-code-confirmd__placeholder-text">
          Код для восстановлния доступа отправлен, введите его в поле ниже
        </p>
        <PasswordCodeConfirmForm :on-submit="onSubmit" />
        <DecoreLine />
        <LinkUnderlined @click.native="handleClick">
          Вернуться ко входу
        </LinkUnderlined>
      </div>
    </CardContainer>
  </div>
</template>

<script>
import PasswordCodeConfirmForm from '../../forms/PasswordCodeConfirmForm.vue';
import CardContainer from '../../atoms/CardContainer.vue';
import DecoreLine from '../../atoms/DecoreLine.vue';
import LinkUnderlined from '../../atoms/LinkUnderlined.vue';
import { logOutWithHistory } from '../../helpers/auth';

export default {
  name: 'PasswordCodeConfirm',
  components: {
    PasswordCodeConfirmForm,
    CardContainer,
    DecoreLine,
    LinkUnderlined,
  },
  beforeDestroy() {
    this.$store.commit('authStore/setError', {});
  },
  methods: {
    onSubmit(data) {
      this.$store.dispatch('authStore/userCheckSmsKey', data);
    },
    handleClick() {
      logOutWithHistory();
    },
  },
};
</script>

<style lang="scss" scoped>
.password-code-confirm {
  max-width: 445px;

  &__wrap {
    padding: 40px;
    text-align: left;
  }

  &__title {
    @include h1;
  }

  &__placeholder-text {
    color: $mainColor;
    text-align: left;
    padding: 16px 0;
  }

}
</style>
