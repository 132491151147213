import axios from 'axios';

export const axiosConfig = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  baseURL: 'https://api.uberu.test.ut.in.ua/api',
};

export const api = axios.create(axiosConfig);

export const apiPublic = axios.create(
  axiosConfig,
);
