<template>
  <div class="request-change-data">
    <h1 class="request-change-data__title">
      Запрос на изменение данных
    </h1>
    <ChangeDataAdmin :info="info" />
    <AdminButtonsGroup
      class="request-change-data__buttons-group"
      :handle-click="handleSubmit"
      :handle-cancel="handleCancel" 
      :cancel-is-showed="true"
    />
  </div>
</template>

<script>
import { getQueryById, approveQueryStatus } from '../../api/supportApi';
import ChangeDataAdmin from '../../molecules/ChangeDataAdmin';
import AdminButtonsGroup from '../../molecules/AdminButtonsGroup.vue';

export default {
  name: 'RequestChangeData',
  data() {
    return {
      info: {},
    }
  },
  components: {
    ChangeDataAdmin,
    AdminButtonsGroup
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
          const query_id = this.$route.query.id;
          getQueryById({...this.userData, query_id}).then((res) => {
              this.info = res.data;
          }).catch((error) => {
            const { data } = error.response;
            this.error = data;
          });
      },
    },
  },
  methods: {
    handleSubmit() {
      const { id } = this.$route.query;
      const params = { };
      approveQueryStatus({ query_id: id }, params).then((res) => {
        this.userInfo = res.data;
      });
      this.$router.push('/admin/requests');
    },
    handleCancel() {
      this.$router.push('/admin/requests');
    },
  },
}
</script>

<style lang="scss" scoped>
  .request-change-data {
    
    &__title {
      @include h1;
      padding: 40px;
    }

    &__buttons-group {
      display: flex;
      height: 43px;
      margin-bottom: 50px;
      padding: 0 40px;
      
      /deep/button {
        margin-right: 25px;
      }
    }
  }
</style>