<template>
  <div class="video-container">
      <div class="video-container__title-block">
        <Video class="video-container__svg"/>
        Посмотрите короткое видео, чтобы понять возможности личного кабинета «Уберу»
      </div>
      <div class="video-container__background">
        <slot />
      </div>
  </div>
</template>

<script>
import Video from '../assets/images/video/video.svg';

export default {
  name: 'VideoContainer',
  components: {
    Video,
  },
};
</script>

<style lang="scss" scoped>
  .video-container {

    position: relative;
    &__title-block {
      background: $mainColor;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 64px;
      color: $mainSecondColor;
      display: flex;
      align-items: center;
      text-align: left;
      position: absolute;
      z-index: 1000;
      width: 635px;
      top: 1px;
    }

    &__svg {
      margin: 0 15px 0 25px;
      width: 50px;
    }

    &__background {

      /deep/div iframe {
        height: 390px;
      }
    }

  }
</style>
