<template>
  <div class="phone-confirm-to-delete">
    <h1>Удаление аккаунта</h1>
    <p>Мы отправили на ваш номер телефона код подтверждения, введите его, чтобы подтвердить удаление аккаунта</p>
    <form @submit.prevent="handleSubmit">
      <InputTextWithLabel
        :id="'smsKey'"
        :set-value="userData"
        :error="error['smsKey']"
        :placeholder="'Введите код подтверждения'"
      />
      <MessageError v-for="message in error['nonFieldError']" :key="message">
        {{message}}
      </MessageError>
      <p v-if="!showTimerBtn">
        Повторно код можно отправить через
        <Timer :callback-fn="showResendCodeBtn"/>
      </p>
      <ButtonUnderlined v-if="showTimerBtn" @click.native="sendACode">
        Отправить код еще раз
      </ButtonUnderlined>
      <div class="phone-confirm-to-delete__button-container">
        <ButtonMain type="submit" class="phone-confirm-to-delete__button">
          Подтвердить
        </ButtonMain>
      </div>
    </form>
    <p>----------------------------------------</p>
    <p>Если у вас нет доступа к текущему номеру телефона, <ButtonUnderlined @click.native="pushToSuppurt">обратитесь в поддержку</ButtonUnderlined></p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { userSendSmsKey } from '../../api/authApi';
import InputTextWithLabel from '../../molecules/InputTextWithLabel.vue';
import ButtonMain from '../../atoms/ButtonMain.vue';
import MessageError from '../../atoms/MessageError.vue';
import Timer from '../../molecules/Timer.vue';
import ButtonUnderlined from '../../atoms/ButtonUnderlined.vue';

export default {
  name: 'PhoneConfirmToDelete',
  components: {
    InputTextWithLabel,
    ButtonMain,
    Timer,
    MessageError,
    ButtonUnderlined,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
    closeModal: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userData: {},
      showTimerBtn: false,
    };
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  mounted() {
    userSendSmsKey();
  },
  beforeDestroy() {
    this.$store.commit('authStore/setError', {});
  },
  methods: {
    handleSubmit() {
      const { userData } = this;
      this.onSubmit({ sms_key: userData.smsKey });
    },
    showResendCodeBtn() {
      this.showTimerBtn = true;
    },
    sendACode() {
      userSendSmsKey().then(() => {
        this.showTimerBtn = false;
      });
    },
    pushToSuppurt() {
      this.$router.push('/dashboard/support');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-confirm-to-delete {
  &__button {
    width: 165px;
    height: 43px;
    margin-right: 15px;
  }

  &__button-container {
    display: flex;
  }
}
</style>
