<template>
  <div class="admin-request-list">
    <div v-for="info in information" :key="info.id" @click="handleClick(info)">
      <div class="admin-request-list__block">
        <h1>
          <DefaultAvatar />
          <span class="admin-request-list__name">
            {{ info.name }}
          </span>
        </h1>
        <div class="admin-request-list__personal-namber">
          {{ info.personalNumber }}
        </div>
        <div class="admin-request-list__address">
          {{ info.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ADMIN_ROUTES } from '../router/constants';
import DefaultAvatar from '../assets/images/icons/default_user_avatar.svg';

export default {
  name: 'AdminRequestsList',
  components: {
    DefaultAvatar,
  },
  props: {
    information: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    handleClick(info) {
      this.$router.push({ path: '/admin-new/request', query: { id: info.queryId } }); 
    },
  },
}
</script>

<style lang="scss">
  .admin-request-list {

    display: flex;
    align-items: self-start;
    flex-direction: column;
    max-height: 600px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 24px;
      background: rgba(42, 49, 51, 0.1);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mainColor;
    }

    &__name {
      @include p;
      display: inline-block;
      padding: 0 35px 0 17px;
      position: relative;
      bottom: 8px;
    }

    &__block {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(42, 49, 51, 0.1);
      width: 100%;

      &:hover {
        cursor: pointer;
        color: $elementsColor;

        h1 span {
          color: $elementsColor;
        }
      }

      h1 {
        width: 210px;
        text-align: start;
        padding: 30px 0;
      }
    }

    &__personal-namber {
      width: 110px;
      text-align: start;
    }

    &__address {
      width: 540px;
      text-align: start;
    }

    &__edit-svg {
      padding-left: 20px;
    }
  }
</style>
