<template>
  <div class="admin-login">
    <CardContainer class="admin-login__form-wrap">
      <div class="admin-login__wrap">
        <h1 class="admin-login__title">Вход для опреаторов</h1>
        <p class="admin-login__placeholder-text">Введите свои данные для входа в поля ниже</p>
        <AdminLoginForm :on-submit="onSubmit" />
      </div>
    </CardContainer>
  </div>
</template>
<script>
import AdminLoginForm from '../../forms/AdminLoginForm.vue';
import CardContainer from '../../atoms/CardContainer.vue';

export default {
  name: 'AdminLogIn',
  components: {
    AdminLoginForm,
    CardContainer,
  },
  methods: {
    onSubmit(data) {
      this.$store.dispatch('authStore/logInAdmin', data);
    },
  },
};
</script>
<style lang="scss" scoped>
.admin-login {
  &__form-wrap {
    width: 445px;
  }
  &__wrap {
    padding: 40px;
    text-align: left;
  }
  &__title {
    @include h1;
  }
  &__question-text {
    font-weight: 600;
    font-size: $littleFontsSize;
    color: $mainColor;
    padding-bottom: 15px;
  }
  &__placeholder-text {
    color: $mainColor;
    text-align: left;
    padding: 16px 0;
  }
  &__button-registration {
    height: 43px;
    width: 253px;
  }
}
</style>
