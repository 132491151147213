<template>
  <AuthLayout
    :footer-is-hidden="true"
    :contacts-is-hidden="true"
  >
    <div class="password-code-confirm-page">
      <PasswordCodeConfirm />
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout.vue';
import PasswordCodeConfirm from '../../organisms/Auth/PasswordCodeConfirm.vue';

export default {
  name: 'PasswordCodeConfirmPage',
  components: {
    AuthLayout,
    PasswordCodeConfirm,
  },
};
</script>

<style lang="scss" scoped>
  .password-code-confirm-page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
