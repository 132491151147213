<template>
  <div class="change-data-admin">
    <div class="change-data-admin__container" v-for="value in info" :key="value.id">
    <div class="change-data-admin__block">
      <div>
        <span class="change-data-admin__block-title">
          Текущие ФИО плательщика:
        </span>
        <span>{{ value.fullName }}</span>
      </div>
      <div>
        <span class="change-data-admin__block-title">
          Новое ФИО плательщика:
        </span>
        <span>{{ value.newFullName }}</span>
      </div>
      <div class="change-data-admin__img">

      </div>
    </div>
    <div class="change-data-admin__block">
      <div>
        Количство проживающих:
        <span>{{ value.numberResidents }}</span>
      </div>
      <div>
        Новое количство проживающих:
        <span>{{ value.newNumberResidents }}</span>
      </div>
      <div class="change-data-admin__img">

      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangeDataAdmin",
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.change-data-admin {

    &__container {
      display: flex;
      padding: 0 40px 40px 40px;
      justify-content: space-between;
      text-align: left;
    }
    
    &__block {
      width: 500px;
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(42, 49, 51, 0.1);
      border-radius: 10px;
      padding: 20px;
      margin-right: 20px;
      div {
          @include p;
          padding-bottom: 15px;
          justify-content: space-between;

          span {
              font-weight: normal;
              padding-left: 3px;
          }
      }
    }

    &__block-title {
      width: 60%;
      font-weight: bold!important;
    }

    &__img {
        border-top: 1px solid rgba(42, 49, 51, 0.1);
    }
}
</style>