<template>
  <div class="user-registration-password-form">
    <p class="user-registration-password-form__hint">Придумайте надежный пароль для входа в ваш личный кабинет</p>
    <form @submit.prevent="handleSubmit">
      <InputTextWithLabel
        :id="'password'"
        :set-value="userData"
        :error="error['password']"
        :placeholder="'Введите пароль'"
      />
      <InputTextWithLabel
        :id="'passwordConfirmation'"
        :set-value="userData"
        :error="error['passwordConfirmation']"
        :placeholder="'Введите пароль еще раз'"
      />
      <MessageError v-for="message in error['nonFieldError']" :key="message">
        {{message}}
      </MessageError>
      <div class="user-registration-password-form__button-container">
        <ButtonSecondary
          @click.native="handleClick"
          class="user-registration-form__button"
        >
          <ArrowBack />
          Назад
        </ButtonSecondary>
        <ButtonMain type="submit" class="user-registration-form__button">
          Далее
          <ArrowNext />
        </ButtonMain>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InputTextWithLabel from '../molecules/InputTextWithLabel.vue';
import MessageError from '../atoms/MessageError.vue';
import ButtonMain from '../atoms/ButtonMain.vue';
import ButtonSecondary from '../atoms/ButtonSecondary.vue';
import ArrowNext from '../assets/images/arrow/next.svg';
import ArrowBack from '../assets/images/arrow/back.svg';

export default {
  name: 'UserRegistrationPasswordForm',
  components: {
    InputTextWithLabel,
    MessageError,
    ButtonMain,
    ButtonSecondary,
    ArrowNext,
    ArrowBack,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userData: {},
    };
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSubmit() {
      this.onSubmit(this.userData);
    },
    handleClick() {
      this.$router.push('/registration/user');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-registration-password-form {
  &__button {
    width: 226px;
    height: 43px;
    margin-right: 15px;
  }

  &__hint {
    @include p;
    font-weight: normal;
    text-align: start;
    padding-bottom: 20px;
  }

  &__button-container {
    display: flex;
    height: 43px;
    width: 85%;
    margin-top: 16px;
  }

  &__button-container button {
    margin-right: 16px;

    svg {
      position: relative;
      top: 2px;
    }
  }
}
</style>
