<template>
  <div class="admin-layout-layout">
    <div class="container">
      <AdminHeaderAuth />
      <slot />
    </div>
  </div>
</template>

<script>
import AdminHeaderAuth from '../organisms/AdminAuthLayout/AdminHeaderAuth.vue';

export default {
  name: 'AdminAuthLayout',
  components: {
    AdminHeaderAuth,
  },
};
</script>

<style lang="scss" scoped>
  .layout {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
