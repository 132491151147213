<template>
  <div class="dialogue">
    <h2 class="dialogue__back" @click="$router.go(-1)">
      <span>
        <ArrowBack class="arrow-back-black"/>
        Диалог с оператором
      </span>
       <span class="dialogue__tema">
         <span>
           Тема: 
         </span>
         {{ dialogue.dialogueLabel }}
       </span>
    </h2>
    <DialogueWrapper :dialogue="dialogue" :error="error"/>
    <AskAQuestionForm :on-submit="onSubmit" :error="error" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import convertToFormData from '../../helpers/convertToFormData';
import { getMessagesByDialogueId, createMessage } from '../../api/supportApi';
import ArrowBack from '../../assets/images/arrow/back.svg';
import DialogueWrapper from '../../molecules/DialogueWrapper.vue';
import AskAQuestionForm from '../../forms/AskAQuestionForm.vue';

export default {
  name: 'Dialogue',
  components: {
    DialogueWrapper,
    ArrowBack,
    AskAQuestionForm
  },
  data() {
    return {
      dialogue: [],
      dialogueId: null,
      error: {},
    }
  },
  beforeMount() {
    this.getMessagesByDialogueId();
  },
  computed: {
    ...mapState('authStore', ['actualAccount']),
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
        this.getMessagesByDialogueId();
      },
    },
  },
  methods: {
    onSubmit(data) {
      const { dialogueId } = this;
      createMessage(convertToFormData({...data,dialogueId}), { personal_number: this.actualAccount.personalNumber }).then(() => {
        this.error = {};
        this.userQuestion = {
          images: [],
        };
        this.getMessagesByDialogueId();
      }).catch((error) => {
        const { data } = error.response;
        this.error = data;
      });
    },
    getMessagesByDialogueId() {
      const { actualAccount } = this;
      if (actualAccount && Object.keys(actualAccount).length) {
        const { id } = this.$route.query;
        this.dialogueId = id;
        const params = { personal_number: this.actualAccount.personalNumber };
        getMessagesByDialogueId({ dialogueId: id }, params).then((res) => {
          this.dialogue = res.data;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .dialogue {
    
    &__back {
      @include h1;
      align-items: center;
      padding-bottom: 15px;
      justify-content: space-between;

      span:hover {
        cursor: pointer;
        color: $elementsColor;

        .arrow-back-black {

          /deep/ path {
            stroke: $elementsColor;
          }
        }
      }
    }

    &__tema {
      @include p;
      font-weight: normal;
      span {
        padding-right: 5px;
        font-weight: bold;
      }
    }
  }
  /deep/ .arrow-back-black {
    padding-right: 10px;
  }
  /deep/ .arrow-back-black path {
      stroke: $mainColor;
  }
  /deep/#dialogueTopicId {
    display: none;
  }
  /deep/.textarea {
    height: 70px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 26px 26px 20px 50px;
  }
  /deep/.file-add-text {
    display: none;
  }
  /deep/.ask-a-question-form .textarea-file {
    position: absolute;
    width: 92.8%;
    bottom: 21px;
  }
  /deep/.ask-a-question-form__svg {
    left: 10px;
  }
  /deep/.file-container__icon svg {
    position: absolute;
  }
  /deep/.file-container__file-name {
    position: absolute;
    left: 12%;
    bottom: 7px;
  }
</style>
