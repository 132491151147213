<template>
  <div class="add-new-personal-account">
    <NewPersonalAccount v-if="isAddNewPersonalAcc" :on-submit="onSubmit" />
    <div v-else>
      <h1>Ваши данные отправлены на проверку</h1>
      <p>После проверки вам придет уведомление о статусе изменения данных</p>
      <ButtonMain @click="handleClick">Ок, спасибо</ButtonMain>
    </div>
  </div>
</template>

<script>
import NewPersonalAccount from '../../forms/NewPersonalAccount.vue';
import ButtonMain from '../../atoms/ButtonMain.vue';

export default {
  name: 'AddNewPersonalAccount',
  components: {
    NewPersonalAccount,
    ButtonMain,
  },
  props: {
    onSuccess: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isAddNewPersonalAcc: true,
    };
  },
  methods: {
    onSubmit(data) {
      this.$store.dispatch('authStore/addUserPersonalNumber', data).then(() => {
        this.isAddNewPersonalAcc = false;
      });
    },
    handleClick() {
      this.onSuccess();
    },
  },
};
</script>

<style lang="scss" scoped>
  .add-new-personal-account {

  }
</style>
