<template>
  <div class="user-info"> 
    <div class="user-info__container">
      <div class="user-info__group">
        <h2 class="user-info__back" @click="$router.go(-1)">
        <ArrowBack class="arrow-back-black"/>
        </h2>
        <DefaultAvatar class="user-info__avatar"/>
        <span v-for="name in userInfo" :key="name.id">
          {{name.fullName}}
        </span>
      </div>
      <AdminPersonalAccount :personalInfo="userInfo"/>
    </div>
    <AdminUserProfile :user="userInfo.user" /> 
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getUserInfonById } from '../../api/supportApi';
import AdminUserProfile from '../../molecules/AdminUserProfile.vue';
import AdminPersonalAccount from '../../molecules/AdminPersonalAccount.vue';
import ArrowBack from '../../assets/images/arrow/back.svg';
import DefaultAvatar from '../../assets/images/icons/default_user_avatar.svg';

export default {
  name: 'UserInfo',
  components: {
    AdminUserProfile,
    ArrowBack,
    DefaultAvatar,
    AdminPersonalAccount
  },
  data() {
    return {
      userInfo: [],
      isEditForm: false,
    }
  },
  computed: {
    ...mapState('authStore', ['personalAccounts', 'actualAccount']),
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
        const { actualAccount } = this;
      },
    },
  },
  beforeMount() {
    this.getUserInfonById();
  },
  methods: {
    getUserInfonById() {
      const { id } = this.$route.query;
      const params = { };
    getUserInfonById({ id: id }, params).then((res) => {
      this.userInfo = res.data;
    });
    },
    getUserInfoByPersonalNumber() {
        const personal_number = '33К1-61831';
        const params = { };
        getUserInfoByPersonalNumber({ personal_number: personal_number }, params).then((res) => {
          this.userInfo = res.data;
        });
    },
  },
}
</script>

<style lang="scss" scoped>
  .arrow-back-black path {
    stroke: $mainColor;
  }
  .user-info {
    
    &__container {
      display: flex;
      justify-content: space-between;
      padding: 30px 0 30px 40px;
      border-bottom: 1px solid rgba(42, 49, 51, 0.1);
    }

    &__group {
      display: flex;
      align-items: center;
    }

    &__avatar {
      padding: 0 15px;
    }

    &__buttons-group {
      display: flex;
      height: 43px;
      width: 60%;
      margin-left: 40px;
      margin-bottom: 40px;

      /deep/ button {
        margin-right: 25px;
      }
    }

    &__back {
      
      &:hover {
      cursor: pointer;

      /deep/ svg path {
        stroke: $elementsColor;
      }
    }
    }
  }
  /deep/ .personal-account__container {
    margin-right: 40px;
  }
</style>