<template>
  <div class="search-street-form">
    <h2 class="search-street-form__title">Выберите улицу</h2>
    <p class="search-street-form__alphabet-title">По Алфавиту</p>
    <div class="search-street-form__search">
      <div class="search-street-form__alphabet">
        <div class="search-street-form__alphabet-list">
          <div
            v-for="alphabet in options.alphabet"
            :key="alphabet.id"
            @click="setAlphabet(alphabet)"
            :class="[
            'search-street-form__alphabet-item',
            alphabet.id === selectedAlphabet && 'search-street-form__alphabet-item--active',
            alphabet.isDisabled && 'search-street-form__alphabet-item--disabled'
            ]"
          >
            {{ alphabet.label }}
          </div>
        </div>
      </div>
      <div class="search-street-form__search-street">
        <div class="search-street-form__search-street-list">
          <div
            v-for="street in options.streets"
            :key="street.id"
            @click="setStreet(street)"
            :class="['search-street-form__search-street-item',
              street.id === selectedStreet.id && 'search-street-form__search-street-item--active']"
          >
            {{ street.label }}
          </div>
        </div>
        <div class="search-street-form__button-group">
          <ButtonSecondary @click.native="handleClickToPrevStep">
            <ArrowBack />
            Назад
          </ButtonSecondary>
          <ButtonMain @click.native="handleClickToNextStep">
            Далее
            <ArrowNext />
          </ButtonMain>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStreetsByCharacterId, getStreets } from '../api/authApi';
import ButtonMain from '../atoms/ButtonMain.vue';
import ButtonSecondary from '../atoms/ButtonSecondary.vue';
import ArrowNext from '../assets/images/arrow/next.svg';
import ArrowBack from '../assets/images/arrow/back.svg';

export default {
  name: 'SearchStreetForm',
  components: {
    ButtonMain,
    ButtonSecondary,
    ArrowNext,
    ArrowBack
  },
  props: {
    handleSetRegistrationData: {
      type: Function,
      default() {
        return {};
      },
    },
    town: {
      type: Object,
      default() {
        return {};
      },
    },
    handleBackToPrev: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {
        alphabet: [],
        streets: [],
      },
      selectedStreet: {},
      selectedAlphabet: {},
    };
  },
  created() {
    const { town } = this;
    getStreets({ townId: town.id }).then((res) => {
      const { data } = res;
      this.options.alphabet = data.alphabet;
    });
  },
  watch: {
    selectedAlphabet: {
      deep: true,
      handler() {
        const { selectedAlphabet, town } = this;
        getStreetsByCharacterId({ townId: town.id, characterId: selectedAlphabet }).then((res) => {
          this.options.streets = res.data;
        });
      },
    },
  },
  methods: {
    setStreet(street) {
      this.selectedStreet = street;
    },
    setAlphabet(alphabet) {
      if (alphabet.isDisabled) return;
      this.selectedAlphabet = alphabet.id;
    },
    handleClickToPrevStep() {
      this.handleBackToPrev();
    },
    handleClickToNextStep() {
      const { selectedStreet } = this;
      if (!Object.keys(selectedStreet).length) {
        return;
      }
      this.handleSetRegistrationData(this.selectedStreet);
    },
  },
};
</script>

<style lang="scss" scoped>
  .search-street-form {

    min-width: 460px;
    &__search {
      display: flex;
    }

    &__title {
      @include h1;
    }

    &__alphabet {
      width: 12%;
    }

    &__alphabet-title {
      @include p;
      padding: 25px 0;
    }

    &__search-street {
      padding-left: 40px;
    }

    &__search-street-list {
      max-height: 230px;
      overflow: auto;
      margin-bottom: 65px;

      &::-webkit-scrollbar {
      width: 4px;
      height: 24px;
      background: rgba(42, 49, 51, 0.1);
      border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
      background: $mainColor;
      border-radius: 100px;
      }
    }

    &__search-street-item {
      width: 350px;

      &:hover {
        cursor: pointer;
      }

      &--active {
        color: $elementsColor;
      }
    }


    &__button-group {
      display: flex;
      position: absolute;
      bottom: 40px;
      right: 40px;

      button {
        height: 43px;
        width: 137px;
        margin-right: 10px;
      }
    }

    &__alphabet-list {
      display: flex;
      flex-direction: column;
      max-height: 290px;
      overflow: auto;

      &::-webkit-scrollbar {
      width: 4px;
      height: 24px;
      background: rgba(42, 49, 51, 0.1);
      border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
      background: $mainColor;
      border-radius: 100px;
      }
    }

    &__alphabet-item {
      display: flex;
      justify-content: center;
      background: rgba(237, 244, 247, 0.6);
      border: 1px solid rgba(42, 49, 51, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      min-width: 40px;
      width: 40px;
      min-height: 40px;
      height: 40px;
      padding: 10px;
      margin-bottom: 10px;
      color: $mainColor;
      font-weight: bold;
      cursor: pointer;

      &--active {
        background: rgba(255, 98, 0, 0.1);
        border: 1px solid rgba(255, 98, 0, 0.1);
        color: $elementsColor;
      }

      &--disabled {
        background: $hintTextColor;
        border: 1px solid $hintTextColor;
        opacity: 0.3;

        &:hover {
          cursor: no-drop;
        }
      }
    }
  }
</style>
