<template>
  <p>0.{{timer}}</p>
</template>

<script>
export default {
  name: 'Timer',
  props: {
    callbackFn: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      timer: 60,
    };
  },
  mounted() {
    this.timerInterval = setInterval(() => this.timeAway(), 1000);
  },
  methods: {
    timeAway() {
      this.timer = Math.floor(this.timer - 0.01);
    },
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
  watch: {
    timer() {
      if (this.timer === 0) {
        this.callbackFn();
        clearInterval(this.timerInterval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
