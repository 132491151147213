<template>
  <div class="search-town-form">
    <h2 class="search-town-form__title">Выберите город</h2>
    <div class="search-town-form__popular">
      <p class="search-town-form__popular-title">Популярные</p>
      <div class="search-town-form__popular-list">
        <div
          v-for="town in options.popular"
          :key="town.id"
          @click="setTown(town), handleClick()"
          :class="['search-town-form__popular-item',
            town.id === selectedTown.id && 'search-town-form__popular-item--active']"
        >
          {{ town.label }}
        </div>
      </div>
    </div>
    <DecoreLine />
    <p class="search-town-form__alphabet-title">По Алфавиту</p>
    <div class="search-town-form__search">
      <div class="search-town-form__alphabet">
        <div class="search-town-form__alphabet-list">
          <div
            v-for="alphabet in options.alphabet"
            :key="alphabet.id"
            @click="setAlphabet(alphabet)"
            :class="[
            'search-town-form__alphabet-item',
            alphabet.id === selectedAlphabet && 'search-town-form__alphabet-item--active',
            alphabet.isDisabled && 'search-town-form__alphabet-item--disabled'
            ]"
          >
            {{ alphabet.label }}
          </div>
        </div>
      </div>
      <div class="search-town-form__search-town">
        <div class="search-town-form__search-town-list">
          <div
            v-for="town in options.towns"
            :key="town.id"
            @click="setTown(town)"
            :class="['search-town-form__search-town-item',
              town.id === selectedTown.id && 'search-town-form__search-town-item--active']"
          >
            {{ town.label }}
          </div>
        </div>
        <ButtonMain class="search-town-form__search-town-button" @click.native="handleClick">
          Далее
          <ArrowNext />
        </ButtonMain>
      </div>
    </div>
  </div>
</template>

<script>
import { getTowns, getTownsByCharacterId } from '../api/authApi';
import DecoreLine from '../atoms/DecoreLine.vue';
import ButtonMain from '../atoms/ButtonMain.vue';
import ArrowNext from '../assets/images/arrow/next.svg';

export default {
  name: 'SearchTownForm',
  components: {
    DecoreLine,
    ButtonMain,
    ArrowNext
  },
  props: {
    handleSetTown: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {
        popular: [],
        alphabet: [],
        towns: [],
      },
      selectedTown: {},
      selectedAlphabet: {},
    };
  },
  created() {
    getTowns().then((res) => {
      const { data } = res;
      this.options.popular = data.popular;
      this.options.alphabet = data.alphabet;
    });
  },
  watch: {
    selectedAlphabet: {
      deep: true,
      handler() {
        const { selectedAlphabet } = this;
        getTownsByCharacterId({ characterId: selectedAlphabet }).then((res) => {
          this.options.towns = res.data;
        });
      },
    },
  },
  methods: {
    setTown(town) {
      this.selectedTown = town;
    },
    setAlphabet(alphabet) {
      if (alphabet.isDisabled) return;
      this.selectedAlphabet = alphabet.id;
    },
    handleClick() {
      const { selectedTown } = this;
      if (!Object.keys(selectedTown).length) {
        return;
      }

      this.handleSetTown(this.selectedTown);
    },
  },
};
</script>

<style lang="scss" scoped>
  .search-town-form {

    &__popular-list {
      display: flex;

    }

    &__popular-item {
      display: flex;
      align-items: center;
      background: rgba(237, 244, 247, 0.6);
      border: 1px solid rgba(42, 49, 51, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      height: 38px;
      color: $mainColor;
      font-size: 14px;
      font-weight: bold;
      margin-right: 10px;
      padding: 10px;
      cursor: pointer;

      &--active {
        background: rgba(255, 98, 0, 0.1);
        border: 1px solid rgba(255, 98, 0, 0.1);
        color: $elementsColor;
      }
    }

    &__search-town-button {
      bottom: 40px;
      right: 40px;
      position: absolute;
      width: 137px;
      height: 43px;
      padding: 0 15px;
    }

    &__title {
      @include h1;
    }

    &__search-town-item {
      font-size: 14px;
      font-weight: 500;
      color: $mainColor;

      &:hover {
        cursor: pointer;
        color: $elementsColor;
      }

      &--active {
        color: $elementsColor;
      }
    }

    &__search {
      display: flex;
    }

    &__popular-title {
      @include p;
      padding: 25px 0;
    }

    &__alphabet {
      width: 25%;
    }

    &__alphabet-list {
      display: flex;
      flex-direction: column;
      max-height: 230px;
      overflow: auto;
      width: 55px;

      &::-webkit-scrollbar {
      width: 4px;
      height: 24px;
      background: rgba(42, 49, 51, 0.1);
      border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
      background: $mainColor;
      border-radius: 100px;
      }
    }

    &__alphabet-title {
      @include p;
      padding-bottom: 25px;
    }

    &__alphabet-item {
      display: flex;
      justify-content: center;
      background: rgba(237, 244, 247, 0.6);
      border: 1px solid rgba(42, 49, 51, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      min-width: 40px;
      width: 40px;
      min-height: 40px;
      padding: 10px;
      margin-bottom: 10px;
      color: $mainColor;
      font-weight: bold;
      cursor: pointer;

      &--active {
        background: rgba(255, 98, 0, 0.1);
        border: 1px solid rgba(255, 98, 0, 0.1);
        color: $elementsColor;
      }

      &--disabled {
        background: $hintTextColor;
        border: 1px solid $hintTextColor;
        opacity: 0.3;

        &:hover {
          cursor: no-drop;
        }
      }
    }
  }
</style>
