<template>
  <div class="new-personal-account">
    <h1 class="new-personal-account__title">Добавить новый лицевой счет</h1>
    <p>Обратите внимание, чтобы добавить вторичный лицевой счет, вам необходимо
      указать его номер и адрес,
    к которому он привязан. Во вторичном лицевом у вас не будет доступа к истории транзакций +
    и подробным данным профиля</p>
    <form @submit.prevent="handleSubmit">
      <InputTextWithLabel
        :id="'personalNumber'"
        :set-value="userInfo"
        :type="'text'"
        :error="error['personalNumber']"
        :placeholder="'Номер лицевого счета'"
      />
      <InputsInOneRowContainer>
        <InputSelectWithLabel
          :id="'townId'"
          :type="'townId'"
          :set-value="userInfo"
          :options="options['townId']"
          :on-select-search="selectSearch"
          :error="error['townId']"
          :placeholder="'Город'"
        />
        <InputSelectWithLabel
          :id="'streetName'"
          :type="'streetName'"
          :set-value="userInfo"
          :disabled="!userInfo.townId"
          :options="options['streetName']"
          :on-select-search="selectSearch"
          :error="error['streetName']"
          :placeholder="'Улица'"
        />
        <InputTextWithLabel
          :id="'building'"
          :type="'text'"
          :set-value="userInfo"
          :disabled="!userInfo.streetName"
          :error="error['building']"
          :placeholder="'Дом'"
        />
        <InputTextWithLabel
          :id="'flat'"
          :type="'text'"
          :set-value="userInfo"
          :disabled="!userInfo.building"
          :error="error['flat']"
          :placeholder="'Кв'"
        />
      </InputsInOneRowContainer>
      <MessageError v-for="message in error['nonFieldError']" :key="message">
        {{message}}
      </MessageError>
      <ButtonMain type="submit" class="new-personal-account__button">
        Добавить лицевой счет
      </ButtonMain>
    </form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { getTowns, getStreets } from '../api/authApi';
import convertOptionsToSelect from '../helpers/convertOptionsToSelect';
import InputTextWithLabel from '../molecules/InputTextWithLabel.vue';
import InputSelectWithLabel from '../molecules/InputSelectWithLabel.vue';
import ButtonMain from '../atoms/ButtonMain.vue';
import InputsInOneRowContainer from '../atoms/InputsInOneRowContainer.vue';
import MessageError from '../atoms/MessageError.vue';

export default {
  name: 'NewPersonalAccount',
  components: {
    InputTextWithLabel,
    ButtonMain,
    MessageError,
    InputSelectWithLabel,
    InputsInOneRowContainer,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userInfo: {},
      options: {
        townId: [],
        streetName: [],
      },
      selectSearch: {},
    };
  },
  watch: {
    'selectSearch.townId': {
      deep: true,
      handler() {
        const { selectSearch } = this;
        if (selectSearch.townId && selectSearch.townId.length >= 2) {
          getTowns({ townName: selectSearch.townId }).then((res) => {
            this.options.townId = convertOptionsToSelect(res.data);
          });
        }
      },
    },
    'selectSearch.streetName': {
      deep: true,
      handler() {
        const { selectSearch, userInfo } = this;
        if (selectSearch.streetName && selectSearch.streetName.length >= 2) {
          getStreets({
            townId: String(userInfo.townId.id),
            streetName: selectSearch.streetName,
          }).then((res) => {
            this.options.streetName = convertOptionsToSelect(res.data);
          });
        }
      },
    },
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSubmit() {
      const { userInfo } = this;
      this.onSubmit({
        ...userInfo,
        streetId: userInfo.streetName?.id,
        townId: userInfo.townId?.id,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.new-personal-account {
  text-align: left;
  &__button {
    width: 80%;
    height: 43px;
    margin-right: 15px;
    margin-top: 15px;
  }
  &__title {
    padding-bottom: 25px;
  }
}
/deep/.vs__search {
  display: inline-block!important;
}
/deep/#vs1__combobox {
  padding: 0 0 0 9px!important;
  height: auto!important;
}
/deep/ .vs--searchable .vs__dropdown-toggle {
  padding: 0 0 0 5px!important;
  overflow: hidden;
}
</style>
