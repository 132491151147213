<template>
  <div class="create-password">
    <!-- modal -->
    <ModalContainer v-if="modalIsOpen" :on-close="handleClose">
      <h1 class="create-password__title">Доступ восстановлен</h1>
      <p class="create-password__placeholder-text">
        Используйте новый пароль для входа в личный кабинет «УБЕРУ»
      </p>
      <ButtonMain @click.native="pushToLoginPage" class="create-password__button">
        Восстановить доступ
      </ButtonMain>
    </ModalContainer>
    <!--  -->
    <CardContainer>
      <div class="create-password__wrap">
        <h1 class="create-password__title">Восстановление доступа</h1>
        <p class="create-password__placeholder-text">
          Придумайте новый пароль для входа в личный кабинет «УБЕРУ»
        </p>
        <CreateNewPasswordForm :on-submit="onSubmit" />
        <DecoreLine />
        <ButtonUnderlined @click.native="handleClick" >
          Выполнить вход
        </ButtonUnderlined>
      </div>
    </CardContainer>
  </div>
</template>

<script>
import CreateNewPasswordForm from '../../forms/CreateNewPasswordForm.vue';
import CardContainer from '../../atoms/CardContainer.vue';
import DecoreLine from '../../atoms/DecoreLine.vue';
import ButtonUnderlined from '../../atoms/ButtonUnderlined.vue';
import ModalContainer from '../../molecules/ModalContainer.vue';
import ButtonMain from '../../atoms/ButtonMain.vue';

import { logOutWithHistory } from '../../helpers/auth';

export default {
  name: 'CreatePassword',
  components: {
    CreateNewPasswordForm,
    CardContainer,
    DecoreLine,
    ButtonUnderlined,
    ModalContainer,
    ButtonMain,
  },
  data() {
    return {
      modalIsOpen: false,
    };
  },
  methods: {
    onSubmit(data) {
      this.$store.dispatch('authStore/userPasswordCreate', data).then(() => {
        this.modalIsOpen = true;
      });
    },
    handleClick() {
      logOutWithHistory();
    },
    handleClose() {
      this.modalIsOpen = false;
    },
    pushToLoginPage() {
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss" scoped>
.create-password {
  max-width: 445px;

  &__wrap {
    padding: 40px;
    text-align: left;
  }

  &__title {
    @include h1;
  }

  &__placeholder-text {
    color: $mainColor;
    text-align: left;
    padding: 16px 0;
  }

  &__button {
    width: 275px;
    height: 43px;
    margin-right: 15px;
  }
}
</style>
