<template>
  <div class="manage-account">
    <div v-if="!deleteAccount && !changePassword && !changePhone">
      <h1 class="manage-account__title">Настройки аккаунта</h1>
      <h2 class="manage-account__name">
        <ChangeAvatar class="manage-account__change-avatar"/>
        {{ userName }}
      </h2>
      <p class="manage-account__change-password" @click="() => changePassword = true">Изменить пароль</p>
      <p class="manage-account__change-phone" @click="() => changePhone = true">Изменить номер телефона</p>
      <ButtonMain class="manage-account__butoon-save" @click.native="handleSave">
        Сохранить
      </ButtonMain>
      <DecoreLine />
      <ButtonUnderlined @click.native="deleteAccount = true">Удалить аккаунт</ButtonUnderlined>
    </div>

    <ChangePhoneAccount v-if="changePhone" :close-modal="handleSave" :to-manage-account-step="() => changePhone = false" />
    <ChangePasswordAccount v-if="changePassword" :to-manage-account-step="() => changePassword = false" />
    <DeleteAccount v-if="deleteAccount" :to-manage-account-step="() => deleteAccount = false" :close-modal="handleSave" />
  </div>
</template>

<script>
import ButtonMain from '../../atoms/ButtonMain.vue';
import ButtonUnderlined from '../../atoms/ButtonUnderlined.vue';
import DecoreLine from '../../atoms/DecoreLine.vue';
import DeleteAccount from '../Account/DeleteAccount';
import ChangePasswordAccount from '../Account/ChangePasswordAccount';
import ChangePhoneAccount from '../Account/ChangePhoneAccount';
import ChangeAvatar from '../../assets/images/avatar/changeAvatar.svg';

export default {
  name: 'ManageAccount',
  components: {
    ButtonMain,
    ButtonUnderlined,
    DeleteAccount,
    ChangePasswordAccount,
    ChangePhoneAccount,
    DecoreLine,
    ChangeAvatar
  },
  props: {
    handleSave: {
      type: Function,
      default() {
        return {};
      },
    },
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      deleteAccount: false,
      changePassword: false,
      changePhone: false,
    };
  },
};
</script>

<style lang="scss" scoped>
  .manage-account {

    div {
      text-align: start;
    }
    
    &__title {
      @include h1;
      padding-bottom: 50px;
    }

    &__name {
      @include h2-bold;
      padding-bottom: 50px;
    }

    &__change-avatar {
      padding-right: 20px;
    }
    
    &__change-password {
      @include p;
      padding-bottom: 20px;

      &:hover {
        color: $elementsColor;
        cursor: pointer;
      }
    }

    &__change-phone {
      @include p;
      padding-bottom: 25px;

      &:hover {
        color: $elementsColor;
        cursor: pointer;
      }
    }

    &__butoon-save {
      width: 149px;
      height: 43px;
    }
  }
</style>
