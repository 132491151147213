<template>
  <div class="password-code-confirm-form">
    <form @submit.prevent="handleSubmit">
      <InputTextWithLabel
        :id="'smsKey'"
        :set-value="userData"
        :error="error['smsKey']"
        :placeholder="'Введите код подтверждения'"
      />
      <MessageError v-for="message in error['nonFieldError']" :key="message">
        {{message}}
      </MessageError>
      <ButtonMain :type="'submit'" class="password-code-confirm-form__button">
        Подтвердить
      </ButtonMain>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InputTextWithLabel from '../molecules/InputTextWithLabel.vue';
import ButtonMain from '../atoms/ButtonMain.vue';
import MessageError from '../atoms/MessageError.vue';

export default {
  name: 'PasswordCodeConfirmForm',
  components: {
    InputTextWithLabel,
    ButtonMain,
    MessageError,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userData: {},
    };
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSubmit() {
      const { userData } = this;
      const sms_key = userData.smsKey
      this.onSubmit({ sms_key: sms_key, type: 'password_recovery' });
    },
  },
};
</script>

<style lang="scss" scoped>
.password-code-confirm-form {
  &__button {
    width: 155px;
    height: 43px;
    margin-right: 15px;
  }
}
</style>
