<template>
  <AuthLayout
    :footer-is-hidden="true"
    :contacts-is-hidden="true"
  >
    <div class="create-password-page">
      <CreatePassword />
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout.vue';
import CreatePassword from '../../organisms/Auth/CreatePassword.vue';

export default {
  name: 'CreatePasswordPage',
  components: {
    AuthLayout,
    CreatePassword,
  },
};
</script>

<style lang="scss" scoped>
  .create-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
