<template>
  <input
    :type="type"
    :id="id"
    :placeholder="placeholder"
    v-model="setValue[id]"
    :disabled="disabled"
    class="input-text"
  />
</template>
<script>
export default {
  name: 'InputText',
  props: {
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
  .input-text {
    background: $placeholderColor;
    border: 1px solid $mainBorderColor;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 45px;
    padding: 10px;
    font-size: 16px;
    &::placeholder {
      color: $hintTextColor;
    }
  }
  .input-text:focus {
    outline: none;
  }
  #building {
    border: none;
    border-radius: 0;
  }
  #flat {
    border: none;
    border-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
</style>
