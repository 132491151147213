<template>
  <div class="user-header-controls">
    <p v-if="userInfo.balance < 0">Задолженность: <strong>{{userInfo.balance}} грн</strong></p>
    <p v-if="userInfo.balance > 0">Переплата: <strong>{{userInfo.balance}} грн</strong></p>
    <UserHeaderMenu :user-name="userInfo.name"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserHeaderMenu from './UserHeaderMenu.vue';

export default {
  name: 'UserHeaderControls',
  components: {
    UserHeaderMenu,
  },
  computed: {
    ...mapState('authStore', ['userInfo']),
  },
};
</script>

<style lang="scss" scoped>
  .user-header-controls {
    display: flex;
    align-items: center;
  }
</style>
