<template>
  <div class="dialog-wrap">
    <div class="dialog-wrap__scroll">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DialogWrap',
};
</script>

<style lang="scss">
  .dialog-wrap {
    
    width: 510px;
    height: 350px;
      background: #EDF4F7;
      border: 1px solid rgba(42, 49, 51, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      padding: 5px 0 20px 20px;
    &__scroll {
      margin-right: 10px;
      overflow-y: auto;
      margin-bottom: 10px;

      &::-webkit-scrollbar {
      width: 4px;
      height: 24px;
      background: rgba(42, 49, 51, 0.1);
      border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
      background: $mainColor;
      border-radius: 100px;
      }
    }
  }
</style>
