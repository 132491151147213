<template>
  <div class="file-uploader-with-modal">
      <FileAddIcon @click="() => fileUploaderIsOpen = true" class="file-uploader-with-modal__svg"/>
      <span v-if="setValue[id].length" class="file-uploader-with-modal__count-selected"> {{ setValue[id].length }}</span>
    <!-- modals -->
    <Tooltip v-if="fileUploaderIsOpen">
      <div class="file-uploader-with-modal__file-container"  v-click-outside="handleClick">
        <div v-if="setValue[id].length"
          class="file-uploader-with-modal__files-container"
        >
          <p
            class="file-uploader-with-modal__file"
            v-for="file in setValue[id]"
            :key="file.id"
            :file-name="file.name"
            @click="() => deleteFile(file)"
          >
          <FileDeleteIcon />
            {{ file.name }}
          </p>
        </div>
        <label :for="id" class="file-uploader-with-modal__title">
          <Plus />
          Добавить
        </label>
          <input :id="id"
            accept="image/png,image/gif,image/jpeg,image/webp,image/svg,image/bmp"
            type="file"
            ref="images"
            @change="handleAdd"
            multiple
          >
      </div>
    </Tooltip>
    <!--  -->
  </div>
</template>

<script>
import FileAddIcon from '../assets/images/icons/file_add.svg';
import Tooltip from './Tooltip.vue';
import Plus from "../assets/images/plus/plus.svg";
import FileDeleteIcon from '../assets/images/icons/file_delete.svg';

export default {
  name: 'FileUploaderWithModal',
  props: {
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    id: {
      type: String,
      default: '',
    },
  },
  components: {
    FileAddIcon,
    Tooltip,
    Plus,
    FileDeleteIcon
  },
  data() {
    return {
      fileUploaderIsOpen: false,
    };
  },
  methods: {
    handleAdd() {
      const { setValue, id } = this;
      const files = Array.from(this.$refs.images.files);

      setValue[id] = [...setValue[id], ...files];
    },
    deleteFile(file) {
      const { setValue, id } = this;
      setValue[id] = setValue[id].filter((el) => el.name !== file.name);
    },
    handleClick() {
      this.fileUploaderIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .file-uploader-with-modal {

    position: relative;

    input {
      display: none;
    }

    &__title {
      color: $elementsColor;
      text-decoration: underline;

      svg {

        position: relative;
        top: 7px;
        right: 6px;

        circle {
          stroke: $elementsColor;
        }

        path {
          stroke: $elementsColor;
        }
      }

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }

    &__count-selected {
      background: #008BB2;
      border-radius: 100px;
      padding: 2px 5px 2px 3px;
      font-size: 12px;
      color: #ffffff;
      position: relative;
      bottom: 5px;
      right: 10px;
    }

    &__svg {
      position: relative;
      left: 7px;
      top: 4px;
    }

    &__svg:hover {
      cursor: pointer;

      path {
        stroke: $elementsColor;
      }
    }

    &__file-container {
      padding: 22px;
      width: 210px;
      text-align: left;
    }

    &__file {
      padding: 10px;
      display: flex;
      text-overflow: ellipsis;
      width: 100%;
      align-items: center;
      position: relative;
      right: 20px;
      color: rgba(42, 49, 51, 0.5);
      font-size: 12px;

      svg {
        padding-right: 10px;
      }
    }
  }
</style>
