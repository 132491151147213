<template>
  <div class="history-table__scroll">
    <div class="history-table">
    <div class="history-table__section" v-for="history in history" :key="history.id">
      <div class="history-table__block">
          <div class="history-table__head">Дата начисления</div>
          <div>30-{{ history.period }} года</div>
      </div>
      <div class="history-table__block">
          <div class="history-table__head">К оплате</div>
          <div>{{ history.charged }} грн</div>
      </div>
      <div class="history-table__block">
          <div class="history-table__head">Оплачено</div>
          <div v-if="history.payment > 0">{{ history.payment }} грн</div>
      </div>
      <div v-if="history.balance > 0" class="history-table__block history-table__block-balance">
          <div class="history-table__head">Переплата</div>
          <div :class="{positive: history.balance > 0, negative: history.balance < 0}">+{{ history.balance }} грн</div>
      </div>
      <div v-if="history.balance < 0" class="history-table__block history-table__block-balance">
          <div class="history-table__head">Задолженость</div>
          <div :class="{positive: history.balance > 0, negative: history.balance < 0}">{{ history.balance }} грн</div>
      </div>
      <div class="history-table__block">
          <div class="history-table__head">Дата оплаты</div>
          <div>{{ history.datePayment }} года</div>
      </div>
        <!-- <div class="history-table__block history-table__block-status">
            <div class="history-table__head">Статус</div>
            <div>{{ history.status }}</div>
        </div> -->
      </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'HistoryTable',
  props: {
    history: {
      type: Array,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.positivePayment {
  opacity: 0;
}
.negativePayment {
  opacity: 0;
}
.negative {
  color: $elementsColor;
}
.positive {
  color: #008BB3;
}
  .history-table {
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px solid rgba(42, 49, 51, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 20px;

    &__scroll {
      max-height: 550px;
      overflow: auto;
      padding-right: 25px;
      margin-right: -25px;

        &::-webkit-scrollbar {
        width: 4px;
        height: 24px;
        background: rgba(42, 49, 51, 0.1);
        border-radius: 100px;
      }

        &::-webkit-scrollbar-thumb {
        background: $mainColor;
        border-radius: 100px;
      }
    }

    &__block-balance {
      width: 115px;
    }

    &__block-status {
      width: 100px;
    }

    &__section {
      display: flex;
      justify-content: space-between;
      border: 1px solid rgba(42, 49, 51, 0.1);
      box-sizing: border-box;
      border-radius: 6px;
      padding: 15px 20px;
      margin-bottom: 15px;

      div {
        display: flex;
        flex-direction: column;
        align-items: start;
      }
    }

    &__head {
      @include p;
      padding-bottom: 10px;
    }

    &__block {
      border-right: 1px solid rgba(42, 49, 51, 0.1);
      padding-right: 40px;
    }
  }
</style>
