<template>
  <div class="actual-step-line">
    <p>Шаг {{actualStep}} <span>/ 4</span></p>
    <div class="actual-step-line__full-line">
      <div class="actual-step-line__filled-line" :style="{ 'width': `${25 * actualStep}%`}"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ActualStepLine',
  props: {
    actualStep: {
      type: Number,
      default: 1,
    },
  },
};
</script>
<style lang="scss" scoped>
  .actual-step-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    p {
      color: $elementsColor;
      font-weight: bold;
    }
    span {
      color: $hintTextColor;
    }
    &__full-line {
      position: relative;
      width: 75%;
      height: 3px;
      background: $hintTextColor;
    }
    &__filled-line {
      position: absolute;
      height: 3px;
      background: $elementsColor;
    }
  }
</style>
