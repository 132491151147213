<template>
  <button :type="type">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonSecondary',
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
  button {
    width: 100%;
    background: rgba(255, 98, 0, 0.1);
    border: 1px solid rgba(255, 98, 0, 0.1);
    box-sizing: border-box;
    border-radius: 100px;
    letter-spacing: 0.1em;
    font-size: $baseFontsSize;
    text-transform: uppercase;
    color: $elementsColor;
    font-weight: bold;
    line-height: 21px;
    outline: none;

    &:hover {
      @include secondButtonHover;
    }

    &:active {
      @include secondButtonActive ;
    }
  }
</style>
