<template>
  <div>
    <div class="support">
      <div>
        <h2 class="support__title support__title-sm">Активные диалоги</h2>
          <DialoguesWrapper :dialogues="dialogues"/>
      </div>
      <div class="support__block">
        <h2 class="support__title">Задать вопрос</h2>
        <AskAQuestionForm :options="options" :on-submit="onSubmit" :error="error"/> 
      </div>
    </div>
    <MessageError v-for="message in error" :key="message.id">
      {{message.join(' ')}}
    </MessageError>
    <SupportStaticInformation />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getDialogueTopic, createDialogue, getUserDialogueList } from '../../api/supportApi';
import convertToFormData from '../../helpers/convertToFormData';
import DialoguesWrapper from '../../molecules/DialoguesWrapper.vue';
import SupportStaticInformation from '../../molecules/SupportStaticInformation.vue';
import AskAQuestionForm from '../../forms/AskAQuestionForm.vue';
import MessageError from '../../atoms/MessageError.vue';

export default {
  name: 'Support',
  components: {
    DialoguesWrapper,
    AskAQuestionForm,
    MessageError,
    SupportStaticInformation,
  },
  data() {
    return {
      options: [],
      error: {},
      dialogues: [],
    };
  },
  computed: {
    ...mapState('authStore', ['userInfo', 'actualAccount']),
  },
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
        const { actualAccount } = this;
        if (actualAccount && Object.keys(actualAccount).length) {
          const params = { personal_number: this.actualAccount.personalNumber };
          getDialogueTopic(params).then((res) => {
            this.options = res.data;
          }).catch((error) => {
            const { data } = error.response;
            this.error = data;
          });
          this.getUserDialogueList();
        }
      },
    },
  },
  methods: {
    onSubmit(data) {
      createDialogue(convertToFormData(data), { personal_number: this.actualAccount.personalNumber }).then(() => {
        this.error = {};
        this.userQuestion = {
          images: [],
        };
        this.getUserDialogueList();
      }).catch((error) => {
        const { data } = error.response;
        this.error = data;
      });
    },
    getUserDialogueList() {
      const params = { personal_number: this.actualAccount.personalNumber };
      getUserDialogueList(params).then((res) => {
        this.dialogues = res.data;
      }).catch((error) => {
        const { data } = error.response;
        this.error = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .support {
    display: flex;
    margin: 50px 0;

    &__title {
      @include h1;
      padding-bottom: 15px;
    }

    &__block {
      width: 100%;
      margin-left: 30px;
    }
  }
</style>
