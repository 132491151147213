<template>
  <div class="dialogue-admin">
    <div v-for="dialogue in dialogues" :key="dialogue.id" class="dialogue-admin__section" @click="handleClick(dialogue)">
        <div>
            <DefaultAvatar class="dialogue-admin__avatar" /> 
        </div>
        <div>
            <div class="dialogue-admin__title-block">
            <h2>
               {{ dialogue.name }}
            </h2>
            <div>
                тема: {{ dialogue.dialogueLabel }}
            </div>
            <div>
                л/с №{{ dialogue.personalNumber }}
            </div>
            <div>
                {{ dialogue.address }}
            </div>
        </div>
        <div class="dialogue-admin__message">
            {{ dialogue.message }}
        </div>
        <div class="dialogue-admin__dialogue-date">
            {{ dialogue.dialogueDate }}
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import { ADMIN_ROUTES } from '../router/constants';
import DefaultAvatar from '../assets/images/icons/default_user_avatar.svg';
import DecoreLine from '../atoms/DecoreLine.vue';

export default {
  name: 'DialogueAdmin',
  components: {
    DefaultAvatar,
    DecoreLine
  },
  props: {
    dialogues: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    handleClick(dialogue) {
      this.$router.push({ path: ADMIN_ROUTES.CHAT, query: { id: dialogue.dialogueId, userId: dialogue.userId } }); 
    },
  },
}
</script>

<style lang="scss" scoped>
    .dialogue-admin {

        max-height: 600px;
        overflow: auto;
        margin-bottom: 40px;

        &::-webkit-scrollbar {
        width: 4px;
        height: 24px;
        background: rgba(42, 49, 51, 0.1);
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        background: $mainColor;
        border-radius: 100px;
      }

        &__avatar {
            margin-right: 25px;
        }
        
        &__section {
            display: flex;
            text-align: left;
            padding: 20px 0;
            border-bottom: 1px solid rgba(42, 49, 51, 0.1);
            margin-right: 40px;

            &:hover {
              div, h2 {
                cursor: pointer;
                color: $elementsColor;
              }
            }
        }

        &__title-block {
            display: flex;
            color: $hintTextColor;
            font-size: $littleFontsSize;
            align-items: center;
            padding-bottom: 7px;

            h2 {
                @include h2-bold;
                padding-right: 25px;
            }

            div {
                padding-right: 30px;
            }
        }

        &__message {
            @include p;
            font-weight: normal;
            padding-bottom: 7px;
        }

        &__dialogue-date {
            color: $hintTextColor;
            font-size: $littleFontsSize;
        }
    }
</style>