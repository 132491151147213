<template>
  <div class="admin-header-auth">
    <router-link to="/">
      <Logo />
    </router-link>
  </div>
</template>

<script>
import Logo from '../../assets/images/logo/logo.svg';

export default {
  name: 'AdminHeaderAuth',
  components: {
    Logo,
  },
};
</script>

<style lang="scss" scoped>
.admin-header-auth {
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;
}
</style>
