<template>
  <div class="admin-navigation-panel">
    <nav>
      <router-link v-for="link in adminNavigationLinks" :to="link.to" :key="link.id">
        {{link.title}}
      </router-link>
    </nav>
  </div>
</template>

<script>
import { adminNavigationLinks } from '../router/constants';

export default {
  name: 'AdminNavigationPanel',
  data() {
    return {
      adminNavigationLinks,
    };
  },
};
</script>

<style lang="scss" scoped>
  .admin-navigation-panel {

    nav {

      display: flex;
      padding: 20px 40px;
      border-bottom: 1px solid rgba(42,49,51,.1);
      a {
        @include p;
        display: block;
        padding: 9px 0;
        margin-right: 44px;
        position: relative;
        text-transform: uppercase;
        text-decoration: none;
        color: $hintTextColor;

        &:hover {
         color: $mainColor;
        }
      }

      .router-link-active {

      color: $elementsColor;

      &:before {
        content: '';
        position: absolute;
        bottom: -22px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $elementsColor;
      }
    }
    }
  }
</style>
