<template>
  <div class="login-form">
    <form @submit.prevent="handleSubmit">
      <InputTextWithLabel
        :id="'name'"
        :set-value="userInfo"
        :error="error['name']"
        :placeholder="'Номер мобильного телефона или email'"
      />
      <InputPasswordWithLabel
        :id="'password'"
        :set-value="userInfo"
        :error="error['password']"
        :placeholder="'Пароль для входа'"
      />
      <MessageError v-for="message in error['nonFieldError']" :key="message">
        {{message}}
      </MessageError>
      <ButtonMain :type="'submit'" class="login-form__button-entrance">
        Войти
      </ButtonMain>
      <LinkUnderlined :to="'/forgot-password'">
        Восстановить пароль
      </LinkUnderlined>
    </form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import InputTextWithLabel from '../molecules/InputTextWithLabel.vue';
import InputPasswordWithLabel from '../molecules/InputPasswordWithLabel.vue';
import ButtonMain from '../atoms/ButtonMain.vue';
import MessageError from '../atoms/MessageError.vue';
import LinkUnderlined from '../atoms/LinkUnderlined.vue';

export default {
  name: 'LoginForm',
  components: {
    InputTextWithLabel,
    InputPasswordWithLabel,
    ButtonMain,
    MessageError,
    LinkUnderlined,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userInfo: {},
    };
  },
  computed: {
    ...mapState('authStore', ['error']),
  },
  methods: {
    handleSubmit() {
      this.onSubmit(this.userInfo);
    },
  },
};
</script>
<style lang="scss" scoped>
.login-form {
  &__button-entrance {
    width: 101px;
    height: 43px;
    margin-right: 15px;
    margin-top: 15px;
  }
}
</style>
