<template>
  <div class="video">
    <VideoContainer>
      <div class="video__background" id="background">
        <div class="video__play" @click="playVideo">
          <Play />
          <div class="video__play-title">
            Смотреть видео
          </div>
        </div>
      </div>
        <video id="video" class="video__video-container" controls="controls" poster="https://uberu.test.ut.in.ua/fon.png">
          <source src="https://uberu.test.ut.in.ua/x.mp4" type="video/mp4" >
      </video>
      
    </VideoContainer>
  </div>
</template>

<script>
import VideoContainer from '../../atoms/VideoContainer';
import Play from '../../assets/images/video/play.svg';

export default {
  name: 'Video',
  components: {
    VideoContainer,
    Play
  },
  methods: {
    playVideo() {
      let video = document.getElementById("video");
      let background = document.getElementById("background");
      background.style.display = "none";
      video.style.filter =  "blur(0)";
        if(video.paused) {
          video.play();
        }
        else {
          video.pause();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .video {
    
    &__video-container {
      width: 100%;
      filter: blur(20px);
      margin-top: 64px;
    }

    &__background {
      position: absolute;
      z-index: 100;
      width: 635px;
      height: 357px;
      background: rgba(42, 49, 51, 0.4);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      margin-top: 64px;
    }

  &__play {
    position: absolute;
    top: 35%;
    left: 48%;
    transform: translate(-50%);
    color: $mainSecondColor;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__play-title {
    text-decoration: underline;
    padding-left: 15px;
    width: 40px;

    &:hover {
      cursor: pointer;
      color: $elementsColor;
      text-decoration: none;
    }
  }
  }
  /deep/ video {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    outline: none;
  }
  .video-player {

  /deep/ :focus {
    outline: 0!important;
  }  
  /deep/ iframe {
      width: 99.7%;
      // height: 388px;
      border: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
</style>
