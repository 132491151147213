export default (data) => {
  const newData = data.map((el) => {
    const optionObj = {};
    Object.entries(el).forEach(([key, value]) => {
      if (key.toLowerCase().includes('id')) {
        optionObj.id = value;
      }
      if (key.toLowerCase().includes('name')) {
        optionObj.label = value.toUpperCase();
      }
    });
    return optionObj;
  });
  return newData;
};
