<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getStoredUserInfo } from './helpers/auth';

export default {
  created() {
    const userInfo = getStoredUserInfo() || null;
    this.$store.commit('authStore/setUserInfo', userInfo);
  },
};
</script>

<style lang="scss">
body {
  background: #EDF4F7;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
button:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}

</style>
