import snake from 'to-snake-case';

export default (data) => {
  const fd = new FormData();

  Object.entries(data).map(([key, value]) => {
    if (Array.isArray(value) && !value.length) {
      return;
    }
    if (Array.isArray(value) && value.length) {
      value.forEach((el) => {
        fd.append(`${snake(key)}[]`, el, 'blob');
      });
      return;
    }
    fd.set(snake(key), value);
  });
  return fd;
};
