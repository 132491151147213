<template>
  <form @submit.prevent="handleSubmit" class="ask-a-question-form-admin">
    <div class="textarea-file">
      <div class="ask-a-question-form-admin__file-input">
        <input :id="'images'"
          accept="image/png,image/gif,image/jpeg,image/webp,image/svg,image/bmp"
          type="file"
          ref="images"
          @change="previewFiles"
          multiple
        >
        <label :for="'images'">
          <Dots class="textarea-file__dots-svg"/>
        </label>
      </div>
      <InputTextarea
        :placeholder="'Ваше сообщение'"
        :id="'message'"
        :set-value="userQuestion"
      />
      <ButtonMain class="ask-a-question-form-admin__submit" type="submit">
        Отправить
        <Send />
      </ButtonMain>
    </div>
    <div
      class="ask-a-question-form-admin__files-container"
    >
      <FileContainer 
        v-for="file in userQuestion.images"
        :key="file.id"
        :file-name="file.name"
        :delete-call-back="() => deleteFile(file)"
      />
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import InputSelect from '../atoms/InputSelect';
import ButtonMain from '../atoms/ButtonMain';
import InputTextarea from '../atoms/InputTextarea';
import FileContainer from '../atoms/FileContainer.vue';
import Send from '../assets/images/arrow/send.svg';
import Dots from '../assets/images/dots/dots.svg';

export default {
  name: 'AskAQuestionFormAdmin',
  components: {
    InputSelect,
    ButtonMain,
    InputTextarea,
    FileContainer,
    Send,
    Dots
  },
  props: {
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userQuestion: {
        images: [],
      },
    };
  },
  computed: {
    ...mapState('authStore', ['userInfo']),
  },
  methods: {
    handleSubmit() {
      const { userQuestion } = this;
      const data = {
        ...userQuestion,
      };
      this.onSubmit(data);
      this.userQuestion = {
        images: []
      }
    },
    previewFiles() {
      this.userQuestion.images = Array.from(this.$refs.images.files).map((file) => file); 
    },
    deleteFile(file) {
      this.userQuestion.images = this.userQuestion.images.filter((el) => el.name !== file.name);
    },
  },
};
</script>

<style lang="scss" scoped>
  .ask-a-question-form-admin {
    position: absolute;
    width: 92.8%;
    bottom: 23px;
    left: 40px;

    .textarea-file {
      position: relative;

      &__dots-svg {
        position: absolute;
        top: 50%;
        transform: translate(30%, -136%);
        border-right: 1px solid $hintTextColor;
        padding: 5px 9px 5px 0;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &__file-input {
      position: absolute;
      bottom: 25px;
      left: 5px;

      input {
        display: none;
      }
    }

    &__files-container {
      display: flex;
      flex-wrap: wrap;
    }

    &__submit {
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 173px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
  #dialogueTopicId {
    
    height: 100%;
    padding: 0;
    margin-bottom: 14px;

    /deep/#vs1__combobox {
      padding: 9px;

      .vs__selected-options {

          /deep/.vs__selected {
            max-width: 100%;
          }
        }
    }
  }

  /deep/ .textarea {
    height: 70px;
    padding: 23px 0 0 57px;
  }

  #dialogueTopicId { 
    
    /deep/ .input-select .vs__search {
      border-right: none;
    }
  }

  /deep/#vs1__listbox {
    background: rgba(237, 244, 247, 0.86);
  }
  /deep/#vs1__listbox li {
    color: $mainColor;
    background: rgba(237, 244, 247, 0.86);
    border-top: 1px solid rgba(42, 49, 51, 0.1);
    padding: 10px;

    &:hover {
      color: $elementsColor;
    }
  }

</style>
