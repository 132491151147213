<template>
  <div>
    <NewPhoneCreateForm v-if="newPhoneCreateForm" :on-submit="handleCreateNewPhone" />
    <PhoneConfirmToChangePhone v-if="newPhoneConfirmForm" :closeModal="closeModal" :on-submit="handleConfirmNewPhone" />
    <div v-if="successChangePhone">
      <h1>Номер телефона  изменен</h1>
      <p>К вашему аккаунту успешно привязан новый номер телефона {{phone.phone}}</p>
      <ButtonMain @click.native="toManageAccountStep">
        Хорошо
      </ButtonMain>
    </div>
  </div>
</template>

<script>
import NewPhoneCreateForm from './NewPhoneCreateForm.vue';
import PhoneConfirmToChangePhone from './PhoneConfirmToDelete.vue';

export default {
  name: 'ChangePhoneAccount',
  components: {
    NewPhoneCreateForm,
    PhoneConfirmToChangePhone,
  },
  props: {
    closeModal: {
      type: Function,
      default() {
        return {};
      },
    },
    toManageAccountStep: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      newPhoneCreateForm: true,
      newPhoneConfirmForm: false,
      successChangePhone: false,
      phone: null,
    };
  },
  methods: {
    handleCreateNewPhone(data) {
      this.phone = { ...data };
      this.$store.dispatch('authStore/userCheckPhone', data).then(() => {
        this.newPhoneCreateForm = false;
        this.newPhoneConfirmForm = true;
      });
    },
    handleConfirmNewPhone(data) {
      const { phone } = this;
      this.$store.dispatch('authStore/userPhoneUpdate', { ...data, ...phone }).then(() => {
        this.newPhoneConfirmForm = false;
        this.successChangePhone = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
