<template>
  <AdminAuthLayout>
    <div class="admin-login-page">
      <div class="admin-login-page__form-container">
        <AdminLogin />
      </div>
    </div>
  </AdminAuthLayout>
</template>

<script>
import AdminAuthLayout from '../../layouts/AdminAuthLayout.vue';
import AdminLogin from '../../organisms/AdminAuth/AdminLogin.vue';

export default {
  name: 'AdminLoginPage',
  components: {
    AdminAuthLayout,
    AdminLogin,
  },
};
</script>

<style lang="scss" scoped>
  .admin-login-page {
    display: flex;
    justify-content: center;

    &__video-container {
      width: 635px;
      margin-left: 30px;
    }
  }
  /deep/ iframe {
    height: 388px;
  }
</style>
