<template>
  <div class="forgot-password">
    <CardContainer>
      <div class="forgot-password__wrap">
        <h1 class="forgot-password__title">Забыли пароль?</h1>
        <p class="forgot-password__placeholder-text">
          Введите свой номер телефона и мы отправим вам код восстановления пароля
        </p>
        <RestorePasswordForm :on-submit="onSubmit" />
        <DecoreLine />
        <LinkUnderlined :to="'/login'">
          Вернуться ко входу
        </LinkUnderlined>
      </div>
    </CardContainer>
  </div>
</template>

<script>
import RestorePasswordForm from '../../forms/RestorePasswordForm.vue';
import CardContainer from '../../atoms/CardContainer.vue';
import DecoreLine from '../../atoms/DecoreLine.vue';
import LinkUnderlined from '../../atoms/LinkUnderlined.vue';

export default {
  name: 'ForgotPassword',
  components: {
    RestorePasswordForm,
    CardContainer,
    DecoreLine,
    LinkUnderlined,
  },
  beforeDestroy() {
    this.$store.commit('authStore/setError', {});
  },
  methods: {
    onSubmit(data) {
      this.$store.dispatch('authStore/restorePassword', data);
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password {
  max-width: 445px;

  &__wrap {
    padding: 40px;
    text-align: left;
  }

  &__title {
    @include h1;
  }

  &__placeholder-text {
    color: $mainColor;
    text-align: left;
    padding: 16px 0;
  }

}
</style>
