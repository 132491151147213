import router from '../router';
import { userLogOut } from '../api/authApi';

const TOKEN = 'token';
const TOKEN_TIME_EXPIRED = 'tokenTimeExpired';
const USER_INFO = 'userInfo';

export const cleanOutUser = () => {
  localStorage.removeItem(USER_INFO);
};

export const cleanOutTokens = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(TOKEN_TIME_EXPIRED);
};

const isTokenAlive = (tokenExpiresIn) => tokenExpiresIn && Date.now() < tokenExpiresIn;

export const getAccessToken = () => localStorage.getItem(TOKEN);

export const getRefreshToken = () => localStorage.getItem(TOKEN_TIME_EXPIRED);

export const getStoredUserInfo = () => {
  try {
    const info = localStorage.getItem(USER_INFO);
    if (!info) {
      return null;
    }

    return JSON.parse(info);
  } catch (e) {
    return null;
  }
};

export const setTokenData = (
  token, tokenTimeExpired,
) => {
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(TOKEN_TIME_EXPIRED, tokenTimeExpired);
};

export const setUserData = (userInfo) => {
  localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
};

export const setAuthData = (
  token, tokenTimeExpired, userInfo,
) => {
  setUserData(userInfo);
  setTokenData(token, tokenTimeExpired);
};

export const isTokensInStore = () => !!getAccessToken() && !!getRefreshToken();

const serverLogout = async () => {
  try {
    await userLogOut();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const logOut = async () => {
  await serverLogout();
  cleanOutTokens();
  cleanOutUser();

  router.push('/login');
};

export const logOutWithHistory = () => {
  cleanOutTokens();
  cleanOutUser();

  router.push('/login');
};
