<template>
  <AuthLayout :footer-is-hidden="true">
    <div class="registration-page">
      <div class="registration__form-container">
        <UserRegistration />
      </div>
      <Video class="registration-page__video-container"/>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout.vue';
import UserRegistration from '../../organisms/Auth/UserRegistration.vue';
import Video from '../../organisms/Video/video.vue';

export default {
  name: 'UserRegistrationPage',
  components: {
    AuthLayout,
    UserRegistration,
    Video,
  },
};
</script>

<style lang="scss" scoped>
  .registration-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 50px;

    &__video-container {
      width: 635px;
      height: 456px;
      margin-left: 30px;
    }
  }
</style>
