<template>
  <div class="user-registration-accept-agreement">
    <form @submit.prevent="handleSubmit">
      <p class="user-registration-accept-agreement__hint">Ознакомьтесь с договором и подтвердите, что согласны с ним</p>
      <div class="user-registration-accept-agreement__agreement-container">
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»,
        Текст договора о неразглашении информации «УБЕРУ»
      </div>

      <ButtonMain class="user-registration-accept-agreement__button" :type="'submit'">
        Согласен
      </ButtonMain>
    </form>
  </div>
</template>
<script>
import ButtonMain from '../atoms/ButtonMain.vue';

export default {
  name: 'userRegistrationAcceptAgreement',
  components: {
    ButtonMain,
  },
  props: {
    onSubmit: {
      type: Function,
      default() {
        return {};
      },
    },
  },
  methods: {
    handleSubmit() {
      this.onSubmit({
        acceptAgreement: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-registration-accept-agreement {

  text-align: start;

  &__agreement-container {
    @include p;
    font-weight: normal;
    display: block;
    max-height: 177px;
    overflow-y: auto;
    background: #EDF4F7;
    border: 1px solid rgba(42, 49, 51, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 12px;
    
    &::-webkit-scrollbar {
      width: 4px;
      height: 24px;
      background: rgba(42, 49, 51, 0.1);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background: $mainColor;
      border-radius: 100px;
    }
  }

  &__hint {
    @include p;
    font-weight: normal;
    padding-bottom: 14px;
  }

  &__button {
    height: 43px;
    width: 40%;
    margin-top: 24px;
  }
}
</style>
