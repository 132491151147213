<template>
<AdminLayout>
  <div class="admin">
    <component
      :is="currentComponent"
    />
  </div>
</AdminLayout>
</template>

<script>
import AdminLayout from '../../layouts/AdminLayout.vue';
import { ADMIN_ROUTES } from '../../router/constants';
import Search from '../../organisms/admin/Search.vue';
import Requests from '../../organisms/admin/Requests.vue';
import Dialogues from '../../organisms/admin/Dialogues.vue';
import Chat from '../../organisms/admin/Chat.vue';

export default {
  name: 'Admin',
  data() {
    return {
      currentComponent: null,
    };
  },
  components: {
    AdminLayout
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler() {
        this.checkActualAdminComponent();
      },
    },
  },
  methods: {
    checkActualAdminComponent() {
      const { path } = this.$route;
      if (path === ADMIN_ROUTES.SEARCH) { 
        this.currentComponent = Search;
      }
      if (path === ADMIN_ROUTES.REQUESTS) {
        this.currentComponent = Requests;
      }
      if (path === ADMIN_ROUTES.DIALOGUES) {
        this.currentComponent = Dialogues;
      }
      if (path === ADMIN_ROUTES.CHAT) {
        this.currentComponent = Chat;
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
