<template>
  <div class="dialogue-wrapper">
    <DialogWrap class="dialogue-wrappe__section">
      <div
        v-for="messege in dialogue.messages.slice().reverse()"
        :key="messege.id"
        class="dialogue-wrapper__container"
      >
        <div
          v-if="messege.author !== 'Оператор'"
          class="user dialogue-wrapper__item"
        >
          <div class="dialogue-wrapper__title-group">
            <h3>
              {{ messege.author }}
            </h3>
            <span>
              {{ messege.createdAt.slice(0, 10) }}
            </span>
          </div>
          <p class="dialogue-wrapper__message">
            {{ messege.message }}
          </p>
          <div class="dialogue-wrapper__file-container">
            <div
              class="dialogue-wrapper__file"
              v-for="image in messege.images"
              :key="image.id"
            >
              <FileContainer
                v-for="file in userQuestion.images"
                :key="file.id"
                :file-name="file.name"
                :delete-call-back="() => deleteFile(file)"
              />
              <FileMessage />
              {{ image.slice(45) }}
            </div>
          </div>
        </div>
        <div
          v-if="messege.author === 'Оператор'"
          class="operator dialogue-wrapper__item"
        >
          <div class="dialogue-wrapper__title-group">
            <h3>
              {{ messege.author }}
            </h3>
            <span>
              {{ messege.createdAt.slice(0, 10) }}
            </span>
          </div>
          <p class="dialogue-wrapper__message">
            {{ messege.message }}
          </p>
          <div
            class="dialogue-wrapper__file"
            v-for="image in messege.images"
            :key="image.id"
          >
            <FileContainer />
            <FileMessage />
            {{ image.slice(45) }}
          </div>
        </div>
      </div>
    </DialogWrap>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getMessagesByDialogueId, createMessage } from "../api/supportApi";
import convertToFormData from "../helpers/convertToFormData";
import DialogWrap from "../atoms/DialogWrap.vue";
import FileContainer from "../atoms/FileContainer.vue";
import FileMessage from "../assets/images/icons/file_message.svg";

export default {
  name: "DialogueWrapper",
  components: {
    DialogWrap,
    FileContainer,
    FileMessage,
  },
  data() {
    return {
      error: {},
      options: [],
      message: [],
      userQuestion: {
        images: [],
      },
    };
  },
  props: {
    dialogue: {
      type: Array,
      default() {
        return {};
      },
    },
    onSubmitDialogue: {
      type: Function,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialogue-wrapper {
  position: relative;
  /deep/ .dialog-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 50px;
  }

  &__container {
    width: 100%;
  }

  &__title-group {
    display: flex;
    align-items: center;
    font-size: $littleFontsSize;
    color: $hintTextColor;

    h3 {
      padding-right: 10px;
    }
  }

  &__file {
    display: flex;
    align-items: center;
    font-size: $littleFontsSize;
    color: $hintTextColor;
    padding: 0 10px 10px 0;
  }

  &__message {
    @include p;
    font-weight: normal;
    padding: 10px 0;
  }

  &__file-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    background: $mainSecondColor;
    border-radius: 6px;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 15px;

    + .ask-a-question-form {
      position: absolute;
      bottom: -15px;
      width: 100%;
      height: 70px;
      right: 0;

      /deep/ #dialogueTopicId {
        display: none;
      }

      /deep/ .textarea {
        height: auto;
        padding: 15px 10px 0px 40px;
      }
    }
  }
}
.operator {
  background: $mainSecondColor;
  border-radius: 6px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 15px;
  width: 70%;
  margin-left: 15px;

  &::before {
    content: "";
    position: absolute;
    border: 20px solid transparent;
    left: -30px;
    bottom: 0;
    border: 20px solid transparent;
    border-bottom: 0px solid $mainSecondColor;
    border-right: 20px solid $mainSecondColor;
  }
}

.user {
  background: #d8e0e2;
  border-radius: 6px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 15px;
  margin-left: 30%;
  margin-right: 30px;

  &::before {
    content: "";
    position: absolute;
    border: 20px solid transparent;
    right: -30px;
    bottom: 0;
    border: 20px solid transparent;
    border-bottom: 0px solid #d8e0e2;
    border-left: 20px solid #d8e0e2;
  }
}
/deep/ .file-add-text {
  display: none;
}
/deep/ .ask-a-question-form__file-input {
  bottom: 17px;
  left: 10px;
}
/deep/ .ask-a-question-form__submit {
  bottom: 10px;
}

/deep/.file-container__upload-status {
  display: none;
}
/deep/ .dialog-wrap__scroll {
  width: 100%;
}
</style>